import {Document, Image, Link, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "./logoAutodichiarazione.png";
import firma from "./firmaAutodichiarazione.png";
import moment from "moment";
import React, {useState} from "react";
import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";


function Autodichiarazione() {

    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [cf, setCf] = useState('')
    const [data, setData] = useState('')

    // PDF
    const styles = StyleSheet.create({
        page: {
            color: 'black',
            backgroundColor: '#ffffff'
        },
        intestazione: {
            paddingTop: 45,
            paddingLeft: 55,
            fontSize: 11,
        },
        section1: {
            fontSize: 12,
            fontFamily: "Helvetica-Oblique",
            paddingTop: 33,
            paddingRight: 90,
        },
        section2: {
            fontSize: 12,
            paddingTop: 33,
            paddingLeft: 55,
            paddingRight: 55,
        },
        section3: {
            fontSize: 18,
            fontFamily: 'Helvetica-Bold',
            paddingTop: 33,
            paddingLeft: 55,
            paddingRight: 55,
        },
        section4: {
            fontSize: 11,
            fontFamily: "Helvetica-Oblique",
            paddingTop: 20,
            paddingLeft: 55,
            paddingRight: 55,
        },
        firma: {
            paddingTop: 20,
            paddingLeft: 350,
        },
    })
    const DocumentoPdf = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.intestazione}>
                    <Image src={logo} style={{width: '160px', marginBottom:2}} />
                    <Text><Text style={{textDecoration: 'underline', fontFamily: "Helvetica-Oblique"}}>sede operativa e fiscale:</Text> via Carlo Allioni 3, 10122, Torino (TO)</Text>
                    <Text><Text style={{textDecoration: 'underline', fontFamily: "Helvetica-Oblique"}}>P.IVA</Text> 11461510015</Text>
                    <Text style={{fontSize:10}}>www.rkhstudio.com • management@rkhstudio.com • (+39)3408727067</Text>
                </View>
                <View style={styles.section1}>
                    <Text style={{textAlign:'right'}}>{moment().format('DD/MM/YYYY')}, Torino (TO)</Text>
                </View>
                <View style={styles.section2}>
                    <Text style={{textAlign: 'justify'}}>
                        Con la presente si attesta la necessità di spostamento per esigenza lavorativa di attività
                        produttiva con codice Ateco 59.20.30 e 59.11 in ottemperanza alle richieste di comprova
                        delle suddette presenti nel <Text style={{fontFamily: "Helvetica-Oblique"}}>Decreto del Presidente del Consiglio dei Ministri del 3 Novembre
                        2020</Text>, per:
                    </Text>
                </View>
                <View style={styles.section3}>
                    <Text style={{marginBottom: 15}}>
                        Nome: {nome}
                    </Text>
                    <Text style={{marginBottom: 15}}>
                        Cognome: {cognome}
                    </Text>
                    <Text>
                        CF: {cf}
                    </Text>
                </View>
                <View style={styles.section2}>
                    <Text style={{textAlign: 'justify'}}>
                        la cui presenza è richiesta nella giornata del <Text style={{fontFamily: 'Helvetica-Bold'}}>{data}</Text> per lo svolgimento di attività di
                        incisione fonomeccanica e riprese videografiche, relativa alle esigenze produttive di cui
                        sopra, presso laboratorio adibito in struttura privata e chiusa al pubblico rispettosa di tutte le
                        norme vigenti ivi incluse le normative in fatto di prevenzione e rispetto dei protocolli atti a
                        limitare l’emergenza sanitaria in relazione all’<Text style={{fontFamily: "Helvetica-Oblique"}}>Art.1/1.o del Decreto del Presidente del
                        Consiglio dei Ministri del 7 Marzo 2020</Text> <Text style={{textDecoration: 'underline', fontFamily: "Helvetica-Oblique"}}>e successivi</Text> nel merito delle normative di igiene
                        sanitaria, situato presso RKH – via Carlo Allioni 3 – 10122 – Torino.
                    </Text>
                </View>
                <View style={styles.section4}>
                    <Text style={{textAlign: 'justify'}}>
                        RKH S.r.l. si impegna rispettare rigorosamente le normative e i protocolli in vigore per poter svolgere
                        l’attività lavorativo-produttiva. È invece responsabilità del soggetto il rispetto di tutte normative e
                        protocolli vigenti, in particolare in fatto di contatti e socializzazione nel tragitto che intercorre dalla
                        propria dimora/domiclio al raggiungimento della struttura adibita di cui sopra e relativo tragitto per
                        rientro presso domicilio dello stesso, e in particolare il personale rispetto dei punti b e c dell’ Art.1/1.
                        del Decreto del Presidente del Consiglio dei Ministri del 7 Marzo 2020 in merito all’autoisolamento.
                    </Text>
                </View>
                <View style={styles.firma}>
                    <Image src={firma} style={{width: '180px'}} />
                </View>
            </Page>
        </Document>
    )

    return (
        <div>
            <Container>
                <Container className="text-center mb-5">
                    <h3 className="text-warning">Generatore Autodichiarazione COVID-19</h3>
                    <p style={{fontSize:'smaller'}}>Tutti i campi sono obbligatori.</p>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nome
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Cognome
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Cognome"
                                    value={cognome}
                                    onChange={e => setCognome(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Codice Fiscale
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Codice Fiscale"
                                    value={cf}
                                    onChange={e => setCf(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Data Riprese
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Data Riprese"
                                    value={data}
                                    onChange={e => setData(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <Container className="text-center mt-4">
                    {
                        nome!=='' && cognome!=='' && cf!=='' && data!=='' ?
                            <PDFDownloadLink document={<DocumentoPdf />} fileName={'autodichiarazione' + '.pdf'}>
                                {({ blob, url, loading, error }) => (url ?
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <Button
                                                variant="warning"
                                                className="rounded-lg"
                                            >
                                                Genera PDF
                                            </Button>
                                        </a>
                                        :
                                        <Spinner animation="border" variant="warning" />
                                )}
                            </PDFDownloadLink>
                            :
                            <Alert variant="warning">Compila tutti i campi.</Alert>
                    }
                </Container>
            </Container>
        </div>
    )
}


export default Autodichiarazione