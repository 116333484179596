import {Button, Col, Container, Form, Row} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import moment from "moment";


function CardCollab({queryId}) {

    const [collab, setCollab] = useState()

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('collabs').doc(queryId)
        actorsRef.onSnapshot(snapshot => {
            setCollab(snapshot.data())
        })
    }, [])

    const [descrizione, setDescrizione] = useState('')
    const [data, setData] = useState('')
    const [retribuzione, setRetribuzione] = useState('')
    const [link, setLink] = useState('')

    return (
        <Container
            style={{
                textAlign: 'center'
            }}
        >
            <h5>Collaborazioni</h5>
            <table>
                <tr>
                    <th>DESCRIZIONE</th>
                    <th>DATA</th>
                    <th>RETRIBUZIONE</th>
                    <th>LINK</th>
                </tr>

                {
                    collab ?
                        Object.keys(collab).map(k => {
                            return (
                                <tr>
                                    <td>{collab[k].descrizione}</td>
                                    <td>{moment(collab[k].data, 'YYYY-MM-DD').format('DD/MM/YYYY')}</td>
                                    <td>{collab[k].retribuzione!=='' ? collab[k].retribuzione : <p>-</p>}</td>
                                    <td>{
                                        collab[k].link!=='' ?
                                            <a href={collab[k].link} target="_blank" rel="noreferrer">
                                                Video
                                            </a>
                                            :
                                            <p>-</p>
                                    }</td>
                                    <td>
                                        <FaTrash
                                            color="red"
                                            onClick={() => {
                                                if(window.confirm("Eliminare questa voce?")) {
                                                    firebase.firestore().collection('collabs/' + queryId).doc(collab[k].data).delete()
                                                }
                                            }}
                                        />
                                    </td>
                                </tr>
                            )
                        })
                        :
                        null
                }
            </table>
            <Row className="mt-3">
                <Col className="px-1">
                    <Form.Control
                        type="text"
                        size="sm"
                        placeholder="Descrizione"
                        onChange={e => setDescrizione(e.target.value)}
                        value={descrizione}
                    />
                </Col>
                <Col className="px-1">
                    <Form.Control
                        type="date"
                        size="sm"
                        placeholder="dd/mm/aaaa"
                        max={moment().format('YYYY-MM-DD')}
                        onChange={e => setData(e.target.value)}
                        value={data}
                    />
                </Col>
                <Col className="px-1">
                    <Form.Control
                        type="text"
                        size="sm"
                        placeholder="Retribuzione"
                        onChange={e => setRetribuzione(e.target.value)}
                        value={retribuzione}
                    />
                </Col>
                <Col className="px-1">
                    <Form.Control
                        type="text"
                        size="sm"
                        placeholder="Link"
                        onChange={e => setLink(e.target.value)}
                        value={link}
                    />
                </Col>
                <Col md="auto" className="px-1">
                    <Button
                        size="sm"
                        variant="warning"
                        onClick={() => {
                            if(descrizione==='') alert('Inserire una descrizione')
                            else if(data==='') alert('Inserire una data')
                            else {
                                addCollab()
                                setDescrizione('')
                                setData('')
                                setRetribuzione('')
                                setLink('')
                            }
                        }}
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            <p className="small my-2">Inserire massimo una collaborazione per data. I campi <u>Descrizione</u> e <u>Data</u> sono obbligatori.</p>
        </Container>
    )

    function addCollab() {
        firebase.firestore().collection('collabs/' + queryId).doc(data).set({
            descrizione: descrizione,
            data: data,
            retribuzione: retribuzione,
            link: link
        })
    }

}

export default CardCollab