import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {createContext, useReducer} from "react";
import {initialState, Reducer} from "./Reducer";
import Router from "./Router";

export const StateContext = createContext()

function App() {

    return (
        <StateContext.Provider value={useReducer(Reducer, initialState)}>
            <Router />
        </StateContext.Provider>
    );
}

export default App
