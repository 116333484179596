import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import logoRkh from "./logoRKH.png";
import {useContext, useState} from "react";
import {StateContext} from "./App";
import {setLogIn, setLogInAdmin, setLogInAttrezzatura} from "./Actions";


function LoginAttrezzatura() {

    const [password, setPassword] = useState('')
    const dispatch = useContext(StateContext)[1]
    const [error, setError] = useState(false)

    return(
        <div className="App" style={{backgroundColor:'#171717'}}>
            <Container className="d-flex flex-column justify-content-center align-items-center mb-5">
                <div className="mt-5 mb-5">
                    <img
                        src={logoRkh}
                        alt="LogoRKH"
                        width="180px"
                    />
                </div>
                <Col md={6}>
                    <Row>
                        <Container style={{textAlign:'left'}} >
                            <Container className="mb-4">
                                <Form.Label><p style={{fontSize:'smaller'}}>Password</p></Form.Label>
                                <Form.Control
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                    autofocus="true"
                                    onKeyPress={e => {
                                        if(e.key==='Enter' && password==='1234') {
                                            dispatch(setLogInAttrezzatura())
                                        } else if(e.key==='Enter' && password==='allioni3') {
                                            dispatch(setLogIn())
                                        } else {
                                            if(e.key==='Enter') {
                                                setError(true)
                                            } else return null
                                        }
                                    }}
                                />
                            </Container>

                            <Container className="text-center">
                                <Button
                                    variant="warning"
                                    className="rounded-lg mt-2 mb-4"
                                    style={{
                                        width:'100%'
                                    }}
                                    onClick={() => {
                                        if(password==='1234') {
                                            dispatch(setLogInAttrezzatura())
                                        } else if(password==='allioni3') {
                                            dispatch(setLogIn())
                                        } else {
                                            setError(true)
                                        }
                                    }}
                                >
                                    Sign in
                                </Button>
                                {
                                    error ?
                                        <Alert variant="danger">Password errata</Alert>
                                        :
                                        null
                                }
                            </Container>
                        </Container>
                    </Row>
                </Col>
            </Container>
        </div>
    )

}

export default LoginAttrezzatura
