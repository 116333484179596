import {Container} from "react-bootstrap";
import logoRkh from './logoRKH.png'


function Page404() {
    return(
        <div>
            <Container
                className="d-flex flex-column justify-content-center align-items-center"
                style={{minHeight:'75vh'}}
            >
                <img src={logoRkh} alt="LogoRKH" height="50px" className="mb-5" />
                <h1 style={{fontSize:'10em', fontWeight:'bold', color:'gold'}} >404</h1>
                <h3>Page not found</h3>
            </Container>
        </div>
    )
}

export default Page404
