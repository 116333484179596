import React, {useState} from "react";
import {Alert, Badge, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "./logoAutodichiarazione.png";
import moment from "moment";
import firma from "./firmaAutodichiarazione.png";


function Liberatoria() {

    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [luogoNascita, setLuogoNascita] = useState('')
    const [dataNascita, setDataNascita] = useState('')
    const [residenza, setResidenza] = useState('')
    const [cf, setCf] = useState('')
    const [programma, setProgramma] = useState('Da Carlo')
    const [dataRiprese, setDataRiprese] = useState('')


    // PDF
    const styles = StyleSheet.create({
        page: {
            color: 'black',
            backgroundColor: '#ffffff'
        },
        frame: {
            paddingTop: 45,
            paddingLeft: 55,
            paddingRight: 55
        },
        intestazione: {
            paddingBottom: 20,
            fontSize: 10,
        },
        section1: {
            fontSize: 10,
            fontFamily: "Helvetica-Bold",
            textDecoration: 'underline',
            alignItems: 'center',
            paddingTop: 10,
        },
        section2: {
            fontSize: 10,
            paddingTop: 10,
        },
        sectionInfo: {
            fontSize: 10,
            paddingTop: 10,
            flexDirection: 'row',
        },
        sectionInfoCol: {
            flexGrow: 1
        },
        footer: {
            flexDirection: 'row',
            fontSize: 10,
            justifyContent: 'space-around'
        },
        firma: {
            paddingTop: 10,
        },
    })
    const DocumentoPdf = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.frame}>
                    <View style={styles.intestazione}>
                        <Image src={logo} style={{width: '120px', marginBottom:2}} />
                        <Text><Text style={{textDecoration: 'underline', fontFamily: "Helvetica-Oblique"}}>sede operativa e fiscale:</Text> via Carlo Allioni 3, 10122, Torino (TO)</Text>
                        <Text><Text style={{textDecoration: 'underline', fontFamily: "Helvetica-Oblique"}}>P.IVA</Text> 11461510015</Text>
                        <Text style={{fontSize:10}}>www.rkhstudio.com • management@rkhstudio.com • (+39)3408727067</Text>
                    </View>
                    <View style={styles.section1}>
                        <Text>SCRITTURA PRIVATA / LIBERATORIA</Text>
                        <Text>TRA</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text>
                            <Text style={{fontFamily: "Helvetica-Bold"}}>RKH S.r.l.</Text>, iscritta al Registro delle imprese con numero TO-1215320, con sede legale in Via Carlo Allioni 3 - Torino Codice
                            Fiscale e Partita Iva 11461510015, PEC rkh@legalmail.it, in persona del legale rappresentante pro tempore sig. Giacomo
                            Sapienza (di seguito “<Text style={{fontFamily: "Helvetica-Bold"}}>RKH</Text>”)
                        </Text>
                    </View>
                    <View style={styles.section1}>
                        <Text>E</Text>
                    </View>
                    <View style={styles.sectionInfo}>
                        <View style={styles.sectionInfoCol}>
                            <Text>Nome: {nome}</Text>
                            <Text>Data di Nascita: {moment(dataNascita, 'YYYY-MM-DD').format('DD/MM/YYYY')}</Text>
                            <Text>Residenza: {residenza}</Text>
                        </View>
                        <View style={styles.sectionInfoCol}>
                            <Text>Cognome: {cognome}</Text>
                            <Text>Luogo di Nascita: {luogoNascita}</Text>
                            <Text>Codice Fiscale: {cf}</Text>
                        </View>
                    </View>
                    <View style={styles.section2}>
                        <Text>(di seguito "<Text style={{fontFamily: "Helvetica-Bold"}}>Ospite</Text>")</Text>
                    </View>
                    <View style={styles.section1}>
                        <Text>PREMESSO CHE</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text style={{paddingBottom: 5}}>
                            - RKH è produttrice e proprietaria del programma denominato “<Text style={{fontFamily: "Helvetica-Bold"}}>{programma}</Text>” trasmesso e diffuso, in formato audio/video
                            o solo in formato audio, attraverso la tecnologia podcast, via Internet, su siti Web di vario genere, YouTube, Spotify,
                            nonché su altri canali e/o piattaforme di servizi audio/musicali (“<Text style={{fontFamily: "Helvetica-Bold"}}>Programma</Text>”);
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            - il Programma, di genere satirico, è costruito su gag e situazioni quasi del tutto improvvisate caratterizzate da un
                            linguaggio scurrile e volgare sia dei conduttori che degli ospiti in studio i quali sono consapevoli che potranno essere
                            oggetto di scherno da parte dei conduttori e degli altri ospiti;
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            - l’Ospite invitato a partecipare alla puntata registrata il giorno {moment(dataRiprese, 'YYYY-MM-DD').format('DD/MM/YYYY')} , dichiara di conoscere il Programma,
                            di essere consapevole del linguaggio scurrile, greve e maleducato che lo caratterizza nonché del fatto che potrà essere
                            preso in giro anche con epiteti offensivi ai quali, ovviamente, potrà replicare e rispondere con la stessa forma di
                            linguaggio (di seguito “<Text style={{fontFamily: "Helvetica-Bold"}}>Partecipazione</Text>”);
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            - l’Ospite accetta il format e le caratteristiche del Programma e concede, sin d’ora, a RKH, l’autorizzazione esclusiva a
                            trasmettere la Partecipazione in differita e/o registrata, a diffonderla ed utilizzarla direttamente o tramite terzi suoi
                            aventi causa, in qualsiasi modo e forma oggi conosciuti o in futuro inventati;
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            - l’Ospite, con la sottoscrizione della presente scrittura privata (di seguito “<Text style={{fontFamily: "Helvetica-Bold"}}>Liberatoria</Text>”), acconsente espressamente alla
                            cessione, in favore di RKH, di tutti i diritti relativi alla Partecipazione ed alla loro utilizzazione da parte di quest’ultima
                            e/o di terzi suoi aventi causa, ai sensi e secondo i termini di cui alla Liberatoria, nonché di accettare il linguaggio scurrile
                            e maleducato che caratterizza il Programma;
                        </Text>
                    </View>
                    <View style={styles.section1}>
                        <Text style={{textTransform: 'uppercase'}}>TUTTO CIò PREMESSO</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text style={{paddingBottom: 5}}>
                            tra le parti in epigrafe, si stipula e si conviene quanto segue:
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            <Text style={{fontFamily: "Helvetica-Bold"}}>1.</Text> Le premesse costituiscono parte integrante ed essenziale della Liberatoria ed assumono,
                            per quanto occorra, valore
                            ed efficacia di patti.
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            <Text style={{fontFamily: "Helvetica-Bold"}}>2.</Text> L’Ospite, con la sottoscrizione della Liberatoria autorizza espressamente RKH e/o i terzi
                            suoi aventi causa ad effettuare
                            registrazioni audio e video della Partecipazione, al fine della sua successiva trasmissione,
                            produzione e pubblicazione,
                            in qualsiasi modo e forma, con qualsiasi mezzo tecnico attuale o futuro.
                        </Text>
                        <Text style={{paddingBottom: 5}}>
                            L’Ospite cede in via esclusiva ed a titolo gratuito a RKH tutti i diritti dallo stesso
                            vantati o vantabili circa la Partecipazione,
                            con efficacia assoluta e trasferibile per tutti i paesi del mondo e per tutto il periodo di
                            durata, originario e prorogato dei
                            predetti diritti ed in particolare, a mero titolo esemplificativo e non esaustivo:
                        </Text>
                        <Text style={{paddingLeft: 20}}>- il diritto di registrare e/o far riprodurre la Partecipazione e/o frammenti di essa
                            su videogrammi, files digitali ed altri
                            mezzi di registrazione e riproduzione di suoni e voci;</Text>
                        <Text style={{paddingLeft: 20}}>- il diritto di duplicare e/o far duplicare, utilizzare e/o far utilizzare, diffondere
                            e/o far diffondere, trasmettere e/o far
                            trasmettere, comunicare e/o far comunicare attraverso qualsiasi media e mezzo,
                            distribuire e/o far distribuire da terzi
                            la Partecipazione attraverso qualsiasi media, sito web e/o qualsiasi altro mezzo di
                            comunicazione e diffusione;</Text>
                        <Text style={{paddingLeft: 20}}>- il diritto di streaming e downloading, gratuito e/o pagamento, della Partecipazione
                            attraverso i media e siti web di
                            terzi aventi causa di RKH e/o il diritto di loro riproduzione, distribuzione e
                            vendita;</Text>
                        <Text style={{paddingLeft: 20}}>- il diritto di utilizzare e/o far utilizzare la Partecipazione o frammenti della
                            stessa in prodotti e supporti refrain e/o
                            promozionali di RKH e/o del Programma;</Text>
                    </View>
                </View>
            </Page>
            <Page>
                <View style={styles.frame}>
                    <View style={styles.section2}>
                        <Text style={{paddingLeft: 20}}>- il libero sfruttamento delle registrazioni audio/video effettuate, senza limiti di
                            spazio e/o passaggi;</Text>
                        <Text style={{paddingLeft: 20, paddingBottom:5}}>- ogni e qualsiasi più ampio diritto riconosciuto a RKH dalle vigenti e/o future
                            normative in tema di diritto d’autore, oltre
                            che ogni e qualsiasi diritto di utilizzazione in via completamente gratuita del nome,
                            dello pseudonimo, dell’immagine e
                            di ogni e qualsiasi segno distintivo, presente e/o futuro, relativo o abbinato
                            all’Ospite, per qualsivoglia utilizzazione da
                            parte di RKH e/o dei terzi suoi aventi causa, con il solo limite del rispetto degli
                            inderogabili diritti morali vantati
                            dall’Ospite.</Text>
                        <Text>L’Ospite, infine, garantisce espressamente a RKH di detenere ogni e qualsiasi diritto relativo alle prestazioni dallo stesso
                            rese nella Partecipazione, di non essere vincolato in alcun modo e forma nei confronti di terzi al riguardo e di poter
                            liberamente disporre dei diritti delle proprie prestazioni e dei diritti di immagine della propria persona al fine della loro
                            registrazione e riproduzione nella Partecipazione nonché di poterne ampiamente disporne, manlevando RKH da ogni e
                            qualsiasi pretesa o richiesta di terzi al riguardo.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>3.</Text> L’Ospite prende atto e dichiara di accettare che all’interno del Programma potranno comparire, in qualità di sponsor,
                            marchi identificanti beni e/o servizi visibili nel corso del Programma tramite l’inserimento di banner, inserzioni
                            pubblicitarie, cartellonistica, product placement e/o mediante il loro abbinamento a prodotti e strumentazioni utilizzate
                            nel corso del Programma, nonché spot pubblicitari e/o commerciali.</Text>
                        <Text>Al riguardo RKH espressamente dichiara che l’eventuale inserimento di detti sponsor risulta finalizzato soltanto alla
                            copertura dei costi di realizzazione del Programma.</Text>
                        <Text>L’Ospite altresì garantisce espressamente a RKH di essere maggiorenne, di detenere ogni e qualsiasi diritto relativo alle
                            prestazioni dallo stesso rese, di non essere vincolato in alcun modo e forma nei confronti di terzi al riguardo e di poter
                            liberamente disporre delle proprie prestazioni, manlevando RKH da ogni e qualsiasi pretesa o richiesta di terzi al
                            riguardo.</Text>
                        <Text>In virtù di quanto indicato nelle premesse della presente Liberatoria, l’Ospite dichiara di essere a conoscenza della
                            peculiarità del Programma, dei temi in esso trattati, del linguaggio scurrile e poco ortodosso che lo caratterizza e della
                            possibilità che possa essere preso in giro e schernito dai conduttori e/o da altri ospiti.</Text>
                        <Text>L’Ospite si obbliga a non rilasciare dichiarazioni che risultino in palese violazione di norme di legge, oppure
                            discriminatorie in qualsivoglia modo e forma, assumendosi la sola e completa responsabilità di tutte le dichiarazioni da
                            lui rese nel corso della Partecipazione e le relative conseguenze, manlevando dunque RKH da qualsivoglia responsabilità
                            al riguardo.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>4.</Text> La produzione e definitiva realizzazione della Partecipazione avverrà presso gli studi di proprietà ed a sole ed esclusive
                            spese di RKH.</Text>
                        <Text>Si da in particolare atto che, posta la natura promo-pubblicitaria della Partecipazione e le sue finalità di diffusione e
                            promozione dell’Ospite e della sua immagine, l’Ospite dichiara di accettare che nulla sia corrisposto per la
                            Partecipazione stessa.</Text>
                        <Text>La gestione di ogni e qualsiasi successivo aspetto relativo alla diffusione ed utilizzazione in generale della Partecipazione,
                            inclusa la sottoscrizione di qualsivoglia accordo volto a concedere a terzi l’uso della stessa (in via provvisoria o a titolo
                            definitivo) spetterà in via esclusiva a RKH.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>5.</Text> Le registrazioni della Partecipazione, nonché il materiale di scarto ad essa relativo, resteranno di sola ed esclusiva
                            proprietà di RKH per tutto il periodo di tempo previsto dalla L. 633/1941 in materia di diritto d’autore, la quale avrà
                            libera utilizzazione della Partecipazione con ulteriore facoltà di cedere a terzi, in tutto o in parte, uno, più o tutti i diritti
                            acquisiti da RKH con la presente Liberatoria.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>6.</Text> L’Accordo ha durata dalla data odierna e valore ed efficacia per tutto il periodo di protezione legale riservato a RKH
                            dalle vigenti e/o future normative in tema di diritto d’autore.</Text>
                        <Text>La mancata o insufficiente utilizzazione della Partecipazione, da parte di RKH e/o di terzi suoi aventi causa, non
                            comporterà alcuna conseguenza sulle cessioni poste in essere dall’Ospite a favore di RKH e sui diritti ceduti e riconosciuti
                            ai sensi e per gli effetti dei patti contenuti nella Liberatoria che resteranno, dunque, validi ed operanti ad ogni effetto
                            di legge anche in tale ipotesi.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>7.</Text> L’Ospite acconsente a che RKH utilizzi i suoi dati personali per le finalità di cui alla Liberatoria e, in ogni caso, nel
                            rispetto del Regolamento UE n. 2016/679.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text><Text style={{fontFamily: "Helvetica-Bold"}}>8.</Text> L’Accordo è regolato dalla legge italiana ed è sottoposto alla giurisdizione esclusiva dell’Autorità Giudiziaria Italiana.
                            Per qualsiasi controversia dovesse insorgere in ordine alla stipula, validità, esecuzione, interpretazione e risoluzione
                            dell’Accordo sarà competente in via esclusiva il Foro di Milano.</Text>
                    </View>
                    <View style={styles.section2}>
                        <Text>Fatto, letto, confermato e sottoscritto</Text>
                        <Text>Torino, il {moment().format('DD/MM/YYYY')}</Text>
                    </View>
                    <View style={styles.footer}>
                        <View style={styles.firma}>
                            <Image src={firma} style={{width: '160px'}} />
                        </View>
                        <View style={styles.firma}>
                            <Text style={{fontFamily: "Helvetica-Bold", paddingTop: 10}}>L'Ospite</Text>
                            <Text style={{paddingTop: 40}}>__________________________</Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )



    return (
        <div>
            <Container>
                <Container className="text-center mb-5">
                    <h3 className="text-warning">Liberatoria per riprese foto/video/audio <span style={{fontSize: 20}} className="ml-3"><Badge variant="primary">BETA</Badge></span></h3>
                    <p style={{fontSize:'smaller'}}>Tutti i campi sono obbligatori.</p>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nome
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Cognome
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Cognome"
                                    value={cognome}
                                    onChange={e => setCognome(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Data di Nascita
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Data di Nascita"
                                    value={dataNascita}
                                    onChange={e => setDataNascita(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Luogo di Nascita
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Luogo di Nascita"
                                    value={luogoNascita}
                                    onChange={e => setLuogoNascita(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Luogo di Residenza
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Luogo di Residenza"
                                    value={residenza}
                                    onChange={e => setResidenza(e.target.value)}
                                />
                                <Form.Text>Indicare indirizzo completo.</Form.Text>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Codice Fiscale
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Codice Fiscale"
                                    value={cf}
                                    onChange={e => setCf(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Nome Programma
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome Programma"
                                    value={programma}
                                    onChange={e => setProgramma(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <Form.Label>
                                    Data Riprese
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    value={dataRiprese}
                                    onChange={e => setDataRiprese(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
                <Container className="text-center mt-4">
                    {
                        nome!=='' && cognome!=='' && dataNascita!=='' && luogoNascita!=='' && residenza!=='' && dataRiprese!=='' && programma!=='' && cf!=='' ?
                            <PDFDownloadLink document={<DocumentoPdf />} fileName={'autodichiarazione' + '.pdf'}>
                                {({ blob, url, loading, error }) => (url ?
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <Button
                                                variant="warning"
                                                className="rounded-lg"
                                            >
                                                Genera PDF
                                            </Button>
                                        </a>
                                        :
                                        <Spinner animation="border" variant="warning" />
                                )}
                            </PDFDownloadLink>
                            :
                            <Alert variant="warning">Compila tutti i campi.</Alert>
                    }
                </Container>
            </Container>
        </div>
    )
}

export default Liberatoria