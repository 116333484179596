import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import moment from "moment";
import {FaTrashAlt} from "react-icons/fa";


function EditProp() {

    const [nome, setNome] = useState()
    const [descrizione, setDescrizione] = useState()
    const [quantita, setQuantita] = useState()
    const [ubicazione, setUbicazione] = useState()

    const queryId = useLocation().pathname.split('/')[4]
    const [info, setInfo] = useState()

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('magazzino').doc(queryId)
        actorsRef.onSnapshot(snapshot => {
            setInfo(snapshot.data())
        })
    }, [])

    useEffect(() => {
        if(info) {
            setNome(info.nome)
            setDescrizione(info.descrizione)
            setQuantita(info.quantita)
            setUbicazione(info.ubicazione)
        }
    }, [info])

    const [error, setError] = useState('')
    const [result, setResult] = useState('')


    return (
        <div>
            {
                info ?
                    <Container>
                        <Container
                            className="text-center mb-5"
                        >
                            <h2 className="text-warning">
                                Modifica attore
                            </h2>
                            <p style={{textAlign:'center', fontSize:'smaller'}}>
                                Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori
                            </p>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Nome <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Ubicazione <span className="asterisco">*</span> </Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        onChange={e => setUbicazione(e.target.value)}
                                        value={ubicazione}
                                        className="mb-3"
                                    >
                                        <option disabled value="">Scegli...</option>
                                        <option value="A1">A1</option>
                                        <option value="A2">A2</option>
                                        <option value="A3">A3</option>
                                        <option value="A4">A4</option>
                                        <option value="A5">A5</option>
                                        <option value="A6">A6</option>
                                        <option value="A7">A7</option>
                                        <option value="A8">A8</option>
                                        <option value="A9">A9</option>
                                        <option value="A10">A10</option>
                                        <option value="A11">A11</option>
                                        <option value="A12">A12</option>
                                        <option value="A13">A13</option>
                                        <option value="B1">B1</option>
                                        <option value="B2">B2</option>
                                        <option value="B3">B3</option>
                                        <option value="B4">B4</option>
                                        <option value="B5">B5</option>
                                        <option value="B6">B6</option>
                                        <option value="B7">B7</option>
                                        <option value="B8">B8</option>
                                        <option value="B9">B9</option>
                                        <option value="B10">B10</option>
                                        <option value="Speciale">Speciale</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Text>Quantità <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="number"
                                        size="sm"
                                        value={quantita}
                                        onChange={e => setQuantita(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Descrizione</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={descrizione}
                                        onChange={e => setDescrizione(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            {
                                error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                            }

                            {
                                result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                            }
                        </Container>
                        <Container
                            className="mt-2 text-right"
                        >
                            <Row>
                                <Col className="text-left">
                                    <FaTrashAlt
                                        size="1.4em"
                                        className="m-3"
                                        color="red"
                                        onClick={() => {
                                            if(window.confirm('Vuoi ELIMINARE questa prop?')) {
                                                firebase.storage().ref('images/magazzino').child(queryId).delete()
                                                firebase.firestore().collection('magazzino').doc(queryId).delete()
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        className="mt-3 text-right"
                                        variant="warning"
                                        onClick={
                                            () => {

                                                if(nome==='') setError('Nome non valido.')
                                                else if(quantita==='' || quantita===0) setError('Quantità non valida.')
                                                else if(ubicazione==='') setError('Ubicazione non valida.')
                                                else {
                                                    let id = info.id

                                                    firebase.firestore().collection('magazzino').doc(id).set({
                                                        id: id,
                                                        nome: nome,
                                                        quantita: quantita,
                                                        ubicazione: ubicazione,
                                                        descrizione: descrizione,
                                                    })
                                                    setError('')
                                                    setResult('Dati aggiornati con successo!')
                                                }
                                            }
                                        }
                                    >
                                        Salva modifiche
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    :
                    null
            }
        </div>
    )

}

export default EditProp