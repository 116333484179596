import {Col, Container, Row} from "react-bootstrap";
import logoRKH from './logoRKH.png'
import {Link, NavLink} from "react-router-dom";


function NavBarOspite() {
    return(
        <div className="mb-5">
            <Container fluid className="NavbarContainer">
                <Container>
                    <Row className="pt-4 pb-4">
                        <Col>
                            <Link
                                to={{pathname:'/'}}
                            >
                                <img src={logoRKH} alt="LogoRKH" height="30px"/>
                            </Link>
                        </Col>
                        <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                            <NavLink
                                to={{pathname:"/"}}
                                style={{
                                    color: "white"
                                }}
                            >
                                Home
                            </NavLink>
                        </Col>
                        <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                            <NavLink
                                to={{pathname:"/iscriviti"}}
                                style={{
                                    color: "white"
                                }}
                            >
                                Iscriviti
                            </NavLink>
                        </Col>
                        <Col xs="auto" sm="auto" md="auto" lg="auto" xl="auto">
                            <NavLink
                                to={{pathname:"/privacy"}}
                                style={{
                                    color: "white"
                                }}
                            >
                                Privacy
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default NavBarOspite
