import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import {useState} from "react";
import moment from 'moment'
import {Link} from "react-router-dom";
import firebase from "./firebase";
import {nanoid} from "nanoid";


function Subscribe() {

    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [sesso, setSesso] = useState('')
    const [dataNascita, setDataNascita] = useState('')
    const [luogoNascita, setLuogoNascita] = useState('')
    const [luogoDomicilio, setLuogoDomicilio] = useState('')
    const [cf, setCf] = useState('')
    const [email, setEmail] = useState('')
    const [telefono, setTelefono] = useState('')
    const [comparsa, setComparsa] = useState(false)
    const [figurante, setFigurante] = useState(false)
    const [attore, setAttore] = useState(false)
    const [livelloRecitazione, setLivelloRecitazione] = useState('')
    const [retribuzione, setRetribuzione] = useState('')
    const [instagram, setInstagram] = useState('')
    const [facebook, setFacebook] = useState('')
    const [youtube, setYoutube] = useState('')
    const [cv, setCv] = useState('')
    const [showreel, setShowreel] = useState('')
    const [fotoPrimoPiano, setFotoPrimoPiano] = useState('')
    const [altreFoto, setAltreFoto] = useState([])
    const [commenti, setCommenti] = useState('')
    const [accetto, setAccetto] = useState(false)
    const [nonAccetto, setNonAccetto] = useState(false)

    const [error, setError] = useState('')
    const [result, setResult] = useState('')


    return(
        <div>
            <Container>

                <Container className="mb-5">
                    <h2 style={{color:'gold', textAlign:'center'}}>Inviaci la tua candidatura</h2>
                    <p style={{textAlign:'center', fontSize:'smaller'}}>Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori</p>
                </Container>

                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Nome <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Mario"
                            onChange={e => setNome(e.target.value)}
                            value={nome}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Cognome <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Rossi"
                            onChange={e => setCognome(e.target.value)}
                            value={cognome}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Sesso <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            as="select"
                            onChange={e => setSesso(e.target.value)}
                            value={sesso}
                        >
                            <option value="" disabled>Scegli...</option>
                            <option value="Uomo">Uomo</option>
                            <option value="Donna">Donna</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Data di Nascita <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="date"
                            max={moment().format('YYYY-MM-DD')}
                            onChange={e => setDataNascita(e.target.value)}
                            value={dataNascita}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Luogo di Nascita <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Torino"
                            onChange={e => setLuogoNascita(e.target.value)}
                            value={luogoNascita}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Città di Domicilio <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Torino"
                            onChange={e => setLuogoDomicilio(e.target.value)}
                            value={luogoDomicilio}
                        />
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Codice Fiscale <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Codice Fiscale"
                            onChange={e => setCf(e.target.value)}
                            value={cf}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Email <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="email@email.com"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Telefono <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            type="tel"
                            placeholder="+39 333 123 4567"
                            onChange={e => setTelefono(e.target.value)}
                            value={telefono}
                        />
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Row>
                            <Col>
                                <Form.Label className="mb-0">Posizione <span className="asterisco">*</span> </Form.Label>
                            </Col>
                        </Row>
                        <Row className="pt-2">
                            <Col md="auto">
                                <Form.Check
                                    label={<p onClick={() => comparsa ? setComparsa(false) : setComparsa(true)}>Comparsa</p>}
                                    onChange={() => comparsa ? setComparsa(false) : setComparsa(true)}
                                    checked={comparsa}
                                />
                            </Col>
                            <Col md="auto">
                                <Form.Check
                                    label={<p onClick={() => figurante ? setFigurante(false) : setFigurante(true)}>Figurante</p>}
                                    onChange={() => figurante ? setFigurante(false) : setFigurante(true)}
                                    checked={figurante}
                                />
                            </Col>
                            <Col md="auto">
                                <Form.Check
                                    label={<p onClick={() => attore ? setAttore(false) : setAttore(true)}>Attore</p>}
                                    onChange={() => attore ? setAttore(false) : setAttore(true)}
                                    checked={attore}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Livello Recitazione <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            as="select"
                            onChange={e => setLivelloRecitazione(e.target.value)}
                            value={livelloRecitazione}
                        >
                            <option value="" disabled>Scegli...</option>
                            <option value="Alto">Alto</option>
                            <option value="Medio">Medio</option>
                            <option value="Basso">Basso</option>
                        </Form.Control>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Retribuzione <span className="asterisco">*</span> </Form.Label>
                        <Form.Control
                            as="select"
                            onChange={e => setRetribuzione(e.target.value)}
                            value={retribuzione}
                        >
                            <option value="" disabled>Scegli...</option>
                            <option value="No">Disponibile solo per prestazioni non retribuite</option>
                            <option value="Si">Disponibile solo per prestazioni retribuite</option>
                            <option value="Entrambe">Disponibile per entrambe</option>
                        </Form.Control>
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Instagram</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="https://www.instagram.com/nickname"
                            onChange={e => setInstagram(e.target.value)}
                            value={instagram}
                        />
                        <Form.Text>
                            Solo link a profili Instagram
                        </Form.Text>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Facebook</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="https://www.facebook.com/nickname"
                            onChange={e => setFacebook(e.target.value)}
                            value={facebook}
                        />
                        <Form.Text>
                            Solo link a profili Facebook
                        </Form.Text>
                    </Col>
                    <Col xs="12" sm="12" md="4" lg="4" xl="4" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>YouTube</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="https://www.youtube.com/nickname"
                            onChange={e => setYoutube(e.target.value)}
                            value={youtube}
                        />
                        <Form.Text>
                            Solo link a canali YouTube
                        </Form.Text>
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-3 mb-lg-3 mb-xl-3">
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Curriculum Vitae</Form.Label>
                        <Form.File
                            label={
                                cv==='' ?
                                    <p>Scegli un file</p>
                                    :
                                    <p>{cv.name}</p>
                            }
                            data-browse="Scegli"
                            onChange={e => {
                                if(e.target.files[0]) {
                                    if(e.target.files[0].type === 'application/pdf') {
                                        if(e.target.files[0].size <= 3*1024*1024) {
                                            setCv(e.target.files[0])
                                            setError('')
                                        } else {
                                            setCv('')
                                            setError('Curriculum Vitae - File troppo pesante')
                                        }
                                    } else {
                                        setCv('')
                                        setError('Curriculum Vitae - Formato file non supportato')
                                    }
                                }
                            }}
                            custom
                        />
                        <Form.Text>
                            Dimensione massima: 3MB - Solo documenti formato PDF
                        </Form.Text>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Showreel</Form.Label>
                        <Form.Control
                            type="link"
                            placeholder="https://www.youtube.com/watch?v=videoID"
                            onChange={e => setShowreel(e.target.value)}
                            value={showreel}
                        />
                        <Form.Text>
                            Solo link a video YouTube o Vimeo
                        </Form.Text>
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-4 mb-lg-4 mb-xl-4">
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Foto Primo Piano <span className="asterisco">*</span> </Form.Label>
                        <Form.File
                            label={
                                fotoPrimoPiano==='' ?
                                    <p>Scegli un file</p>
                                    :
                                    <p>{fotoPrimoPiano.name}</p>
                            }
                            data-browse="Scegli"
                            onChange={e => {
                                if(e.target.files[0]) {
                                    if(e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
                                        if(e.target.files[0].size <= 3*1024*1024) {
                                            setFotoPrimoPiano(e.target.files[0])
                                            setError('')
                                        } else {
                                            setFotoPrimoPiano('')
                                            setError('Foto Primo Piano - File troppo pesante')
                                        }
                                    } else {
                                        setFotoPrimoPiano('')
                                        setError('Foto Primo Piano - Formato file non supportato')
                                    }
                                }
                            }}
                            custom
                        />
                        <Form.Text>
                            Dimensione massima: 3MB - Solo formati .jpg .png .jpeg
                        </Form.Text>
                    </Col>
                    <Col xs="12" sm="12" md="6" lg="6" xl="6" className="mb-4 mb-sm-4 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Altre foto</Form.Label>
                        <Form.File
                            label={
                                <p>{altreFoto.length} immagini caricate</p>
                            }
                            data-browse="Scegli"
                            onChange={e => {
                                let res = []
                                if(e.target.files) {
                                    if(e.target.files.length>3) {
                                        setError('Altre Foto - Caricare massimo 3 foto')
                                        res = []
                                    } else {
                                        if(e.target.files[0]) {
                                            if(e.target.files[0].type==='image/jpg' || e.target.files[0].type==='image/jpeg' || e.target.files[0].type==='image/png') {
                                                if(e.target.files[0].size<=3*1024*1024) {
                                                    res.push(e.target.files[0])
                                                    setError('')
                                                } else {
                                                    setError('Altre Foto - File troppo pesante')
                                                    res = []
                                                }
                                            } else {
                                                setError('Altre Foto - Formato file non supportato')
                                                res = []
                                            }
                                        }
                                        if(e.target.files[1]) {
                                            if(e.target.files[1].type==='image/jpg' || e.target.files[1].type==='image/jpeg' || e.target.files[1].type==='image/png') {
                                                if(e.target.files[1].size<=5*1024*1024) {
                                                    res.push(e.target.files[1])
                                                    setError('')
                                                } else {
                                                    setError('Altre Foto - File troppo pesante')
                                                    res = []
                                                }
                                            } else {
                                                setError('Altre Foto - Formato file non supportato')
                                                res = []
                                            }
                                        }
                                        if(e.target.files[2]) {
                                            if(e.target.files[2].type==='image/jpg' || e.target.files[2].type==='image/jpeg' || e.target.files[2].type==='image/png') {
                                                if(e.target.files[2].size<=5*1024*1024) {
                                                    res.push(e.target.files[2])
                                                    setError('')
                                                } else {
                                                    setError('Altre Foto - File troppo pesante')
                                                    res = []
                                                }
                                            } else {
                                                setError('Altre Foto - Formato file non supportato')
                                                res = []
                                            }
                                        }
                                    }
                                }
                                setAltreFoto(res)
                            }}
                            multiple
                            custom
                        />
                        <Form.Text>
                            Max 3 foto - Dimensione massima: 3MB a foto - Solo formati .jpg .png .jpeg
                        </Form.Text>
                    </Col>
                </Row>
                <Row className="mb-0 mb-sm-0 mb-md-4 mb-lg-4 mb-xl-4">
                    <Col xs="12" sm="12" md="12" lg="12" xl="12" className="mb-2 mb-sm-2 mb-md-0 mb-lg-0 mb-xl-0">
                        <Form.Label>Commenti</Form.Label>
                        <Form.Control
                            as="textarea"
                            placeholder="Commenti"
                            value={commenti}
                            onChange={e => setCommenti(e.target.value)}
                        />
                    </Col>
                </Row>

                <Form.Label>Informativa Privacy e Trattamento dei Dati Personali</Form.Label> <span className="asterisco">*</span>
                <Container className="mb-3 border border-light rounded-lg py-3">
                    <p>
                        Dichiaro di aver letto e compreso l'<Link to="/privacy" style={{color:'#ffcc00'}}>informativa sulla privacy</Link> e autorizzo il trattamento dei miei dati personali ai sensi del decreto legislativo N° 196/2003 sulla tutela della privacy.
                    </p>
                </Container>
                <Row className="mb-0 mb-sm-0 mb-md-4 mb-lg-4 mb-xl-4 d-flex justify-content-center">
                    <Col md="auto">
                        <Form.Check
                            label={<span onClick={() => accetto ? null : setInformativa(true)}><span>Accetto</span> <span className="asterisco">*</span></span>}
                            type="radio"
                            onClick={() => accetto ? null : setInformativa(true)}
                            checked={accetto}
                        />
                    </Col>
                    <Col md="auto">
                        <Form.Check
                            label={<span onClick={() => nonAccetto ? null : setInformativa(false)}>Non accetto</span>}
                            type="radio"
                            onClick={() => nonAccetto ? null : setInformativa(false)}
                            checked={nonAccetto}
                        />
                    </Col>
                </Row>

                {
                    error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                }

                {
                    result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                }


                <div className="mb-5 d-flex justify-content-end">
                    <Button
                        size="lg"
                        variant="warning"
                        onClick={
                            () => {

                                if(nome==='') setError('Nome non valido.')
                                else if(cognome==='') setError('Cognome non valido.')
                                else if(sesso==='') setError('Sesso non valido.')
                                else if(dataNascita==='') setError('Data di nascita non valida.')
                                else if(moment(dataNascita, 'YYYY-MM-DD') >= moment()) setError('Data di nascita non valida.')
                                else if(luogoNascita==='') setError('Luogo di nascita non valido.')
                                else if(luogoDomicilio==='') setError('Luogo di domicilio non valido.')
                                else if(cf==='') setError('Codice fiscale non valido.')
                                else if(email==='' || email.split('@').length!==2) setError('Email non valida.')
                                else if(email.split('@')[0].length===0 || email.split('@')[1].length===0) setError('Email non valida.')
                                else if(telefono==='') setError('Telefono non valido.')
                                else if(!comparsa && !figurante && !attore) setError('Posizione non valida.')
                                else if(livelloRecitazione==='') setError('Livello recitazione non valido.')
                                else if(retribuzione==='') setError('Retribuzione non valida.')
                                else if(fotoPrimoPiano==='') setError('Caricare una foto primo piano')
                                else if(!accetto) setError('Per candidarsi, è necessario accettare l\'informativa sulla privacy e sul trattamento dei dati personali.')
                                else {
                                    let string = cognome + nome + '-' + nanoid(5)
                                    string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
                                    let id = string.split(' ').join('')
                                    firebase.storage().ref('images/actors/' + id + '/0').put(fotoPrimoPiano).then(() => {
                                        console.log('Foto Primo Piano caricata')
                                    }).catch(() => setError('Errore nel caricamento della foto primo piano'))
                                    if(cv) {
                                        firebase.storage().ref('images/actors/' + id + '/cv').put(cv).then(() => {
                                            console.log('CV caricato')
                                        }).catch(() => setError('Errore nel caricamento del cv'))
                                    }
                                    if(altreFoto) {
                                        for(let i=1; i<altreFoto.length+1; i++) {
                                            firebase.storage().ref('images/actors/' + id + '/' + i).put(altreFoto[i-1]).then(() => {
                                                console.log('Altre Foto (' + i + ') caricata')
                                            }).catch(() => setError('Errore nel caricamento della foto n. ' + i + ' di Altre Foto'))
                                        }
                                    }
                                    firebase.firestore().collection('provActors').doc(id).set({
                                        id: id,
                                        nome: nome,
                                        cognome: cognome,
                                        sesso: sesso,
                                        dataNascita: dataNascita,
                                        luogoNascita: luogoNascita,
                                        luogoDomicilio: luogoDomicilio,
                                        cf: cf,
                                        email: email,
                                        telefono: telefono,
                                        comparsa: comparsa,
                                        figurante: figurante,
                                        attore: attore,
                                        livelloRecitazione: livelloRecitazione,
                                        retribuzione: retribuzione,
                                        instagram: instagram,
                                        facebook: facebook,
                                        youtube: youtube,
                                        showreel: showreel,
                                        commenti: commenti,
                                        accetto: accetto,
                                        note: ''
                                    }).then(() => {
                                        setError('')
                                        setResult('Candidatura inviata con successo!')
                                        clearInputs()
                                    }).catch(() => setError('Errore nell\'invio della candidatura. Riprova.'))

                                }
                            }
                        }
                    >
                        Invia
                    </Button>
                </div>
            </Container>
        </div>
    )

    function setInformativa(bool) {
        if(bool) {
            setAccetto(true)
            setNonAccetto(false)
        }else {
            setAccetto(false)
            setNonAccetto(true)
        }
    }

    function clearInputs() {
        setNome('')
        setCognome('')
        setSesso('')
        setDataNascita('')
        setLuogoNascita('')
        setLuogoDomicilio('')
        setCf('')
        setEmail('')
        setTelefono('')
        setComparsa(false)
        setFigurante(false)
        setAttore(false)
        setLivelloRecitazione('')
        setRetribuzione(false)
        setInstagram('')
        setFacebook('')
        setYoutube('')
        setCv('')
        setShowreel('')
        setFotoPrimoPiano('')
        setAltreFoto('')
        setCommenti('')
        setAccetto(false)
        setNonAccetto(false)
    }

}

export default Subscribe
