import firebase from "./firebase";
import {useState} from "react";
import { FaDownload } from "react-icons/fa";


function CardVerbali({nome}) {

    const [url, setUrl] = useState('')
    const ref = firebase.storage().ref('pdf/verbali/').child(nome)

    ref.getDownloadURL().then((url) => {
        setUrl(url)
    }).catch((err) => {
        console.log('err')
    })

    return (
        <div className="cardVerbali">
            <p className="font-weight-bolder">{nome}</p>
            <div className="text-right">
                <a href={url} target="_blank" rel="noreferrer">
                    <FaDownload
                        size="1.3em"
                        color="#ffcc00"
                        className="mt-4 mr-4 iconVerbale"
                    />
                </a>
            </div>
        </div>
    )
}

export default CardVerbali