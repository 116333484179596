import {Accordion, Container} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {FaArchive} from "react-icons/all";
import {CardBookingAttrezzatura} from "./CardBookingAttrezzatura";
import firebase from "./firebase";


export function ArchivioPrenotazioni() {


    const [ritiri, setRitiri] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('ritiriAttrezzatura')
        actorsRef.onSnapshot(snapshot => {
            let rit = snapshot.docs.sort((a,b) => (a.data().date>b.data().date) ? 1 : ((b.data().date > a.data().date) ? -1 : 0))
            setRitiri(rit.reverse())
        })
    }, [])

    return (
        <div className="mb-5">
            <Container>
                <div className="text-center mb-5">
                    <h3 className="text-warning">Archivio ritiri attrezzatura</h3>
                    <p className="small">Storico ritiri attrezzatura.</p>
                </div>
                <Accordion className="text-dark">
                    {
                        ritiri ?
                            ritiri.map(a => {
                                return <CardBookingAttrezzatura a={a.data()} />
                            })
                            :
                            null
                    }
                </Accordion>
            </Container>
        </div>
    )
}