import moment from "moment";


export function PioggiaAMilano() {

    return (
        <div>
            {
                moment().isBefore(moment('2021-11-12 00:00', 'YYYY-MM-DD H:mm')) ?
                    window.location.replace("https://rkhstudio.com/anzj-jack-sapienza-pioggia-a-milano-preascolto/")
                    :
                    window.location.replace("https://lnk.to/pioggiaamilano")
            }
        </div>
    )
}
