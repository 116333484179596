import {Badge, Button, Card, Form, Modal} from "react-bootstrap";
import firebase from "./firebase";
import {useState} from "react";
import {FaPencilAlt} from "react-icons/fa";
import MultiSelect from "react-multi-select-component";
import {options} from "./NewScreen";


export function CardScreen({props}) {

    const [url, setUrl] = useState('')
    firebase.storage().ref('images/screen/').child(props.id).getDownloadURL().then((link) => {
        setUrl(link)
    })

    const [showModalEdit, setShowModalEdit] = useState(false)

    const [film, setFilm] = useState(props.film)
    const [regista, setRegista] = useState(props.regista)
    const [selected, setSelected] = useState(props.tags)


    return (
        <div className="mb-3" style={{width:'100%'}}>
            <Card className="text-dark" >
                <a href={url} target="_blank" rel="noreferrer">
                    <Card.Img variant="top" src={url} />
                </a>
                <Card.Body>
                    <Card.Title>
                        <h5 className="font-weight-bold">{props.film}</h5>
                        <h6>{props.regista}</h6>
                    </Card.Title>
                    <Card.Text>
                        {
                            props.tags.map(t => <Badge variant="warning" className="mr-1">{t.label}</Badge>)
                        }
                        <FaPencilAlt className="ml-2 opacity75 iconTrash" onClick={() => setShowModalEdit(true)}/>
                    </Card.Text>
                </Card.Body>
            </Card>

            <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)} className="text-dark"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Screen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Label className="small">Titolo film</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome Film"
                            value={film}
                            onChange={e => setFilm(e.target.value)}
                        />
                    </div>
                    <div>
                        <Form.Label className="small">Regista</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome Regista"
                            value={regista}
                            onChange={e => setRegista(e.target.value)}
                        />
                    </div>
                    <div>
                        <Form.Label className="small">Tags</Form.Label>
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            className="text-dark"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" size="sm" className="rounded10" onClick={() => setShowModalEdit(false)}>
                        Annulla
                    </Button>
                    <Button variant="danger" size="sm" className="rounded10" onClick={() => {
                        if(window.confirm('Eliminare questo screen?')) {
                            firebase.storage().ref('images/screen').child(props.id).delete().then(() => {
                                firebase.firestore().collection('screen').doc(props.id).delete().then(() => setShowModalEdit(false))
                            })
                        }
                    }}>
                        Elimina
                    </Button>
                    <Button variant="success" size="sm" className="rounded10" onClick={() => {
                        firebase.firestore().collection('screen').doc(props.id).update({
                            id: props.id,
                            film: film,
                            regista: regista,
                            tags: selected,
                        }).then(() => setShowModalEdit(false))
                    }}>
                        Aggiorna
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
