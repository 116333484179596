import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./Home";
import {Redirect, Route, Switch} from "react-router";
import NavBar from "./NavBar";
import Persona from "./Persona";
import Attori from "./Attori";
import Subscribe from "./Subscribe";
import Page404 from "./Page404";
import NavBarOspite from "./NavBarOspite";
import HomeOspiti from "./HomeOspiti";
import Login from "./Login";
import AttoriProvvisori from "./AttoriProvvisori";
import PrivacyOspiti from "./PrivacyOspiti";
import {BrowserRouter} from "react-router-dom";
import ProvPersona from "./ProvPersona";
import {useContext} from "react";
import {StateContext} from "./App";
import EditActor from "./EditActor";
import Costumi from "./Costumi";
import AddCostume from "./AddCostume";
import Autodichiarazione from "./Autodichiarazione";
import Strumenti from "./Strumenti";
import Magazzino from "./Magazzino";
import AddProp from "./AddProp";
import Liberatoria from "./Liberatoria";
import Verbale from "./Verbale";
import ArchivioVerbali from "./ArchivioVerbali";
import EditCostume from "./EditCostume";
import EditProp from "./EditProp";
import Attrezzatura from "./Attrezzatura";
import AddAttrezzatura from "./AddAttrezzatura";
import LoginAttrezzatura from "./LoginAttrezzatura";
import AttrezzaturaManage from "./AttrezzaturaManage";
import {ArchivioPrenotazioni} from "./ArchivioPrenotazioni";
import {TeamVideo} from "./TeamVideo/TeamVideo";
import NavBarVideo from "./TeamVideo/NavBarVideo";
import {Report} from "./TeamVideo/Report";
import {Calendario} from "./Calendario/Calendario";
import {AddUtente} from "./TeamVideo/AddUtente";
import {ReportUtente} from "./TeamVideo/ReportUtente";
import {CalendarioNew} from "./Calendario/CalendarioNew";
import {Screen} from "./Screen";
import {NewScreen} from "./NewScreen";
import {PioggiaAMilano} from "./PioggiaAMilano";


function Router() {

    const [state, dispatch] = useContext(StateContext)

    return (
            <div>
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/">
                            <NavBarOspite />
                            <HomeOspiti />
                        </Route>

                        <Route exact path="/privacy">
                            <NavBarOspite />
                            <PrivacyOspiti />
                        </Route>

                        <Route exact path="/iscriviti">
                            <NavBarOspite />
                            <Subscribe />
                        </Route>

                        <Route exact path="/areariservata/">
                            <Redirect to="/areariservata/home" />
                        </Route>
                        <Route exact path="/areariservata/home">
                            {
                                state.login ?
                                    <Home />
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/attori">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Attori />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/attoriProvvisori">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <AttoriProvvisori />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route path="/areariservata/attoriProvvisori/">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <ProvPersona />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route path="/areariservata/attori/">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Persona />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route path="/areariservata/attori/modifica/">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <EditActor />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/costumi">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Costumi />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/costumi/new">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <AddCostume />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route path="/areariservata/costumi/modifica/">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <EditCostume />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/attrezzatura">
                            {
                                state.loginAttrezzatura ?
                                    <div>
                                        <NavBar />
                                        <Attrezzatura />
                                    </div>
                                    :
                                    <LoginAttrezzatura />
                            }
                        </Route>
                        <Route exact path="/areariservata/attrezzatura/new">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <AddAttrezzatura />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/attrezzatura/manage">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <AttrezzaturaManage />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/attrezzatura/archivio">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <ArchivioPrenotazioni />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/magazzino">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Magazzino />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/magazzino/new">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <AddProp />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route path="/areariservata/magazzino/modifica/">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <EditProp />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/strumenti">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Strumenti />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/autodichiarazione">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Autodichiarazione />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/liberatoria">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Liberatoria />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/verbale">
                            {
                                    <div>
                                        <NavBar />
                                        <Verbale />
                                    </div>
                            }
                        </Route>

                        <Route exact path="/areariservata/archivioverbali">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <ArchivioVerbali />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/screen">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Screen />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/screen/new">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <NewScreen />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/calendario">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <Calendario />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>
                        <Route exact path="/areariservata/calendario/new">
                            {
                                state.login ?
                                    <div>
                                        <NavBar />
                                        <CalendarioNew />
                                    </div>
                                    :
                                    <Login />
                            }
                        </Route>

                        <Route exact path="/areariservata/teamvideo">
                            <div>
                                <TeamVideo />
                            </div>
                        </Route>
                        <Route exact path="/areariservata/teamvideo/report">
                            <div>
                                <NavBarVideo />
                                <Report />
                            </div>
                        </Route>
                        <Route exact path="/areariservata/teamvideo/report/addutente">
                            <div>
                                <NavBarVideo />
                                <AddUtente />
                            </div>
                        </Route>
                        <Route exact path="/areariservata/teamvideo/report/:utente">
                            <div>
                                <NavBarVideo />
                                <ReportUtente />
                            </div>
                        </Route>

                        <Route exact path="/pioggia-a-milano">
                            <PioggiaAMilano />
                        </Route>

                        <Route path="">
                            <NavBarOspite />
                            <Page404 />
                        </Route>
                    </Switch>
                </BrowserRouter>
            </div>
    );
}

export default Router
