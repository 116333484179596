import {Button, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import firebase from "../firebase";
import {CardCalendario} from "./CardCalendario";


export function Calendario() {

    const [eventi, setEventi] = useState([])
    useEffect(() => {
        const ref = firebase.firestore().collection('calendario')
        ref.onSnapshot(snapshot => {
            setEventi(snapshot.docs.map(d => d.data()))
        })
    }, [])
    let elenco = []
    elenco = eventi.filter(a => a.stato!=='Chiuso')


    return (
        <div className="pt-4 mb-5">
            <Container>
                <div className="text-center mb-5">
                    <h2 className="text-warning">Calendario</h2>
                    <p className="small mb-3">Calendario consegne video</p>
                    <Link to="/areariservata/calendario/new">
                        <Button variant="outline-warning" size="sm" className="rounded10" >Aggiungi</Button>
                    </Link>
                </div>
                <div>
                    {
                        elenco.length!==0 ?
                            elenco.map(e => <CardCalendario props={e} />)
                            :
                            null
                    }
                </div>
            </Container>
        </div>
    )

}
