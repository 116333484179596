import {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import firebase from "./firebase";
import ProvCard from "./ProvCard";


function AttoriProvvisori() {

    const [elencoAttori, setElencoAttori] = useState([])

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('provActors')
        actorsRef.onSnapshot(snapshot => {
            setElencoAttori(snapshot.docs)
        })
    }, [])

    return (
        <div>
            <Container className="mb-5">
                {
                    elencoAttori ?
                        Object.keys(elencoAttori).map(i => {
                            let a = elencoAttori[i]
                            return <ProvCard props={a.data()} />
                        })
                        :
                        <div>Non sono presenti nuovi attori.</div>
                }
            </Container>
        </div>
    )
}

export default AttoriProvvisori
