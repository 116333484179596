import {Badge, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FaInfoCircle, FaMinus, FaPlus} from "react-icons/fa";
import React, {useContext} from "react";
import {StateContext} from "./App";
import {addAttrezzatura, delAttrezzatura} from "./Actions";



function CardAttrezzatura({props, booked}) {

    const [state, dispatch] = useContext(StateContext)

    return(
        <Row className="mb-3">
            <Col md="auto">
                <a href={props.link} target="_blank" rel="noreferrer" >
                    <img src={props.link} height="50px"/>
                </a>
            </Col>
            <Col className="d-flex align-items-center">
                <div>
                    <p className="font-weight-bolder">{props.nome} <span>{
                        props.note ?
                            <OverlayTrigger key="right" placement="right" overlay={
                                <Tooltip id="tooltip-right">
                                    {props.note}
                                </Tooltip>
                            }>
                                <FaInfoCircle className="ml-2 mb-1" />
                            </OverlayTrigger>
                            :
                            null
                    }</span></p>
                    <p className="small text-black-50">ID: {props.id}</p>
                </div>
            </Col>
            <Col md="auto" className="d-flex align-items-center">
                {
                    state.attrezzatura.includes(props.id) ?
                        <div>
                            <Badge
                                variant="warning"
                                className="mr-3"
                            >
                                Selezionato
                            </Badge>
                            <FaMinus
                                size="1.5em"
                                color="black"
                                onClick={() => dispatch(delAttrezzatura(props.id))}
                            />
                        </div>
                        :
                        booked ?
                            <Badge
                                variant="danger"
                                className="mr-3"
                            >
                                Non disponibile
                            </Badge>
                            :
                            <div>
                                <Badge
                                    variant="success"
                                    className="mr-3"
                                >
                                    Disponibile
                                </Badge>
                                <FaPlus
                                    size="1.5em"
                                    color="black"
                                    onClick={() => dispatch(addAttrezzatura(props.id))}
                                />
                            </div>
                }
            </Col>
        </Row>

    )
}

export default CardAttrezzatura