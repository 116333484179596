import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useState} from "react";
import firebase from "firebase";
import {nanoid} from "nanoid";


export function AddUtente() {

    const [nome, setNome] = useState('')
    const [cognome, setCognome] = useState('')
    const [result, setResult] = useState('')

    return (
        <div>
            <Container>
                <div className="text-center mb-5">
                    <h2 className="text-warning">Nuovo Utente</h2>
                    <p className="small">Aggiungi nuovo utente per i report settimanali.</p>
                </div>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Label className="small">Nome *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome utente"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        />
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Label className="small">Nome *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Cognome utente"
                            value={cognome}
                            onChange={e => setCognome(e.target.value)}
                        />
                    </Col>
                    <Col className="text-right">
                        {
                            result ?
                                <span className="text-success font-weight-bold mr-3">{result}</span>
                                :
                                null
                        }
                        <Button
                            variant="warning"
                            disabled={!nome || !cognome}
                            onClick={() => addUtente()}
                        >
                            Aggiungi
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function addUtente() {
        const id = nanoid(5)
        firebase.firestore().collection('teamvideo-utenti').doc(id).set({
            id: id,
            nome: nome,
            cognome: cognome,
        }).then(() => {
            setResult('Utente aggiunto con successo.')
            setNome('')
            setCognome('')
        })
    }
}
