import {Accordion, Button, Card, Container} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import firebase from "./firebase";
import {categories} from "./Attrezzatura";
import {Link} from "react-router-dom";
import CardAttrezzaturaManage from "./CardAttrezzaturaManage";
import {CardBookingAttrezzatura} from "./CardBookingAttrezzatura";
import {FaArchive} from "react-icons/all";


function AttrezzaturaManage() {

    const [elenco, setElenco] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('attrezzatura')
        actorsRef.onSnapshot(snapshot => {
            setElenco(snapshot.docs)
        })
    }, [])
    let attrezzatura = []
    if(elenco.length!==0) {
        elenco.map(a => {
            attrezzatura = [
                ...attrezzatura,
                a.data()
            ]
        })
    }
    attrezzatura = attrezzatura.sort((a,b) => (a.nome>b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))

    const [ritiri, setRitiri] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('ritiriAttrezzatura')
        actorsRef.onSnapshot(snapshot => {
            let rit = snapshot.docs.sort((a,b) => (a.data().date>b.data().date) ? 1 : ((b.data().date > a.data().date) ? -1 : 0))
            setRitiri(rit.reverse())
        })
    }, [])


    return (
        <div>
            <Container>
                <div className="text-center mb-5">
                    <h3 className="text-warning">Ritiri attrezzatura</h3>
                    <p className="small">Pannello di controllo ritiri attrezzatura.</p>
                </div>
                <div className="mb-5">
                    <h6 className="text-center text-warning pb-2">Elenco prenotazioni <span>
                        <Link to={{pathname:'/areariservata/attrezzatura/archivio'}}>
                            <FaArchive
                                size="1.2em"
                                className="ml-3 iconArchivioBookingAttrezzatura"
                            />
                        </Link>
                    </span></h6>
                    <Accordion className="text-dark">
                        {
                            ritiri ?
                                ritiri.filter(a => a.data().status!=='Deleted').filter(a => a.data().status!=='Completed').map(a => {
                                    return <CardBookingAttrezzatura a={a.data()} />
                                })
                                :
                                null
                        }
                    </Accordion>
                </div>
                <div className="mb-5">
                    <h6 className="text-center text-warning pb-2">Attrezzatura <Link
                        to={{pathname:"/areariservata/attrezzatura/new"}}
                    ><Button size="sm" variant="outline-warning" className="ml-3">Aggiungi</Button></Link></h6>
                    <Accordion className="text-dark">
                        {
                            elenco ?
                                categories.map(a => {
                                    let attrezz = attrezzatura.filter(b => b.categoria===a)

                                    return (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={a}>
                                                <p className="font-weight-bolder text-center text-uppercase">{a}</p>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={a}>
                                                <Card.Body>{
                                                    attrezz.map(c => {
                                                        return (
                                                            <CardAttrezzaturaManage c={c} />
                                                        )
                                                    })
                                                }</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )
                                })
                                :
                                null
                        }
                    </Accordion>
                </div>
            </Container>
        </div>
    )
}

export default AttrezzaturaManage