import {Badge, Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FaInfoCircle, FaPencilAlt} from "react-icons/fa";
import firebase from "./firebase";
import {categories} from "./Attrezzatura";
import React, {useState} from "react";



function CardAttrezzaturaManage({c}) {

    const [nome, setNome] = useState(c.nome)
    const [link, setLink] = useState(c.link)
    const [categoria, setCategoria] = useState(c.categoria)
    const [note, setNote] = useState(c.note ? c.note : '')

    const [showEdit, setShowEdit] = useState(false)



    return (
        <Row className="mb-3">
            <Col md="auto">
                <img src={c.link} height="50px"/>
            </Col>
            <Col md="auto" className="d-flex align-items-center">
                <div>
                    <p className="font-weight-bolder">{c.nome} <span>{
                        c.note ?
                            <OverlayTrigger key="right" placement="right" overlay={
                                <Tooltip id="tooltip-right">
                                    {c.note}
                                </Tooltip>
                            }>
                                <FaInfoCircle className="ml-2 mb-1" />
                            </OverlayTrigger>
                            :
                            null
                    }</span></p>
                    <p className="small text-black-50">ID: {c.id}</p>
                </div>
            </Col>
            <Col md="auto" className="d-flex align-items-center">
                <FaPencilAlt size="1.3em" onClick={() => setShowEdit(true)} />
            </Col>
            <Col md="auto" className="d-flex align-items-center">
                {
                    c.disponibile===true ?
                        <Badge variant="success">Disponibile</Badge>
                        :
                        <Badge variant="danger">Non disponibile</Badge>
                }
            </Col>
            <Col className="d-flex align-items-center justify-content-end">
                <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => {
                        firebase.firestore().collection('attrezzatura').doc(c.id).update({
                            disponibile: true
                        })
                    }}
                >
                    Disponibile
                </Button>
                <Button
                    variant="outline-danger"
                    size="sm"
                    className="mx-3"onClick={() => {
                    firebase.firestore().collection('attrezzatura').doc(c.id).update({
                        disponibile: false
                    })
                }}
                >
                    Non disponibile
                </Button>
            </Col>

            <Modal
                show={showEdit}
                onHide={() => setShowEdit(false)}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="text-dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Modifica attrezzatura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label className="small">Nome</Form.Label>
                        <Form.Control
                            type="text"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="small">Link foto</Form.Label>
                        <Form.Control
                            type="text"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="small">Categoria</Form.Label>
                        <Form.Control
                            as="select"
                            value={categoria}
                            onChange={e => setCategoria(e.target.value)}
                        >
                            <option value="" disabled>Scegli...</option>
                            {
                                categories.map(a => <option>{a}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="small">Note</Form.Label>
                        <Form.Control
                            type="text"
                            value={note}
                            onChange={e => setNote(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        if(window.confirm('Eliminare questa voce?')) {
                            firebase.firestore().collection('attrezzatura').doc(c.id).delete().then(() => setShowEdit(false))
                        }
                    }}>Elimina voce</Button>
                    <Button variant="warning" onClick={() => {
                        console.log(nome, link, categoria, note)

                        firebase.firestore().collection('attrezzatura').doc(c.id).update({
                            nome: nome,
                            categoria: categoria,
                            link: link,
                            note: note
                        }).then(() => setShowEdit(false))
                    }}>Aggiorna</Button>
                </Modal.Footer>
            </Modal>

        </Row>
    )
}

export default CardAttrezzaturaManage