import {Container, Form, Row, Col, Button, Modal, Spinner, Accordion, Card, Badge} from "react-bootstrap";
import CardAttrezzatura from "./CardAttrezzatura";
import React, {useContext, useEffect, useState} from "react";
import firebase from "./firebase";
import {StateContext} from "./App";
import {nanoid} from "nanoid";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "./logoAutodichiarazione.png";
import moment from "moment";
import {clearAttrezzatura} from "./Actions";


export const categories = [
    "Camere", "Ottiche", "Accessori camere, monitor e cavi video",
    "Movimentazione camere", "Grips", "Luci", "Teli e bandiere",
    "Elettrico", "Audio", "Produzione", "Consumabili", "Altro",
]

function Attrezzatura() {

    const [state, dispatch] = useContext(StateContext)

    const [elenco, setElenco] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('attrezzatura')
        actorsRef.onSnapshot(snapshot => {
            setElenco(snapshot.docs)
        })
    }, [])
    let attrezzatura = []
    if(elenco.length!==0) {
        elenco.map(a => {
            attrezzatura = [
                ...attrezzatura,
                a.data()
            ]
        })
    }
    attrezzatura = attrezzatura.sort((a,b) => (a.nome>b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))



    const [ritiri, setRitiri] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('ritiriAttrezzatura')
        actorsRef.onSnapshot(snapshot => {
            setRitiri(snapshot.docs)
        })
    }, [])

    const [giorno, setGiorno] = useState('')
    const [orainizio, setOrainizio] = useState('')
    const [orafine, setOrafine] = useState('')
    const [referente, setReferente] = useState('')


    let filtered = ritiri
    let nondisp = []
    if(giorno!=='' && orainizio!=='' && orafine!=='') {
        // moment(a.data().date + ' ' + a.data().inizio, 'YYYY-MM-DD H:mm')

        filtered = filtered.filter(a => {
            let i = moment(a.data().date + ' ' + a.data().inizio, 'YYYY-MM-DD H:mm')
            let f = moment(a.data().date + ' ' + a.data().fine, 'YYYY-MM-DD H:mm')
            let ii = moment(giorno + ' ' + orainizio, 'YYYY-MM-DD H:mm')
            let ff = moment(giorno + ' ' + orafine, 'YYYY-MM-DD H:mm')

            if(ii>=i && ii<f) return true
            else if(ff>i && ff<=f) return true
            else if(ii<=i && ff>=f) return true
            else return false
        })

        filtered.map(a => {
            nondisp = nondisp.concat(a.data().elenco)
        })

        attrezzatura.map(a => {
            if(!a.disponibile) nondisp = nondisp.concat(a.id)
        })
    }else {
        attrezzatura.map(a => {
            nondisp = nondisp.concat(a.id)
        })
    }

    const [showcarrello, setShowcarrello] = useState(false)
    const [result, setResult] = useState('')

    let elencoAtt = []
    let elencoPdf = []
    let totalPages = 0
    if(attrezzatura.length!==0) {
        state.attrezzatura.map(x => elencoAtt.push(attrezzatura.filter(y => y.id===x)[0]))
        totalPages = Math.ceil(elencoAtt.length/30)
        for(let i=0; i<Math.ceil(elencoAtt.length/30); i++) {
            elencoPdf = [
                ...elencoPdf,
                elencoAtt.slice(i*30, ((i+1)*30)+1)
            ]
        }
    }

    // PDF
    const [pdf, setPdf] = useState(false)
    const [idPrenotazione, setIdPrenotazione] = useState('')
    const styles = StyleSheet.create({
        page: {
            color: 'black',
            backgroundColor: '#ffffff'
        },
        intestazione: {
            paddingTop: 45,
            paddingLeft: 55,
            fontSize: 11,
        },
        section1: {
            fontSize: 12,
            paddingTop: 33,
            paddingLeft: 55,
            paddingRight: 55,
        },
        section2: {
            fontSize: 10,
            paddingTop: 10,
            paddingLeft: 70,
            paddingRight: 55,
        },
        section3: {
            minHeight: 20,
            border: '1px solid black',
            padding: 0,
            margin: -1,
        },
        section4: {
            maxWidth: 200,
            minHeight: '100%',
            borderRight: '1px solid black',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 5,
            paddingRight: 5,
        },
        section5: {
            width: 40,
            borderRight: '1px solid black',
        }
    })

    let countPage = 0
    const DocumentoPdf = () => (
        <Document>
            {
                elencoPdf.length!==0 ?
                    elencoPdf.map(x => {
                            countPage++
                            return (
                                <Page size="A4" style={styles.page}>
                                    <View style={styles.intestazione}>
                                        <Image src={logo} style={{width: '100px', marginBottom:1}} />
                                    </View>
                                    <View style={styles.section1}>
                                        <Text>PRENOTAZIONE ATTREZZATURA</Text>
                                    </View>
                                    <View style={styles.section2}>
                                        <Text>ID PRENOTAZIONE: <Text style={{fontFamily: "Helvetica-Bold"}}>{idPrenotazione}</Text></Text>
                                        <Text>DATA: <Text style={{fontFamily: "Helvetica-Bold"}}>{giorno}</Text></Text>
                                        <Text>ORA RITIRO: <Text style={{fontFamily: "Helvetica-Bold"}}>{orainizio}</Text></Text>
                                        <Text>ORA CONSEGNA: <Text style={{fontFamily: "Helvetica-Bold"}}>{orafine}</Text></Text>
                                        <Text>REFERENTE: <Text style={{fontFamily: "Helvetica-Bold"}}>{referente}</Text></Text>
                                    </View>
                                    <View style={styles.section1}>
                                        <Text>ELENCO ATTREZZATURA - <Text style={{fontFamily: "Helvetica-Bold"}}>PAGINA {countPage}/{totalPages}</Text></Text>
                                    </View>
                                    <View style={styles.section2}>
                                        {
                                            x.length!==0 ?
                                                x.map(z =>
                                                    <View style={styles.section3}>
                                                        <View style={styles.section4}>
                                                            <Text>{z.nome}</Text>
                                                        </View>
                                                    </View>
                                                )
                                                :
                                                null
                                        }
                                    </View>
                                </Page>
                            )
                        }
                    )
                    :
                    null
            }
        </Document>
    )

    return (
        <div>
            <Container>
                <div className="text-center mb-4">
                    <h2 className="text-warning">Prenota attrezzatura</h2>
                    <p>Elenco e modulo per ritiro attrezzatura.</p>
                </div>
                <div className="mb-5">
                    <Row className="mb-3">
                        <Col>
                            <Form.Label className="small">Giorno (gg/mm/aaaa)</Form.Label>
                            <Form.Control
                                type="date"
                                disabled={state.attrezzatura.length>0}
                                value={giorno}
                                onChange={e => setGiorno(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label className="small">Ora ritiro (hh:mm)</Form.Label>
                            <Form.Control
                                type="time"
                                disabled={state.attrezzatura.length>0}
                                value={orainizio}
                                onChange={e => setOrainizio(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label className="small">Ora consegna (hh:mm)</Form.Label>
                            <Form.Control
                                type="time"
                                disabled={state.attrezzatura.length>0}
                                value={orafine}
                                onChange={e => setOrafine(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <div>
                        <Row>
                            <Col>
                                <Form.Label className="small">Referente</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome referente"
                                    value={referente}
                                    onChange={e => setReferente(e.target.value)}
                                />
                            </Col>
                            <Col className="text-right d-flex align-items-end justify-content-end">
                                <Button
                                    variant="warning"
                                    className="mx-2 font-weight-bolder"
                                    disabled={!(giorno !== '' && orainizio !== '' && orafine !== '' && referente !== '' && state.attrezzatura.length!==0)}
                                    onClick={() => setShowcarrello(true)}
                                >
                                    Carrello {state.attrezzatura.length>0 ? <Badge pill variant="danger">{state.attrezzatura.length}</Badge> : null}
                                </Button>
                                <Button
                                    variant="danger"
                                    disabled={!(giorno !== '' && orainizio !== '' && orafine !== '' && referente !== '' && state.attrezzatura.length!==0)}
                                    onClick={() => dispatch(clearAttrezzatura())}
                                >
                                    Svuota carrello
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className="mb-5">
                    <Accordion className="text-dark">
                        {
                            attrezzatura ?
                                categories.map(a => {
                                    let attrezz = attrezzatura.filter(b => b.categoria===a)

                                    return (
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey={a}>
                                                <p className="font-weight-bolder text-center text-uppercase">{a}</p>
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey={a}>
                                                <Card.Body>{
                                                    attrezz.map(c => {
                                                        if(c.categoria===a)
                                                            if(nondisp) {
                                                                if(nondisp.includes(c.id)) {
                                                                    return <CardAttrezzatura props={c} booked={true} />
                                                                } else {
                                                                    return <CardAttrezzatura props={c} booked={false} />
                                                                }
                                                            } else {
                                                                return <CardAttrezzatura props={c} booked={true} />
                                                            }
                                                        return (

                                                            <CardAttrezzatura props={c} />
                                                        )
                                                    })
                                                }</Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    )
                                })
                                :
                                null
                        }
                    </Accordion>



                    {
                        /**
                        categories.map(t => {
                            return (
                                <div className="mb-5">
                                    <div className="text-center mb-4">
                                        <h4 className="text-warning text-uppercase font-weight-bolder">{t}</h4>
                                    </div>
                                    <div>
                                        {
                                            elenco.map(a => {
                                                if(a.data().categoria===t)
                                                    if(nondisp) {
                                                        if(nondisp.includes(a.data().id)) {
                                                            return <CardAttrezzatura props={a.data()} booked={true} />
                                                        } else {
                                                            return <CardAttrezzatura props={a.data()} booked={false} />
                                                        }
                                                    } else {
                                                        return <CardAttrezzatura props={a.data()} booked={true} />
                                                    }
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                         **/
                    }
                </div>
            </Container>


            <Modal size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered className="text-dark" show={showcarrello} onHide={() => setShowcarrello(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Riepilogo prenotazione</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Giorno: <span className="font-weight-bolder">{giorno}</span></p>
                    <p>Ora ritiro: <span className="font-weight-bolder">{orainizio}</span></p>
                    <p>Ora consegna: <span className="font-weight-bolder">{orafine}</span></p>
                    <p>Referente: <span className="font-weight-bolder">{referente}</span></p>
                    <hr />
                    <ul>
                        {
                            state.attrezzatura.map(a => {
                                return <li className="font-weight-bolder">{attrezzatura.filter(b => b.id === a)[0].nome} <span className="text-black-50 small ml-2">{attrezzatura.filter(b => b.id === a)[0].id}</span></li>
                            })
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    {
                        result ?
                            <p className="font-weight-bolder">{result}</p>
                            :
                            null
                    }
                    {
                        pdf ?
                            <PDFDownloadLink document={<DocumentoPdf />} fileName={'autodichiarazione' + '.pdf'}>
                                {({ blob, url, loading, error }) => (url ?
                                        <a href={url} target="_blank" rel="noreferrer">
                                            <Button
                                                variant="warning"
                                                className="rounded-lg"
                                            >
                                                Genera PDF
                                            </Button>
                                        </a>
                                        :
                                        <Spinner animation="border" variant="warning" />
                                )}
                            </PDFDownloadLink>
                            :
                            null
                    }
                    <Button variant="secondary" onClick={() => setShowcarrello(false)}>
                        Chiudi
                    </Button>
                    <Button variant="warning"
                            onClick={() => {
                                let idRitiro = nanoid(20)
                                setIdPrenotazione(idRitiro)
                                firebase.firestore().collection('ritiriAttrezzatura').doc(idRitiro).set({
                                    id: idRitiro,
                                    date: giorno,
                                    inizio: orainizio,
                                    fine: orafine,
                                    referente: referente,
                                    elenco: state.attrezzatura,
                                    status: 'Booked'
                                }).then(() => {
                                    setResult('Attrezzatura prenotata con successo')
                                    setPdf(true)
                                })
                            }}
                    >
                        Prenota
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default Attrezzatura
