import {Accordion, Button, Card, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {useHistory} from "react-router";
import React, {useEffect, useState} from "react";
import firebase from "../firebase";
import {FaChevronDown} from "react-icons/all";
import moment from "moment";

const giorniSettimana = ['lunedì', 'martedì', 'mercoledì', 'giovedì', 'venerdì', 'sabato', 'domenica']
const tipiAttività = ['R1', 'R2', 'R3', 'S1', 'S2', 'M1', 'M2', 'G1', 'G2', 'A1', 'A2', '3D']
const initalReportDay = {
    quantita: 0,
    note: '',
    ore: 0,
}

export function ReportUtente() {

    let hist = useHistory().location.pathname.split('/')
    const idUtente = hist[hist.length-1]

    const [utente, setUtente] = useState([])
    useEffect(() => {
        const ref = firebase.firestore().collection('teamvideo-utenti').doc(idUtente)
        ref.onSnapshot(snapshot => {
            setUtente(snapshot.data())
        })
    }, [])

    const [reports, setReports] = useState([])
    useEffect(() => {
        const ref = firebase.firestore().collection('teamvideo-reports')
        ref.onSnapshot(snapshot => {
            setReports(snapshot.docs)
        })
    }, [])

    // MODAL
    const [showModal, setShowModal] = useState(false)

    const [settimana, setSettimana] = useState('')
    const [report, setReport] = useState(giorniSettimana.map(g => tipiAttività.map(t => initalReportDay)))
    console.log('REPORT', report)
    let firstday
    let countday = -1
    let countattivita = -1
    if(settimana!=='') {
        firstday = moment(settimana)
    }


    return (
        <div>
            <Container>
                <div className="mb-5">
                    <div className="text-center mb-3">
                        <h2 className="text-warning">Report</h2>
                        <p>Report settimanale <span className="text-uppercase font-weight-bold">{utente ? utente.nome + ' ' + utente.cognome : null}</span></p>
                    </div>
                    <div className="text-center">
                        <Button
                            variant="outline-warning"
                            size="sm"
                            onClick={() => setShowModal(true)}
                        >
                            Nuovo Report
                        </Button>
                    </div>
                </div>
            </Container>

            <Modal show={showModal} onHide={() => setShowModal(false)} className="text-dark" size="xl">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100">
                        <Row>
                            <Col>
                                Nuovo Report
                            </Col>
                            <Col>
                                <Row>
                                    <Col md="auto" className="small text-uppercase font-weight-bold d-flex justify-content-end align-items-center">
                                        <p>Settimana</p>
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="week"
                                            value={settimana}
                                            onChange={e => setSettimana(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {
                            settimana==='' ?
                                <p className="py-5 font-weight-bold text-center">Scegli una settimana.</p>
                                :
                                <Accordion className="text-dark">
                                    {
                                        giorniSettimana.map(g => {
                                            countday = countday + 1
                                            countattivita = -1
                                            return (
                                                <Card>
                                                    <Accordion.Toggle as={Card.Header} eventKey={g}>
                                                        <Row>
                                                            <Col>
                                                                <h6 className="mt-2">{g.toUpperCase()} {moment(firstday).add(countday, 'd').format('DD/MM/YYYY')}</h6>
                                                            </Col>
                                                            <Col md="auto">
                                                                <FaChevronDown />
                                                            </Col>
                                                        </Row>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse eventKey={g}>
                                                        <Card.Body>
                                                            <Row className="mb-1 text-center text-uppercase">
                                                                <Col md={1} className="d-flex justify-content-center align-items-end">
                                                                    <h6 className="small d-none">Tipo</h6>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <h6 className="small">Quantità</h6>
                                                                </Col>
                                                                <Col>
                                                                    <h6 className="small">Note Lavoro</h6>
                                                                </Col>
                                                                <Col md={2}>
                                                                    <h6 className="small">h. Consultivo</h6>
                                                                </Col>
                                                            </Row>
                                                            {
                                                                tipiAttività.map(t => {
                                                                    countattivita++
                                                                    return (
                                                                        <Row className="mb-2">
                                                                            <Col md={1} className="d-flex justify-content-center align-items-end">
                                                                                <h6 className="font-weight-bold">{t}</h6>
                                                                            </Col>
                                                                            <Col md={2}>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    size="sm"
                                                                                    value={report[countday][countattivita].quantita}
                                                                                />
                                                                            </Col>
                                                                            <Col>
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    size="sm"
                                                                                    value={report[countday][countattivita].note}
                                                                                />
                                                                            </Col>
                                                                            <Col md={2}>
                                                                                <Form.Control
                                                                                    type="number"
                                                                                    size="sm"
                                                                                    value={report[countday][countattivita].ore}
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                })
                                                            }
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            )
                                        })
                                    }
                                </Accordion>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Annulla
                    </Button>
                    <Button variant="warning" onClick={() => setShowModal(false)}>
                        Salva
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}
