import {Badge, Button, Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {useState} from "react";
import firebase from "./firebase";
import profilePic from './profilePic.jpg'


function PersonCard({props}) {

    // IMMAGINE
    const img0Ref = firebase.storage().ref('images/actors/' + props.id).child('0')
    const [image0, setImage0] = useState(profilePic)
    img0Ref.getDownloadURL().then((url) => {
        setImage0(url)
    }).catch((err) => {
        setImage0(profilePic)
    })

    // CALCOLO ETA'
    const anno = moment().format('YYYY')
    const mese = moment().format('MM')
    const giorno = moment().format('DD')
    const [a, m, g] = props.dataNascita.split('-')
    let eta = anno - a - 1
    if(mese > m) eta++
    else if(mese===m && giorno >= g) eta++


    return(
        <div>
            <Container className="cardPersona mb-4">
                <Row>
                    <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pl-0 pr-0">
                        <Container fluid className="titleCardPersona pt-3 pb-2">
                            <Container>
                                <h3>{props.nome} {props.cognome} - {eta}</h3>
                            </Container>
                        </Container>
                        <Container className="pl-4 pt-2">
                            <Row>
                                <Col md="auto">
                                    <p>Nome: <b>{props.nome}</b></p>
                                </Col>
                                <Col md="auto">
                                    <p>Cognome: <b>{props.cognome}</b></p>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col md="auto">
                                    <p>Genere: <b style={{textTransform:'uppercase'}}>{props.sesso}</b></p>
                                </Col>
                                <Col md="auto">
                                    <p>Domicilio: <b>{props.luogoDomicilio}</b></p>
                                </Col>
                                <Col md="auto">
                                    <p>Età: <b>{eta}</b></p>
                                </Col>
                            </Row>
                            <Container className="mb-3">
                                <Row>
                                    <Col xs="6" sm="6" md="7" lg="7" xl="7" className="p-0">
                                        {
                                            props.comparsa ? <Badge variant="warning" className="mr-2">Comparsa</Badge> : null
                                        }
                                        {
                                            props.figurante ?
                                                <Badge variant="primary" className="mr-2">Figurante</Badge> : null
                                        }
                                        {
                                            props.attore ? <Badge variant="danger" className="mr-2">Attore</Badge> : null
                                        }
                                    </Col>
                                    <Col>
                                        <span className="mr-1" style={{fontSize:'smaller'}}>Livello: </span>
                                        {
                                            props.livelloRecitazione==='Basso' ? <Badge variant="dark" className="mr-2">Basso</Badge> :
                                                props.livelloRecitazione==='Medio' ? <Badge variant="warning" className="mr-2">Medio</Badge> :
                                                    props.livelloRecitazione==='Alto' ? <Badge variant="success" className="mr-2">Alto</Badge> :
                                                        null
                                        }
                                    </Col>
                                </Row>
                            </Container>
                            <Row>
                                <Col>
                                    <span className="mr-1" style={{fontSize:'smaller'}}>Retribuzione: </span>
                                    {
                                        props.retribuzione==='No' ? <Badge variant="success" className="mr-2">No</Badge> :
                                            props.retribuzione==='Si' ? <Badge variant="danger" className="mr-2">Si</Badge> :
                                                props.retribuzione==='Entrambe' ? <Badge variant="warning" className="mr-2">Si/No</Badge> :
                                                    null
                                    }
                                </Col>
                                <Col className="d-flex justify-content-end">
                                    <Link
                                        to={{
                                            pathname:`/areariservata/attori/${props.id}`
                                        }}
                                    >
                                        <Button
                                            size="sm"
                                            variant="dark"
                                            className="rounded-lg mb-3"
                                        >
                                            Altre info
                                        </Button>
                                    </Link>
                                </Col>
                            </Row>


                        </Container>
                    </Col>
                    <Col xs="0" sm="0" md="0" lg="4" xl="4" className="pr-0 pl-0 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${image0})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                            className="picCardPersona"
                        >

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default PersonCard
