import {Alert, Badge, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import React, {useState} from "react";
import { FaPencilAlt, FaTrash } from "react-icons/fa";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "./logoAutodichiarazione.png";


function Verbale() {

    const [riunione, setRiunione] = useState('')
    const [data, setData] = useState('')
    const [partecipante, setPartecipante] = useState('')
    const [punto, setPunto] = useState('')
    const [puntoVecchio, setPuntoVecchio] = useState('')
    const [compito, setCompito] = useState('')
    const [personaCompito, setPersonaCompito] = useState('')

    const [partecipanti, setPartecipanti] = useState([])
    const [punti, setPunti] = useState([])
    const [puntiVecchi, setPuntiVecchi] = useState([])
    const [compiti, setCompiti] = useState([])


    // PDF
    const styles = StyleSheet.create({
        page: {
            color: 'black',
            backgroundColor: '#ffffff'
        },
        intestazione: {
            paddingTop: 45,
            paddingLeft: 55,
            fontSize: 11,
        },
        section1: {
            fontSize: 12,
            paddingTop: 33,
            paddingLeft: 55,
            paddingRight: 55,
        },
        section2: {
            fontSize: 12,
            paddingTop: 5,
            paddingLeft: 70,
            paddingRight: 55,
        },
        section3: {
            fontSize: 12,
            paddingTop: 5,
            paddingLeft: 20,
            paddingRight: 55,
            paddingBottom: 10,
        },
    })

    const DocumentoPdf = () => (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.intestazione}>
                    <Image src={logo} style={{width: '120px', marginBottom:2}} />
                </View>
                <View style={styles.section1}>
                    <Text>VERBALE DI RIUNIONE: {riunione.toUpperCase()}</Text>
                    <Text>DATA: {data}</Text>
                    <Text>PRESENTI: {partecipanti.map(a => <Text>{a} </Text>)}</Text>
                </View>
                <View style={styles.section1}>
                    <Text>PUNTI DISCUSSI:</Text>
                </View>
                <View style={styles.section2}>
                    {
                        punti.length!==0 ?
                            punti.map(a => <Text>- {a}</Text>)
                            :
                            <Text>- N.A.</Text>
                    }
                </View>
                {
                    puntiVecchi.length!==0 ?
                        <View>
                            <View style={styles.section1}>
                                <Text>PUNTI RIUNIONI PRECEDENTI:</Text>
                            </View>
                            <View style={styles.section2}>
                                {
                                    puntiVecchi.map(a => <Text>- {a}</Text>)
                                }
                            </View>
                        </View>
                        :
                        null
                }
                <View style={styles.section1}>
                    <Text>COMPITI:</Text>
                </View>
                <View style={styles.section2}>

                    {
                        partecipanti.map(p => {
                            if(compiti.filter(a => a.persona===p).length!==0) {
                                return (
                                    <>
                                        <Text>{p.toUpperCase()}:</Text>
                                        <View style={styles.section3}>
                                            {
                                                compiti.filter(a => a.persona===p).map(a => <Text>- {a.compito}</Text>)
                                            }
                                        </View>
                                    </>
                                )
                            } else return null
                        })
                    }
                </View>
            </Page>
        </Document>
    )


    return (
        <div>
            <Container>
                <Container className="text-center mb-5">
                    <h3 className="text-warning">Generatore Verbale di Riunione <span style={{fontSize: 20}} className="ml-3"><Badge variant="danger">NEW</Badge></span></h3>
                    <p style={{fontSize:'smaller'}}>Tutti i campi sono obbligatori. <span style={{textDecoration: "underline"}}>COMPILARE CON GOOGLE CHROME.</span></p>
                </Container>
                <Container className="p-0">
                    <Form.Group>
                        <Form.Label className="text-warning font-weight-bold">Riunione</Form.Label>
                        <Form.Control
                            type="text"
                            value={riunione}
                            onChange={e => setRiunione(e.target.value)}
                        />
                    </Form.Group>
                </Container>
                <Row>
                    <Col md="4">
                        <Form.Group>
                            <Form.Label className="text-warning font-weight-bold">Data riunione</Form.Label>
                            <Form.Control
                                type="text"
                                value={data}
                                onChange={e => setData(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-warning font-weight-bold">Presenti</Form.Label>
                            <Row>
                                <Col className="pr-1">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nuovo partecipante"
                                        value={partecipante}
                                        onChange={e => setPartecipante(e.target.value)}
                                    />
                                </Col>
                                <Col md="auto" className="pl-1">
                                    <Button
                                        variant="warning"
                                        disabled={partecipante==='' ? true : false}
                                        onClick={() => {
                                            setPartecipanti(partecipanti.concat([partecipante]))
                                            setPartecipante('')
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            <div>
                                <ul className="mt-3">
                                    {
                                        partecipanti.map(a => {
                                            return (
                                                <li>
                                                    {a}
                                                    <FaTrash
                                                        color="white"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            if(window.confirm('Vuoi eliminare questa persona? Verranno eliminate anche i compiti relativi a questa persona.')) {
                                                                setPartecipanti(partecipanti.filter(b => b!==a))
                                                                setCompiti(compiti.filter(b => b.persona!==a))
                                                            }
                                                        }}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-warning font-weight-bold">Punti discussi</Form.Label>
                            <Row>
                                <Col className="pr-1">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nuovo punto"
                                        value={punto}
                                        onChange={e => setPunto(e.target.value)}
                                    />
                                </Col>
                                <Col md="auto" className="pl-1">
                                    <Button
                                        variant="warning"
                                        disabled={punto==='' ? true : false}
                                        onClick={() => {
                                            setPunti(punti.concat([punto]))
                                            setPunto('')
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            <div>
                                <ul className="mt-3">
                                    {
                                        punti.map(a => {
                                            return (
                                                <li>
                                                    {a}
                                                    <FaPencilAlt
                                                        color="white"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            var edit = prompt('Modifica voce', '')
                                                            if(edit!==null || edit!=='') {
                                                                setPunti(punti.filter(b => b!==a).concat([edit]))
                                                            }
                                                        }}
                                                    />
                                                    <FaTrash
                                                        color="white"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            if(window.confirm('Vuoi eliminare questa voce?')) {
                                                                setPunti(punti.filter(b => b!==a))
                                                            }
                                                        }}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label className="text-warning font-weight-bolder">Punti riunioni precedenti</Form.Label>
                            <Row>
                                <Col className="pr-1">
                                    <Form.Control
                                        type="text"
                                        placeholder="Nuovo punto"
                                        value={puntoVecchio}
                                        onChange={e => setPuntoVecchio(e.target.value)}
                                    />
                                </Col>
                                <Col md="auto" className="pl-1">
                                    <Button
                                        variant="warning"
                                        disabled={puntoVecchio==='' ? true : false}
                                        onClick={() => {
                                            setPuntiVecchi(puntiVecchi.concat([puntoVecchio]))
                                            setPuntoVecchio('')
                                        }}
                                    >
                                        Add
                                    </Button>
                                </Col>
                            </Row>
                            <div>
                                <ul className="mt-3">
                                    {
                                        puntiVecchi.map(a => {
                                            return (
                                                <li>
                                                    {a}
                                                    <FaPencilAlt
                                                        color="white"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            var edit = prompt('Modifica voce', '')
                                                            if(edit!==null || edit!=='') {
                                                                setPuntiVecchi(puntiVecchi.filter(b => b!==a).concat([edit]))
                                                            }
                                                        }}
                                                    />
                                                    <FaTrash
                                                        color="white"
                                                        className="ml-2"
                                                        onClick={() => {
                                                            if(window.confirm('Vuoi eliminare questa voce?')) {
                                                                setPuntiVecchi(puntiVecchi.filter(b => b!==a))
                                                            }
                                                        }}
                                                    />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group>
                    <Form.Label className="text-warning font-weight-bolder">Compiti</Form.Label>
                    <Row>
                        <Col className="pr-1">
                            <Form.Control
                                type="text"
                                placeholder="Nuovo compito"
                                value={compito}
                                onChange={e => setCompito(e.target.value)}
                            />
                        </Col>
                        <Col md="4" className="px-1">
                            <Form.Control
                                as="select"
                                value={personaCompito}
                                onChange={e => setPersonaCompito(e.target.value)}
                            >
                                <option value="" disabled>Scegli...</option>
                                {
                                    partecipanti.map(a => <option value={a}>{a}</option>)
                                }
                            </Form.Control>
                        </Col>
                        <Col md="auto" className="pl-1">
                            <Button
                                variant="warning"
                                disabled={compito==='' || personaCompito==='' ? true : false}
                                onClick={() => {
                                    setCompiti(compiti.concat([{persona:personaCompito, compito:compito}]))
                                    setCompito('')
                                    setPersonaCompito('')
                                }}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>
                    <div className="text-center d-flex justify-content-center align-items-center mt-3">
                        <ul>
                            {
                                compiti.map(a => <li>
                                    <Row>
                                        <Col md="auto" style={{textDecoration: 'underline'}}>{a.persona}</Col>
                                        <Col md="auto">{a.compito}</Col>
                                        <Col md="auto">
                                            <FaPencilAlt
                                                color="white"
                                                className="ml-2"
                                                onClick={() => {
                                                    var edit = prompt('Modifica voce', '')
                                                    if(edit!==null || edit!=='') {
                                                        setCompiti(compiti.filter(b => b!==a).concat([{persona:a.persona, compito:edit}]))
                                                    }
                                                }}
                                            />
                                            <FaTrash
                                                color="white"
                                                className="ml-2"
                                                onClick={() => {
                                                    if(window.confirm('Vuoi eliminare questa voce?')) {
                                                        setCompiti(compiti.filter(b => b!==a))
                                                    }
                                                }}
                                            />
                                        </Col>
                                    </Row>

                                </li>)
                            }
                        </ul>
                    </div>
                    <div className="text-center mt-3">
                        {
                            data!=='' && punti.length!==0 && compiti.length!==0 ?
                                <PDFDownloadLink document={<DocumentoPdf />} fileName={'autodichiarazione' + '.pdf'}>
                                    {({ blob, url, loading, error }) => (url ?
                                            <a href={url} target="_blank" rel="noreferrer">
                                                <Button
                                                    variant="warning"
                                                    className="rounded-lg"
                                                >
                                                    Genera PDF
                                                </Button>
                                            </a>
                                            :
                                            <Spinner animation="border" variant="warning" />
                                    )}
                                </PDFDownloadLink>
                                :
                                <Alert variant="warning">Compila tutti i campi.</Alert>
                        }
                    </div>
                </Form.Group>
            </Container>
        </div>
    )
}

export default Verbale