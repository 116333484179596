import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import moment from "moment";
import {FaTrashAlt} from "react-icons/fa";


function EditCostume() {

    const [nome, setNome] = useState()
    const [eta, setEta] = useState()
    const [note, setNote] = useState()
    const [sesso, setSesso] = useState()
    const [ubicazione, setUbicazione] = useState()

    const queryId = useLocation().pathname.split('/')[4]
    const [info, setInfo] = useState()

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('costumi').doc(queryId)
        actorsRef.onSnapshot(snapshot => {
            setInfo(snapshot.data())
        })
    }, [])

    useEffect(() => {
        if(info) {
            setNome(info.nome)
            setEta(info.eta)
            setNote(info.note)
            setSesso(info.sesso)
            setUbicazione(info.ubicazione)
        }
    }, [info])

    const [error, setError] = useState('')
    const [result, setResult] = useState('')


    return (
        <div>
            {
                info ?
                    <Container>
                        <Container
                            className="text-center mb-5"
                        >
                            <h2 className="text-warning">
                                Modifica attore
                            </h2>
                            <p style={{textAlign:'center', fontSize:'smaller'}}>
                                Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori
                            </p>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Nome <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Età <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={eta}
                                        onChange={e => setEta(e.target.value)}
                                    >
                                        <option value="Adulto">Adulto</option>
                                        <option value="Bambino">Bambino</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Text>Sesso <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={sesso}
                                        onChange={e => setSesso(e.target.value)}
                                    >
                                        <option value="Uomo">Uomo</option>
                                        <option value="Donna">Donna</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Ubicazione <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={ubicazione}
                                        onChange={e => setUbicazione(e.target.value)}
                                    >
                                        <option disabled value="">Scegli...</option>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="Baule">Baule</option>
                                        <option value="Speciale">Speciale</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Text>Note</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            {
                                error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                            }

                            {
                                result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                            }
                        </Container>
                        <Container
                            className="mt-2 text-right"
                        >
                            <Row>
                                <Col className="text-left">
                                    <FaTrashAlt
                                        size="1.4em"
                                        className="m-3"
                                        color="red"
                                        onClick={() => {
                                            if(window.confirm('Vuoi ELIMINARE questo costume?')) {
                                                firebase.storage().ref('images/costumi').child(queryId).delete()
                                                firebase.firestore().collection('costumi').doc(queryId).delete()
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        className="mt-3 text-right"
                                        variant="warning"
                                        onClick={
                                            () => {

                                                if(nome==='') setError('Nome non valido.')
                                                else if(eta==='') setError('Età non valida.')
                                                else if(sesso==='') setError('Sesso non valido.')
                                                else if(ubicazione==='') setError('Ubicazione non valida.')
                                                else {
                                                    let id = info.id

                                                    firebase.firestore().collection('costumi').doc(id).set({
                                                        id: id,
                                                        nome: nome,
                                                        eta: eta,
                                                        ubicazione: ubicazione,
                                                        sesso: sesso,
                                                        note: note,
                                                    })
                                                    setError('')
                                                    setResult('Dati aggiornati con successo!')
                                                }
                                            }
                                        }
                                    >
                                        Salva modifiche
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    :
                    null
            }
        </div>
    )

}

export default EditCostume