
export const SET_NOME = "setNome"
export const SET_MIN_ETA = "setMinEta"
export const SET_MAX_ETA = "setMaxEta"
export const SET_UOMO = "setUomo"
export const SET_DONNA = "setDonna"
export const SET_COMPARSA = "setComparsa"
export const SET_FIGURANTE = "setFigurante"
export const SET_ATTORE = "setAttore"
export const SET_BASSO = "setBasso"
export const SET_MEDIO = "setMedio"
export const SET_ALTO = "setAlto"
export const SET_RETRIBUITO = "setRetribuito"
export const SET_NON_RETRIBUITO = "setNonRetribuito"
export const SET_COLLAB = "setCollab"
export const SET_RESIDENTI = "setResidenti"
export const CLEAR_FILTERS = "clearFilters"
export const ORDER_BY_ETA = "orderByEta"
export const ORDER_BY_NOME = "orderByNome"

export const LOG_IN = "logIn"
export const LOG_IN_ATTREZZATURA = "logInAttrezzatura"
export const LOG_IN_ADMIN = "logInAdmin"

export const ADD_ATTREZZATURA = "addAttrezzatura"
export const DEL_ATTREZZATURA = "delAttrezzatura"
export const CLEAR_ATTREZZATURA = "clearAttrezzatura"


export function setNome(nome) {
    return { type: SET_NOME, nome }
}
export function setMinEta(minEta) {
    return { type: SET_MIN_ETA, minEta }
}
export function setMaxEta(maxEta) {
    return { type: SET_MAX_ETA, maxEta }
}
export function setUomo(uomo) {
    return { type: SET_UOMO, uomo }
}
export function setDonna(donna) {
    return { type: SET_DONNA, donna }
}
export function setComparsa(comparsa) {
    return { type: SET_COMPARSA, comparsa }
}
export function setFigurante(figurante) {
    return { type: SET_FIGURANTE, figurante }
}
export function setAttore(attore) {
    return { type: SET_ATTORE, attore }
}
export function setBasso(basso) {
    return { type: SET_BASSO, basso }
}
export function setMedio(medio) {
    return { type: SET_MEDIO, medio }
}
export function setAlto(alto) {
    return { type: SET_ALTO, alto }
}
export function setRetribuito(retribuito) {
    return { type: SET_RETRIBUITO, retribuito }
}
export function setNonRetribuito(nonRetribuito) {
    return { type: SET_NON_RETRIBUITO, nonRetribuito }
}
export function setCollab(collab) {
    return { type: SET_COLLAB, collab }
}
export function setResidenti(residenti) {
    return { type: SET_RESIDENTI, residenti }
}
export function clearFilters() {
    return { type: CLEAR_FILTERS }
}
export function orderByEta() {
    return { type: ORDER_BY_ETA }
}
export function orderByNome() {
    return { type: ORDER_BY_NOME }
}

export function setLogIn() {
    return { type: LOG_IN }
}
export function setLogInAttrezzatura() {
    return { type: LOG_IN_ATTREZZATURA }
}
export function setLogInAdmin() {
    return { type: LOG_IN_ADMIN }
}

export function addAttrezzatura(id) {
    return { type: ADD_ATTREZZATURA, id }
}
export function delAttrezzatura(id) {
    return { type: DEL_ATTREZZATURA, id }
}
export function clearAttrezzatura(id) {
    return { type: CLEAR_ATTREZZATURA, id }
}