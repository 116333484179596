import {Button, Col, Container, Form, Pagination, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import CardProp from "./CardProp";
import CardCostume from "./CardCostume";


function Magazzino() {

    const [nome, setNome] = useState('')
    const [ubicazione, setUbicazione] = useState('Tutti')


    const [elencoProps, setElencoProps] = useState([])

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('magazzino')
        actorsRef.onSnapshot(snapshot => {
            setElencoProps(snapshot.docs)
        })
    }, [])

    let props = []
    if(elencoProps) props = Object.keys(elencoProps).map(a => elencoProps[a].data())
    let filtered = props.filter(a => (a.nome).toLowerCase().includes(nome) || (a.descrizione).toLowerCase().includes(nome))
    if(ubicazione!=='Tutti') filtered = filtered.filter(a => a.ubicazione===ubicazione)

    // PAGINATION
    let disp = 20
    let pagine = Math.ceil(filtered.length / disp)
    const [pagActive, setPagActive] = useState(1)

    return (
        <div>
            <Container className="mb-5">
                <Row>
                    <Col md="3" className="p-0 mx-3 mx-sm-3 mx-md-3 mx-lg-0 mx-xl-0
                    mb-5 mb-sm-5 mb-md-0 mb-lg-0 mb-xl-0">
                        <Container className="filtriContainer pt-4 pb-3 pl-4 pr-4">
                            <h3 className="mb-2 text-warning">Filtri</h3>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">nome prop</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Cerca"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="labelFiltri">ubicazione</Form.Label>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    onChange={e => setUbicazione(e.target.value)}
                                >
                                    <option value="Tutti">Tutti</option>
                                    <option value="A1">A1</option>
                                    <option value="A2">A2</option>
                                    <option value="A3">A3</option>
                                    <option value="A4">A4</option>
                                    <option value="A5">A5</option>
                                    <option value="A6">A6</option>
                                    <option value="A7">A7</option>
                                    <option value="A8">A8</option>
                                    <option value="A9">A9</option>
                                    <option value="A10">A10</option>
                                    <option value="A11">A11</option>
                                    <option value="A12">A12</option>
                                    <option value="A13">A13</option>
                                    <option value="B1">B1</option>
                                    <option value="B2">B2</option>
                                    <option value="B3">B3</option>
                                    <option value="B4">B4</option>
                                    <option value="B5">B5</option>
                                    <option value="B6">B6</option>
                                    <option value="B7">B7</option>
                                    <option value="B8">B8</option>
                                    <option value="B9">B9</option>
                                    <option value="B10">B10</option>
                                    <option value="Speciale">Speciale</option>
                                </Form.Control>
                            </Form.Group>
                            <Button
                                className="mb-2"
                                size="sm"
                                variant="outline-warning"
                                onClick={() => {
                                    setNome('')
                                    setUbicazione('Tutti')
                                }}
                            >
                                Reset
                            </Button>
                        </Container>
                        <Container
                            className="d-flex justify-content-center mt-3"
                        >
                            <div>
                                <Link
                                    to={{pathname:"/areariservata/magazzino/new"}}
                                >
                                    <Button
                                        size="sm"
                                        variant="warning"
                                        className="rounded-lg mx-2 my-2"
                                    >
                                        <b>NUOVA PROP</b>
                                    </Button>
                                </Link>
                            </div>
                        </Container>
                        <Container className="text-center mt-3">
                            <p
                                style={{
                                    fontSize: 'small',
                                    color: 'gray'
                                }}
                            >
                                <div>Props nel DB: {elencoProps ? Object.keys(elencoProps).length : '0'}</div>
                                <div>Props filtrate: {filtered.length}</div>
                            </p>
                        </Container>
                    </Col>
                    <Col className="pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5">
                        {
                            filtered.length!==0 ?
                                getCards()
                                :
                                <div style={{
                                    color:'lightgray'
                                }}>
                                    <Container className="text-center mt-5">
                                        <h2>La tua ricerca non ha prodotto nessun risultato...</h2>
                                        <h3>Riprova cambiando i filtri.</h3>
                                    </Container>
                                </div>
                        }
                        <Container className="d-flex justify-content-center">
                            <Pagination size="sm">
                                {
                                    createPagination()
                                }
                            </Pagination>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function createPagination() {
        let arr = []
        for(let i=1; i<=pagine; ++i) {
            arr.push(i)
        }
        return(
            arr.map(a => <Pagination.Item
                onClick={() => setPagActive(a)}
                active={a===pagActive}
            >
                {a}
            </Pagination.Item>)
        )
    }

    function getCards() {
        let arr = []
        for(let i=(pagActive-1)*disp; i<=pagActive*disp ; ++i) {
            arr.push(filtered[i] ? filtered[i] : null)
        }
        return(
            arr.map(a => a===null ? null : <CardProp id={a.id} props={a} />)
        )
    }

}


export default Magazzino