import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import MultiSelect from "react-multi-select-component";
import {CardScreen} from "./CardScreen";
import {options} from "./NewScreen";


export function Screen() {

    const [elencoScreen, setElencoScreen] = useState([])
    useEffect(() => {
        const ref = firebase.firestore().collection('screen')
        ref.onSnapshot(snapshot => {
            setElencoScreen(snapshot.docs)
        })
    }, [])

    const [cerca, setCerca] = useState('')
    const [selected, setSelected] = useState([]);

    let filtered = elencoScreen
    if(cerca.trim()!=='') {
        filtered.filter(a => (a.film + a.regista).toLowerCase().includes(cerca.trim().toLowerCase()))
    }

    return (
        <div className="mb-5">
            <Container>
                <div className="text-center mb-5">
                    <h2 className="text-warning">Screen Film</h2>
                    <p className="small">Database screen scene film</p>
                    <Link to='/areariservata/screen/new'>
                        <Button variant="outline-warning" className="rounded10 mt-3" size="sm">
                            Aggiungi
                        </Button>
                    </Link>
                </div>
                <Row>
                    <Col md="3" className="p-0 mx-3 mx-sm-3 mx-md-3 mx-lg-0 mx-xl-0
                    mb-5 mb-sm-5 mb-md-0 mb-lg-0 mb-xl-0">
                        <Container className="filtriContainer pt-4 pb-3 pl-4 pr-4">
                            <h3 className="mb-2 text-warning">Filtri</h3>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">cerca (film/regista)</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Cerca"
                                    value={cerca}
                                    onChange={e => setCerca(e.target.value)}
                                />
                            </Form.Group>
                            <div className="mb-2">
                                <Form.Label className="small">Tags</Form.Label>
                                <MultiSelect
                                    options={options}
                                    value={selected}
                                    onChange={setSelected}
                                    className="text-dark"
                                />
                            </div>
                        </Container>
                    </Col>
                    <Col className="pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5">
                        <Row>
                            {
                                filtered.length!==0 ?
                                    filtered.map(a => <CardScreen props={a.data()} />)
                                    :
                                    null
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
