import {Accordion, Badge, Button, Card, Col, Row, Spinner} from "react-bootstrap";
import moment from "moment";
import firebase from "./firebase";
import React, {useEffect, useState} from "react";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View} from "@react-pdf/renderer";
import logo from "./logoAutodichiarazione.png";


export function CardBookingAttrezzatura({a}) {

    const [elenco, setElenco] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('attrezzatura')
        actorsRef.onSnapshot(snapshot => {
            setElenco(snapshot.docs)
        })
    }, [])
    let attrezzatura = []
    if(elenco.length!==0) {
        elenco.map(a => {
            attrezzatura = [
                ...attrezzatura,
                a.data()
            ]
        })
    }
    attrezzatura = attrezzatura.sort((a,b) => (a.nome>b.nome) ? 1 : ((b.nome > a.nome) ? -1 : 0))

    let elencoAtt = []
    let elencoPdf = []
    let totalPages = 0
    if(attrezzatura.length!==0) {
        a.elenco.map(x => elencoAtt.push(attrezzatura.filter(y => y.id===x)[0]))
        totalPages = Math.ceil(elencoAtt.length/30)
        for(let i=0; i<Math.ceil(elencoAtt.length/30); i++) {
            elencoPdf = [
                ...elencoPdf,
                elencoAtt.slice(i*30, ((i+1)*30)+1)
            ]
        }
    }

    // PDF
    const styles = StyleSheet.create({
        page: {
            color: 'black',
            backgroundColor: '#ffffff'
        },
        intestazione: {
            paddingTop: 45,
            paddingLeft: 55,
            fontSize: 11,
        },
        section1: {
            fontSize: 12,
            paddingTop: 33,
            paddingLeft: 55,
            paddingRight: 55,
        },
        section2: {
            fontSize: 10,
            paddingTop: 10,
            paddingLeft: 70,
            paddingRight: 55,
        },
        section3: {
            minHeight: 20,
            border: '1px solid black',
            padding: 0,
            margin: -1,
        },
        section4: {
            maxWidth: 200,
            minHeight: '100%',
            borderRight: '1px solid black',
            display: 'flex',
            justifyContent: 'center',
            paddingLeft: 5,
            paddingRight: 5,
        },
        section5: {
            width: 40,
            borderRight: '1px solid black',
        }
    })

    let countPage = 0
    const DocumentoPdf = () => (
        <Document>
            {
                elencoPdf.length!==0 ?
                    elencoPdf.map(x => {
                        countPage++
                        return (
                            <Page size="A4" style={styles.page}>
                                <View style={styles.intestazione}>
                                    <Image src={logo} style={{width: '100px', marginBottom:1}} />
                                </View>
                                <View style={styles.section1}>
                                    <Text>PRENOTAZIONE ATTREZZATURA</Text>
                                </View>
                                <View style={styles.section2}>
                                    <Text>ID PRENOTAZIONE: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.id}</Text></Text>
                                    <Text>DATA: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.date}</Text></Text>
                                    <Text>ORA RITIRO: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.inizio}</Text></Text>
                                    <Text>ORA CONSEGNA: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.fine}</Text></Text>
                                    <Text>REFERENTE: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.referente}</Text></Text>
                                </View>
                                <View style={styles.section1}>
                                    <Text>ELENCO ATTREZZATURA - <Text style={{fontFamily: "Helvetica-Bold"}}>PAGINA {countPage}/{totalPages}</Text></Text>
                                </View>
                                <View style={styles.section2}>
                                    {
                                        x.length!==0 ?
                                            x.map(z =>
                                                <View style={styles.section3}>
                                                    <View style={styles.section4}>
                                                        <Text>{z.nome}</Text>
                                                    </View>
                                                </View>
                                            )
                                            :
                                            null
                                    }
                                </View>
                            </Page>
                        )
                    }
                    )
                    :
                    null
            }
        </Document>
    )

    return (
        <Card>
            <Accordion.Toggle as={Card.Header} eventKey={a.id}>
                <Row>
                    <Col>
                        <p>Referente: <span className="font-weight-bolder">{a.referente}</span></p>
                    </Col>
                    <Col>
                        <p>Giorno: <span className="font-weight-bolder">{moment(a.date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span></p>
                    </Col>
                    <Col>
                        <p>Ora ritiro: <span className="font-weight-bolder">{a.inizio}</span></p>
                    </Col>
                    <Col>
                        <p>Ora consegna: <span className="font-weight-bolder">{a.fine}</span></p>
                    </Col>
                    <Col md="auto">
                        {
                            a.status==='Booked' ?
                                <Badge variant="primary">Booked</Badge>
                                :
                                a.status==='Completed' ?
                                    <Badge variant="success">Completed</Badge>
                                    :
                                    <Badge variant="warning">STATUS</Badge>
                        }
                    </Col>
                </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={a.id}>
                <Card.Body>
                    <pre className="small text-black-50 mb-2">ID prenotazione: {a.id}</pre>
                    <p className="mb-2">Elenco attrezzatura:</p>
                    <ul>
                        {
                            elenco.length!==0 ?
                                a.elenco.map(b => {
                                    return <li className="font-weight-bolder">
                                        {elenco.filter(c => c.id === b)[0].data().nome} <span className="small text-black-50 mx-3">{elenco.filter(c => c.id === b)[0].data().id}</span>
                                        <input type="checkbox" />
                                    </li>
                                })
                                :
                                null
                        }
                    </ul>
                    <div className="text-right">
                        <PDFDownloadLink document={<DocumentoPdf />} fileName={'autodichiarazione' + '.pdf'}>
                            {({ blob, url, loading, error }) => (url ?
                                    <a href={url} target="_blank" rel="noreferrer">
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            className="mx-2 rounded-lg"
                                        >
                                            Genera PDF
                                        </Button>
                                    </a>
                                    :
                                    <Spinner animation="border" variant="warning" />
                            )}
                        </PDFDownloadLink>

                        <Button
                            variant="outline-primary"
                            size="sm"
                            className="mx-2"
                            onClick={() => {
                                firebase.firestore().collection('ritiriAttrezzatura').doc(a.id).update({
                                    status: 'Booked'
                                })
                            }}
                        >
                            Mark as booked
                        </Button>
                        <Button
                            variant="outline-success"
                            size="sm"
                            className="mx-2"
                            onClick={() => {
                                firebase.firestore().collection('ritiriAttrezzatura').doc(a.id).update({
                                    status: 'Completed'
                                })
                            }}
                        >
                            Mark as completed
                        </Button>
                        <Button
                            variant="outline-danger"
                            size="sm"
                            className="mx-2"
                            onClick={() => {
                                if(window.confirm('Eliminare questa prenotazione?')) {
                                    firebase.firestore().collection('ritiriAttrezzatura').doc(a.id).delete()
                                }
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}


/*

<Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.intestazione}>
                    <Image src={logo} style={{width: '100px', marginBottom:1}} />
                </View>
                <View style={styles.section1}>
                    <Text>PRENOTAZIONE ATTREZZATURA</Text>
                </View>
                <View style={styles.section2}>
                    <Text>ID PRENOTAZIONE: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.id}</Text></Text>
                    <Text>DATA: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.date}</Text></Text>
                    <Text>ORA RITIRO: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.inizio}</Text></Text>
                    <Text>ORA CONSEGNA: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.fine}</Text></Text>
                    <Text>REFERENTE: <Text style={{fontFamily: "Helvetica-Bold"}}>{a.referente}</Text></Text>
                </View>
                <View style={styles.section1}>
                    <Text>ELENCO ATTREZZATURA</Text>
                </View>
                <View style={styles.section2}>
                    {
                        attrezzatura.length!==0 ?
                            a.elenco.map(a => {
                                return <View style={styles.section3}>
                                    <View style={styles.section4}>
                                        <Text>{attrezzatura.filter(b => b.id === a)[0].nome}</Text>
                                    </View>
                                </View>
                            })
                            :
                            null
                    }
                </View>
            </Page>
        </Document>


 */
