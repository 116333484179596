import {Button, Container} from "react-bootstrap";
import logoRkh from "../logoRKH.png";
import {Link} from "react-router-dom";


export function TeamVideo() {
    return (
        <div>
            <Container fluid style={{backgroundColor:'#181818'}}>
                <Container className="App">
                    <Container className="mb-5">
                        <img
                            src={logoRkh}
                            alt="LogoRKH"
                            width="180px"
                            className="mb-4"
                        />
                        <h5
                            style={{
                                letterSpacing:'5px',
                                fontWeight:'lighter'
                            }}
                        >
                            TEAM  VIDEO
                        </h5>
                    </Container>
                    <Container className="mt-5">
                        <Link
                            to={{pathname:"/areariservata/teamvideo/report"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>REPORT</b>
                            </Button>
                        </Link>
                        <Link
                            to={{pathname:"/areariservata/teamvideo/calendario"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>CALENDARIO</b>
                            </Button>
                        </Link>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}
