import {Badge, Col, Container, Row} from "react-bootstrap";
import {FaLongArrowAltRight, FaRegCalendarAlt} from "react-icons/fa";
import {Link} from "react-router-dom";


function Strumenti() {
    return (
        <div>
            <Container>
                <Container className="text-center mb-5">
                    <h3 className="text-warning">Strumenti</h3>
                    <p style={{fontSize:'smaller'}}>Strumenti utili per il team produzione.</p>
                </Container>
                <Container>
                    <Row>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/autodichiarazione' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Autodichiarazione
                                    </h5>
                                    <p className="text-uppercase">
                                        Covid-19
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">NEW</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '' }}
                            >
                                <div className="cardStrumentiDisabled">
                                    <h5 className="font-weight-bold">
                                        Liberatoria
                                    </h5>
                                    <p className="text-uppercase">
                                        Liberatoria video
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="dark" className="mr-2">BETA</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/verbale' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Verbale riunione
                                    </h5>
                                    <p className="text-uppercase">
                                        Produzione Video
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">NEW</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/archivioverbali' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Archivio
                                    </h5>
                                    <p className="text-uppercase">
                                        Archivio Verbali
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="primary" className="d-none mr-2">BETA</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/attrezzatura/manage' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Attrezzatura
                                    </h5>
                                    <p className="text-uppercase">
                                        Pannello di controllo
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">NEW</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/calendario' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Calendario
                                    </h5>
                                    <p className="text-uppercase">
                                        Calendario Produzioni
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="mr-2">NEW</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/screen' }}
                            >
                                <div className="cardStrumenti">
                                    <h5 className="font-weight-bold">
                                        Screen Film
                                    </h5>
                                    <p className="text-uppercase">
                                        Database screen film
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="primary" className="mr-2">BETA</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '' }}
                            >
                                <div className="cardStrumentiDisabled">
                                    <h5 className="font-weight-bold">
                                        Script Editor
                                    </h5>
                                    <p className="text-uppercase">
                                        Sceneggiatura
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">Beta</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '/areariservata/autodichiarazione' }}
                            >
                                <div className="cardStrumentiDisabled d-none">
                                    <h5 className="font-weight-bold">
                                        Autodichiarazione
                                    </h5>
                                    <p className="text-uppercase">
                                        Covid-19
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="mr-2 d-none">NEW</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '' }}
                            >
                                <div className="cardStrumentiDisabled d-none">
                                    <h5 className="font-weight-bold">
                                        PdP Maker
                                    </h5>
                                    <p className="text-uppercase">
                                        Piano di Produzione
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">Beta</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '' }}
                            >
                                <div className="cardStrumentiDisabled d-none">
                                    <h5 className="font-weight-bold">
                                        Calendario
                                    </h5>
                                    <p className="text-uppercase">
                                        Calendario Produzioni
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">Beta</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                        <Col md={3} className="my-3">
                            <Link
                                style={{ textDecoration: 'none' }}
                                to={{ pathname: '' }}
                            >
                                <div className="cardStrumentiDisabled d-none">
                                    <h5 className="font-weight-bold">
                                        Script Editor
                                    </h5>
                                    <p className="text-uppercase">
                                        Sceneggiatura
                                    </p>
                                    <div className="d-flex justify-content-between align-items-center mt-4">
                                        <FaLongArrowAltRight size= "2em" />
                                        <Badge variant="danger" className="d-none mr-2">Beta</Badge>
                                    </div>
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}


export default Strumenti
