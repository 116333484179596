import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCLE4RM2lqXJUP1ESczMIwq5nrAkY4hWrE",
    authDomain: "rkh-app.firebaseapp.com",
    databaseURL: "https://rkh-app-default-rtdb.firebaseio.com",
    projectId: "rkh-app",
    storageBucket: "rkh-app.appspot.com",
    messagingSenderId: "260026386442",
    appId: "1:260026386442:web:297c006b4ccd12d34bf7f3",
    measurementId: "G-XJQWQQY1NW"
};

firebase.initializeApp(firebaseConfig);

export default firebase
