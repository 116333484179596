import {Button, Container, Form} from "react-bootstrap";
import {useState} from "react";
import firebase from "firebase";
import {nanoid} from "nanoid";
import {categories} from './Attrezzatura'


function AddAttrezzatura() {

    const [nome, setNome] = useState('')
    const [link, setLink] = useState('')
    const [categoria, setCategoria] = useState('')

    const [result, setResult] = useState('')

    return (
        <div>
            <Container>
                <div className="mb-5 text-center">
                    <h2 className="text-warning">Aggiungi attrezzatura</h2>
                    <p className="small">Tutti i campi sono obbligatori.</p>
                </div>
                <div>
                    <Form.Group>
                        <Form.Label className="small">Nome attrezzatura</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome attrezzatura"
                            value={nome}
                            onChange={e => setNome(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="small">Link foto</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Link foto"
                            value={link}
                            onChange={e => setLink(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="small">Categoria</Form.Label>
                        <Form.Control
                            as="select"
                            value={categoria}
                            onChange={e => setCategoria(e.target.value)}
                        >
                            <option value="" disabled>Scegli...</option>
                            {
                                categories.map(a => <option>{a}</option>)
                            }
                        </Form.Control>
                    </Form.Group>
                    <div
                        className="text-right"
                    >
                        <Button
                            disabled={!(nome !== '' && link !== '')}
                            variant="warning"
                            onClick={() => {
                                let id = nanoid(20)
                                firebase.firestore().collection('attrezzatura').doc(id).set({
                                    id: id,
                                    nome: nome,
                                    link: link,
                                    categoria: categoria,
                                    disponibile: true,
                                }).then(() => {
                                    setResult('Attrezzatura inserita con successo')
                                    //setNome('')
                                    //setLink('')
                                    //setCategoria('')
                                })
                            }}
                        >
                            Aggiungi
                        </Button>
                    </div>
                </div>
                <div className="text-center">
                    {
                        result ?
                            <p className="text-success font-weight-bolder">{result}</p>
                            :
                            null
                    }
                </div>
            </Container>
        </div>
    )
}


export default AddAttrezzatura