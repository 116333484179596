import {Alert, Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import firebase from "./firebase";
import CardVerbali from "./CardVerbali";


function ArchivioVerbali() {

    const [file, setFile] = useState('')
    const [nome, setNome] = useState('')
    const [error, setError] = useState('')
    const [result, setResult] = useState('')

    const [files, setFiles] = useState([])

    const ref = firebase.storage().ref('pdf/').child('verbali/')
    useEffect(() => {
        ref.listAll().then((res) => {
            setFiles(res.items)
        }).catch((error) => console.log(error))
    })


    return (
        <div>
            <Container>
                <Container className="mb-4">
                    <h2 style={{color:'gold', textAlign:'center'}}>Archivio Verbali</h2>
                    <p style={{textAlign:'center', fontSize:'smaller'}}>Archivio verbali di riunione di produzione.</p>
                </Container>
                <Container>
                    <Row>
                        <Col>
                            <Form.Label>Nome <span className="asterisco">*</span> </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome file"
                                value={nome}
                                onChange={e => setNome(e.target.value)}
                            />
                        </Col>
                        <Col>
                            <Form.Label>File <span className="asterisco">*</span> </Form.Label>
                            <Form.File
                                label={
                                    file==='' ?
                                        <p>Scegli un file</p>
                                        :
                                        <p>{file.name}</p>
                                }
                                data-browse="Scegli"
                                onChange={e => {
                                    if(e.target.files[0]) {
                                        if(e.target.files[0].type === 'application/pdf') {
                                            if(e.target.files[0].size <= 5*1024*1024) {
                                                setFile(e.target.files[0])
                                                setError('')
                                            } else {
                                                setFile('')
                                                setError('File troppo pesante')
                                            }
                                        } else {
                                            setFile('')
                                            setError('Formato file non supportato')
                                        }
                                    }
                                }}
                                custom
                            />
                            <Form.Text
                                className="mb-3"
                            >
                                Dimensione massima: 5MB - Solo formati .pdf
                            </Form.Text>
                        </Col>
                    </Row>


                    {
                        error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                    }

                    {
                        result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                    }

                    <div className="text-right mb-4">
                        <Button
                            variant="warning"
                            disabled={file==='' ? true : false}
                            onClick={() => {
                                firebase.storage().ref('pdf/verbali/' + nome + '.pdf').put(file).then(() => {
                                    setResult('File caricato correttamente')
                                }).catch((err) => setError('Errore caricamento file'))
                            }}
                        >
                            Upload
                        </Button>
                    </div>
                </Container>
                <Container>
                    <Row>
                        {
                            files.length!==0 ?
                                files.sort().reverse().map(a => <Col md="3" className="mb-4">
                                        <CardVerbali nome={a.name} />
                                    </Col>)
                                :
                                <Container className="text-center">
                                    <Spinner animation="border" variant="warning" className="mb-2"/>
                                    <h5>Caricamento dati...</h5>
                                </Container>
                        }
                    </Row>
                </Container>
            </Container>
        </div>
    )
}


export default ArchivioVerbali