import {Col, Row} from "react-bootstrap";
import {FaArrowRight} from "react-icons/all";
import {Link} from "react-router-dom";


export function CardUtente({props}) {


    return (
        <Col md={3}>
            <Link
                to={'/teamvideo/report/' + props.id}
            >
                <div className="cardUtenteTeamVideo">
                    <Row>
                        <Col>
                            <p className="font-weight-bold text-uppercase" style={{fontSize:'1.2em'}}>{props.nome}</p>
                            <p className="small text-uppercase">{props.cognome}</p>
                        </Col>
                        <Col md="auto d-flex justify-content-center align-items-center">
                            <FaArrowRight
                                size="1.5em"
                                color="#1a1a1a"
                            />
                        </Col>
                    </Row>
                </div>
            </Link>
        </Col>
    )
}
