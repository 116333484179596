import firebase from "./firebase";
import {useEffect, useState} from "react";
import {Alert, Button, Col, Container, Form, Modal, Row} from "react-bootstrap";
import {FaTrash, FaSearchPlus, FaCalendarDay, FaPencilAlt} from "react-icons/fa";
import costumePic from './costumePic.jpg'
import moment from "moment";
import {Link} from "react-router-dom";


function CardProp({id, props}) {

    // IMMAGINE
    const imgRef = firebase.storage().ref('images/magazzino/').child(id)
    const [image, setImage] = useState(costumePic)
    imgRef.getDownloadURL().then((url) => {
        setImage(url)
    }).catch((err) => {
        setImage(costumePic)
    })

    // MODAL
    const [show, setShow] = useState(false)
    const [dataRitiro, setDataRitiro] = useState('')
    const [dataConsegna, setDataConsegna] = useState('')
    const [dataConsegna2, setDataConsegna2] = useState('')
    const [referente, setReferente] = useState('')
    const [error, setError] = useState('')

    const [elencoRitiri, setElencoRitiri] = useState([])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('ritiri').doc(id).collection('ritiri')
        actorsRef.onSnapshot(snapshot => {
            setElencoRitiri(snapshot.docs)
        })
    }, [])

    return (
        <div>
            <Container
                className="cardPersona mb-4"
            >
                <Row style={{
                    minHeight: '200px'
                }}>
                    <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pl-0 pr-0">
                        <Container fluid className="titleCardPersona pt-3 pb-2">
                            <Container>
                                <h5>{props.nome.toUpperCase()}</h5>
                            </Container>
                        </Container>
                        <Row>
                            <Col>
                                <Container className="pl-4 pt-2">
                                    <p>Nome: <b>{props.nome}</b></p>
                                    <p>Descrizione: <b>{props.descrizione}</b></p>
                                    <p>Quantità: <b>{props.quantita}</b></p>
                                    <p>Ubicazione: <b>{props.ubicazione}</b></p>
                                </Container>
                            </Col>
                            <Col md="auto">
                                <Container className="text-right">
                                    <Link
                                        to={"/areariservata/magazzino/modifica/" + id}
                                    >
                                        <FaPencilAlt
                                            className="m-3"
                                            size="1.25em"
                                            color="black"
                                        />
                                    </Link>
                                    <FaTrash
                                        className="m-3"
                                        size="1.15em"
                                        color="red"
                                        onClick={() => {
                                            if(window.confirm("Eliminare questa prop?")) {
                                                firebase.firestore().collection('magazzino').doc(id).delete()
                                                firebase.storage().ref('images/magazzino/').child(id).delete()
                                            }
                                        }}
                                    />
                                    <div>
                                        <a href={image} target="_blank" rel="noreferrer">
                                            <FaSearchPlus
                                                className="m-3"
                                                size="1.25em"
                                                color="black"
                                            />
                                        </a>
                                    </div>
                                    <FaCalendarDay
                                        className="m-3"
                                        size="1.25em"
                                        color="black"
                                        onClick={() => setShow(true)}
                                    />
                                </Container>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="0" sm="0" md="0" lg="4" xl="4" className="pr-0 pl-0 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${image})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover'
                            }}
                            className="picCardPersona"
                        >
                        </div>
                    </Col>
                </Row>
            </Container>

            <div>
                <Modal show={show}
                       onHide={() => setShow(false)}
                       size="lg"
                       aria-labelledby="contained-modal-title-vcenter"
                       centered
                       style={{color:'black'}}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Storico ritiri</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table>
                            <tr>
                                <th className="text-warning">Data e Ora Ritiro</th>
                                <th className="text-warning">Data e Ora Consegna</th>
                                <th className="text-warning">Referente</th>
                            </tr>
                            {
                                elencoRitiri ?
                                    elencoRitiri.map(a => {
                                        let dati = a.data()
                                        return (
                                            <tr>
                                                <td>{moment(dati.dataRitiro, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')}</td>
                                                <td>{
                                                    dati.dataConsegna ?
                                                        moment(dati.dataConsegna, 'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')
                                                        :
                                                        <div>
                                                            <Row>
                                                                <Col md="auto">
                                                                    <Form.Control
                                                                        type="datetime-local"
                                                                        size="sm"
                                                                        min={dati.dataRitiro}
                                                                        value={dataConsegna2}
                                                                        onChange={e => setDataConsegna2(e.target.value)}
                                                                    />
                                                                </Col>
                                                                <Col md="auto">
                                                                    <Button
                                                                        size="sm"
                                                                        variant="warning"
                                                                        disabled={dataConsegna2==='' ? true : false}
                                                                        onClick={() => {
                                                                            firebase.firestore().collection('ritiri').doc(id).collection('ritiri').doc(dati.dataRitiro).set({
                                                                                id: dati.id,
                                                                                dataRitiro: dati.dataRitiro,
                                                                                dataConsegna: dataConsegna2,
                                                                                referente: dati.referente
                                                                            }).then(() => {
                                                                                setError('')
                                                                                setDataConsegna2('')
                                                                            }).catch(() => setError('Errore nell\'inserimento della data di consegna. Riprova.'))
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                }</td>
                                                <td>{dati.referente}</td>
                                            </tr>
                                        )
                                    })
                                    :
                                    null
                            }
                        </table>
                        <Row className="mt-3">
                            <Col>
                                <Form.Control
                                    type="datetime-local"
                                    size="sm"
                                    value={dataRitiro}
                                    onChange={e => setDataRitiro(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    type="datetime-local"
                                    size="sm"
                                    min={dataRitiro}
                                    value={dataConsegna}
                                    onChange={e => setDataConsegna(e.target.value)}
                                />
                            </Col>
                            <Col>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    value={referente}
                                    onChange={e => setReferente(e.target.value)}
                                >
                                    <option value="" disabled>Scegli...</option>
                                    <option value="Roberto">Roberto</option>
                                    <option value="Julia">Julia</option>
                                    <option value="Daniele">Daniele</option>
                                    <option value="Francesca">Francesca</option>
                                </Form.Control>
                            </Col>
                            <Col md="auto">
                                <Button
                                    size="sm"
                                    variant="warning"
                                    disabled={dataRitiro==='' || referente==='' ? true : false}
                                    onClick={() => {
                                        if(dataRitiro==='') setError('Data e Ora Ritiro non valida.')
                                        else if(referente==='') setError('Scegliere un referente.')
                                        else {
                                            firebase.firestore().collection('ritiri').doc(id).collection('ritiri').doc(dataRitiro).set({
                                                id: id,
                                                dataRitiro: dataRitiro,
                                                dataConsegna: dataConsegna,
                                                referente: referente
                                            }).then(() => {
                                                setError('')
                                                setDataRitiro('')
                                                setDataConsegna('')
                                                setReferente('')
                                            }).catch(() => setError('Errore nell\'inserimento del ritiro. Riprova.'))
                                        }
                                    }}
                                >
                                    Add
                                </Button>
                            </Col>
                        </Row>
                        {
                            error ?
                                <div>
                                    <Alert variant="danger" size="sm" className="mt-3 text-center">
                                        {error}
                                    </Alert>
                                </div>
                                :
                                null
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShow(false)}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>

        </div>
    )
}

export default CardProp