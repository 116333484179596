import {Alert, Button, Container, Form} from "react-bootstrap";
import {useState} from "react";
import {nanoid} from "nanoid";
import firebase from "./firebase";


function AddProp() {

    const [nome, setNome] = useState('')
    const [descrizione, setDescrizione] = useState('')
    const [quantita, setQuantita] = useState(1)
    const [ubicazione, setUbicazione] = useState('')
    const [foto, setFoto] = useState('')

    const [error, setError] = useState('')
    const [result, setResult] = useState('')

    return (
        <div>
            <Container className="mb-4">
                <h2 style={{color:'gold', textAlign:'center'}}>Nuova prop</h2>
                <p style={{textAlign:'center', fontSize:'smaller'}}>Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori</p>
            </Container>

            <Container>
                <Form.Label>Nome <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Nome"
                    onChange={e => setNome(e.target.value)}
                    value={nome}
                    className="mb-3"
                />

                <Form.Label>Descrizione</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Note"
                    onChange={e => setDescrizione(e.target.value)}
                    value={descrizione}
                    className="mb-3"
                />

                <Form.Label>Quantità <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    type="number"
                    onChange={e => setQuantita(e.target.value)}
                    value={quantita}
                    min={0}
                    className="mb-3"
                />

                <Form.Label>Ubicazione <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    as="select"
                    onChange={e => setUbicazione(e.target.value)}
                    value={ubicazione}
                    className="mb-3"
                >
                    <option disabled value="">Scegli...</option>
                    <option value="A1">A1</option>
                    <option value="A2">A2</option>
                    <option value="A3">A3</option>
                    <option value="A4">A4</option>
                    <option value="A5">A5</option>
                    <option value="A6">A6</option>
                    <option value="A7">A7</option>
                    <option value="A8">A8</option>
                    <option value="A9">A9</option>
                    <option value="A10">A10</option>
                    <option value="A11">A11</option>
                    <option value="A12">A12</option>
                    <option value="A13">A13</option>
                    <option value="B1">B1</option>
                    <option value="B2">B2</option>
                    <option value="B3">B3</option>
                    <option value="B4">B4</option>
                    <option value="B5">B5</option>
                    <option value="B6">B6</option>
                    <option value="B7">B7</option>
                    <option value="B8">B8</option>
                    <option value="B9">B9</option>
                    <option value="B10">B10</option>
                    <option value="Speciale">Speciale</option>
                </Form.Control>

                <Form.Label>Foto <span className="asterisco">*</span> </Form.Label>
                <Form.File
                    label={
                        foto==='' ?
                            <p>Scegli un file</p>
                            :
                            <p>{foto.name}</p>
                    }
                    data-browse="Scegli"
                    onChange={e => {
                        if(e.target.files[0]) {
                            if(e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
                                if(e.target.files[0].size <= 5*1024*1024) {
                                    setFoto(e.target.files[0])
                                    setError('')
                                } else {
                                    setFoto('')
                                    setError('Foto Prop - File troppo pesante')
                                }
                            } else {
                                setFoto('')
                                setError('Foto Prop - Formato file non supportato')
                            }
                        }
                    }}
                    custom
                />
                <Form.Text
                    className="mb-3"
                >
                    Dimensione massima: 5MB - Solo formati .jpg .png .jpeg
                </Form.Text>

                {
                    error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                }

                {
                    result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                }

                <div className="mb-5 d-flex justify-content-end">
                    <Button
                        size="md"
                        variant="warning"
                        onClick={() => {

                            if(nome==='') setError('Nome non valido.')
                            else if(quantita==='' || quantita<1) setError('Quantità non valida')
                            else if(ubicazione==='') setError('Ubicazione non valida.')
                            else if(foto==='') setError('Caricare una foto.')
                            else {
                                let string = nome + '-' + nanoid(8)
                                string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
                                let id = string.split(' ').join('')
                                firebase.storage().ref('images/magazzino/' + id).put(foto).then(() => {
                                    console.log('Immagine caricata')
                                }).catch((err) => setError('Errore caricamento immagine'))
                                firebase.firestore().collection('magazzino').doc(id).set({
                                    id: id,
                                    nome: nome,
                                    descrizione: descrizione,
                                    quantita : quantita,
                                    ubicazione: ubicazione,
                                }).then(() => {
                                    setError('')
                                    setResult('Prop inserita con successo!')

                                    setNome('')
                                    setDescrizione('')
                                    setQuantita(1)
                                    setUbicazione('')
                                    setFoto('')
                                }).catch((err) => setError('Errore inserimento prop'))
                            }
                        }}
                    >
                        Aggiungi
                    </Button>
                </div>

            </Container>
        </div>
    )
}

export default AddProp