import {useLocation} from "react-router";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import moment from "moment";
import {FaTrashAlt} from "react-icons/fa";


function EditActor() {

    const [nome, setNome] = useState()
    const [cognome, setCognome] = useState()
    const [sesso, setSesso] = useState()
    const [dataNascita, setDataNascita] = useState()
    const [luogoNascita, setLuogoNascita] = useState()
    const [luogoDomicilio, setLuogoDomicilio] = useState()
    const [cf, setCf] = useState()
    const [email, setEmail] = useState()
    const [telefono, setTelefono] = useState()
    const [comparsa, setComparsa] = useState()
    const [figurante, setFigurante] = useState()
    const [attore, setAttore] = useState()
    const [livelloRecitazione, setLivelloRecitazione] = useState()
    const [retribuzione, setRetribuzione] = useState()
    const [instagram, setInstagram] = useState()
    const [facebook, setFacebook] = useState()
    const [youtube, setYoutube] = useState()
    const [showreel, setShowreel] = useState()
    const [commenti, setCommenti] = useState()

    const queryId = useLocation().pathname.split('/')[4]
    const [info, setInfo] = useState()

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('actors').doc(queryId)
        actorsRef.onSnapshot(snapshot => {
            setInfo(snapshot.data())
        })
    }, [])

    useEffect(() => {
        if(info) {
            setNome(info.nome)
            setCognome(info.cognome)
            setSesso(info.sesso)
            setDataNascita(info.dataNascita)
            setLuogoNascita(info.luogoNascita)
            setLuogoDomicilio(info.luogoDomicilio)
            setCf(info.cf)
            setEmail(info.email)
            setTelefono(info.telefono)
            setComparsa(info.comparsa)
            setFigurante(info.figurante)
            setAttore(info.attore)
            setLivelloRecitazione(info.livelloRecitazione)
            setRetribuzione(info.retribuzione)
            setInstagram(info.instagram)
            setFacebook(info.facebook)
            setYoutube(info.youtube)
            setShowreel(info.showreel)
            setCommenti(info.commenti)
        }
    }, [info])

    const [error, setError] = useState('')
    const [result, setResult] = useState('')


    return (
        <div>
            {
                info ?
                    <Container>
                        <Container
                            className="text-center mb-5"
                        >
                            <h2 className="text-warning">
                                Modifica attore
                            </h2>
                            <p style={{textAlign:'center', fontSize:'smaller'}}>
                                Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori
                            </p>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Nome <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={nome}
                                        onChange={e => setNome(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Cognome <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={cognome}
                                        onChange={e => setCognome(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Sesso <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={sesso}
                                        onChange={e => setSesso(e.target.value)}
                                    >
                                        <option value="Uomo">Uomo</option>
                                        <option value="Donna">Donna</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Data di Nascita <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="date"
                                        size="sm"
                                        value={dataNascita}
                                        onChange={e => setDataNascita(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Luogo di Nascita <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={luogoNascita}
                                        onChange={e => setLuogoNascita(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Città di Domicilio <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={luogoDomicilio}
                                        onChange={e => setLuogoDomicilio(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Codice Fiscale <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={cf}
                                        onChange={e => setCf(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Email <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="email"
                                        size="sm"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Telefono <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        type="tel"
                                        size="sm"
                                        value={telefono}
                                        onChange={e => setTelefono(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Posizione <span className="asterisco">*</span></Form.Text>
                                    <Row>
                                        <Col>
                                            <Form.Check
                                                label="Comparsa"
                                                checked={comparsa}
                                                onChange={() => comparsa ? setComparsa(false) : setComparsa(true)}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                label="Figurante"
                                                checked={figurante}
                                                onChange={() => figurante ? setFigurante(false) : setFigurante(true)}
                                            />
                                        </Col>
                                        <Col>
                                            <Form.Check
                                                label="Attore"
                                                checked={attore}
                                                onChange={() => attore ? setAttore(false) : setAttore(true)}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Form.Text>Livello Recitazione <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={livelloRecitazione}
                                        onChange={e => setLivelloRecitazione(e.target.value)}
                                    >
                                        <option value="Basso">Basso</option>
                                        <option value="Medio">Medio</option>
                                        <option value="Alto">Alto</option>
                                    </Form.Control>
                                </Col>
                                <Col>
                                    <Form.Text>Retribuzione <span className="asterisco">*</span></Form.Text>
                                    <Form.Control
                                        as="select"
                                        size="sm"
                                        value={retribuzione}
                                        onChange={e => setRetribuzione(e.target.value)}
                                    >
                                        <option value="No">Disponibile solo per prestazioni non retribuite</option>
                                        <option value="Si">Disponibile solo per prestazioni retribuite</option>
                                        <option value="Entrambe">Disponibile per entrambe</option>
                                    </Form.Control>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Instagram</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={instagram}
                                        onChange={e => setInstagram(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Facebook</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={facebook}
                                        onChange={e => setFacebook(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Youtube</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={youtube}
                                        onChange={e => setYoutube(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container className="mb-2">
                            <Row>
                                <Col>
                                    <Form.Text>Showreel</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={showreel}
                                        onChange={e => setShowreel(e.target.value)}
                                    />
                                </Col>
                                <Col>
                                    <Form.Text>Commenti</Form.Text>
                                    <Form.Control
                                        type="text"
                                        size="sm"
                                        value={commenti}
                                        onChange={e => setCommenti(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                        <Container>
                            {
                                error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                            }

                            {
                                result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                            }
                        </Container>
                        <Container
                            className="mt-2 text-right"
                        >
                            <Row>
                                <Col className="text-left">
                                    <FaTrashAlt
                                        size="1.4em"
                                        className="m-3"
                                        color="red"
                                        onClick={() => {
                                            if(window.confirm('Vuoi ELIMINARE questo attore?')) {
                                                firebase.storage().ref('images/actors/' + queryId).child('0').delete()
                                                firebase.storage().ref('images/actors/' + queryId).child('1').delete()
                                                firebase.storage().ref('images/actors/' + queryId).child('2').delete()
                                                firebase.storage().ref('images/actors/' + queryId).child('3').delete()
                                                firebase.storage().ref('images/actors/' + queryId).child('cv').delete()
                                                firebase.firestore().collection('actors').doc(queryId).delete()
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        className="mt-3 text-right"
                                        variant="warning"
                                        onClick={
                                            () => {

                                                if(nome==='') setError('Nome non valido.')
                                                else if(cognome==='') setError('Cognome non valido.')
                                                else if(sesso==='') setError('Sesso non valido.')
                                                else if(dataNascita==='') setError('Data di nascita non valida.')
                                                else if(moment(dataNascita, 'YYYY-MM-DD') >= moment()) setError('Data di nascita non valida.')
                                                else if(luogoNascita==='') setError('Luogo di nascita non valido.')
                                                else if(luogoDomicilio==='') setError('Luogo di domicilio non valido.')
                                                else if(cf==='') setError('Codice fiscale non valido.')
                                                else if(email==='' || email.split('@').length!==2) setError('Email non valida.')
                                                else if(email.split('@')[0].length===0 || email.split('@')[1].length===0) setError('Email non valida.')
                                                else if(telefono==='') setError('Telefono non valido.')
                                                else if(!comparsa && !figurante && !attore) setError('Posizione non valida.')
                                                else if(livelloRecitazione==='') setError('Livello recitazione non valido.')
                                                else if(retribuzione==='') setError('Retribuzione non valida.')
                                                else {
                                                    let id = info.id

                                                    firebase.firestore().collection('actors').doc(id).set({
                                                        id: id,
                                                        nome: nome,
                                                        cognome: cognome,
                                                        sesso: sesso,
                                                        dataNascita: dataNascita,
                                                        luogoNascita: luogoNascita,
                                                        luogoDomicilio: luogoDomicilio,
                                                        cf: cf,
                                                        email: email,
                                                        telefono: telefono,
                                                        comparsa: comparsa,
                                                        figurante: figurante,
                                                        attore: attore,
                                                        livelloRecitazione: livelloRecitazione,
                                                        retribuzione: retribuzione,
                                                        instagram: instagram,
                                                        facebook: facebook,
                                                        youtube: youtube,
                                                        showreel: showreel,
                                                        commenti: commenti,
                                                        accetto: info.accetto,
                                                        note: ''
                                                    })
                                                    setError('')
                                                    setResult('Dati aggiornati con successo!')
                                                }
                                            }
                                        }
                                    >
                                        Salva modifiche
                                    </Button>
                                </Col>
                            </Row>
                        </Container>
                    </Container>
                    :
                    null
            }
        </div>
    )

}

export default EditActor