import {Button, Col, Container, Form, Pagination, Row} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import PersonCard from "./PersonCard";
import moment from "moment";
import firebase from "./firebase";
import {StateContext} from "./App";
import {
    setNome,
    setMinEta,
    setMaxEta,
    setUomo,
    setDonna,
    setComparsa,
    setFigurante,
    setAttore,
    setAlto,
    setBasso,
    setMedio,
    setRetribuito,
    setNonRetribuito,
    setCollab,
    setResidenti,
    clearFilters, orderByEta, orderByNome
} from "./Actions";
import {Link} from "react-router-dom";


function Attori() {

    const [state, dispatch] = useContext(StateContext)

    const [elencoAttori, setElencoAttori] = useState([])
    const [elencoCollab, setElencoCollab] = useState([])
    const [elencoAttoriProvv, setElencoAttoriProvv] = useState([])

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('actors')
        actorsRef.onSnapshot(snapshot => {
            setElencoAttori(snapshot.docs)
        })
    }, [])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('collabs')
        actorsRef.onSnapshot(snapshot => {
            setElencoCollab(snapshot.docs)
        })
    }, [])
    useEffect(() => {
        const actorsRef = firebase.firestore().collection('provActors')
        actorsRef.onSnapshot(snapshot => {
            setElencoAttoriProvv(snapshot.docs)
        })
    }, [])

    let attori = []
    if(elencoAttori) attori = Object.keys(elencoAttori).map(a => elencoAttori[a].data())
    let filtered = attori.filter(a => (a.nome+' '+a.cognome).toLowerCase().includes(state.nome))

    filtered = filtered.filter(b => {
        const anno = moment().format('YYYY')
        const mese = moment().format('MM')
        const giorno = moment().format('DD')
        let eta = 0
        const [a, m, g] = b.dataNascita.split('-')
        eta = anno - a - 1
        if(mese > m) eta++
        else if(mese===m && giorno >= g) eta++

        if(eta>=state.minEta && eta<=state.maxEta) {
            return true
        }else return false
    })

    if(state.uomo && !state.donna) filtered = filtered.filter(a => a.sesso==='Uomo')
    if(!state.uomo && state.donna) filtered = filtered.filter(a => a.sesso==='Donna')

    if(state.comparsa && state.figurante && !state.attore) filtered = filtered.filter(a => a.comparsa===true || a.figurante===true)
    if(state.comparsa && !state.figurante && state.attore) filtered = filtered.filter(a => a.comparsa===true || a.attore===true)
    if(state.comparsa && !state.figurante && !state.attore) filtered = filtered.filter(a => a.comparsa===true)
    if(!state.comparsa && state.figurante && state.attore) filtered = filtered.filter(a => a.figurante===true || a.attore===true)
    if(!state.comparsa && state.figurante && !state.attore) filtered = filtered.filter(a => a.figurante===true)
    if(!state.comparsa && !state.figurante && state.attore) filtered = filtered.filter(a => a.attore===true)

    if(state.basso && state.medio && !state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Basso' || a.livelloRecitazione==='Medio')
    if(state.basso && !state.medio && state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Basso' || a.livelloRecitazione==='Alto')
    if(state.basso && !state.medio && !state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Basso')
    if(!state.basso && state.medio && state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Medio' || a.livelloRecitazione==='Alto')
    if(!state.basso && state.medio && !state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Medio')
    if(!state.basso && !state.medio && state.alto) filtered = filtered.filter(a => a.livelloRecitazione==='Alto')

    if(state.retribuito && !state.nonRetribuito) filtered = filtered.filter(a => a.retribuzione==='Si' || a.retribuzione==='Entrambe')
    if(!state.retribuito && state.nonRetribuito) filtered = filtered.filter(a => a.retribuzione==='No' || a.retribuzione==='Entrambe')


    if(state.collab) filtered = filtered.filter(a => {
        elencoCollab.map(b => {
            if(b.id===a.id) return true
            else return false
        })
    })

    if(state.residenti) filtered = filtered.filter(a => (a.luogoDomicilio).toLowerCase().includes('torin') ||
        (a.luogoDomicilio).toLowerCase().includes('chivasso') || (a.luogoDomicilio).toLowerCase().includes('rivoli'))

    if(state.orderByEta) filtered = filtered.sort((a,b) => a.dataNascita < b.dataNascita ? 1 : -1)
    if(state.orderByNome) filtered = filtered.sort((a,b) => (a.nome + ' ' + a.cognome) > (b.nome + ' ' + b.cognome) ? 1 : -1)

    // PAGINATION
    let disp = 20
    let pagine = Math.ceil(filtered.length / disp)
    const [pagActive, setPagActive] = useState(1)

    return(
        <div>
            <Container className="mb-5">
                <Row>
                    <Col md="3" className="p-0 mx-3 mx-sm-3 mx-md-3 mx-lg-0 mx-xl-0
                    mb-5 mb-sm-5 mb-md-0 mb-lg-0 mb-xl-0">
                        <Container className="filtriContainer pt-4 pb-3 pl-4 pr-4">
                            <h3 className="mb-2 text-warning">Filtri</h3>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">nome o cognome attore</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Cerca"
                                    value={state.nome}
                                    onChange={e => dispatch(setNome((e.target.value).toLowerCase()))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">range età</Form.Label>
                                <Row>
                                    <Col className="pr-1">
                                        <Form.Control
                                            size="sm"
                                            type="number"
                                            value={state.minEta}
                                            max={state.maxEta}
                                            onChange={e => dispatch(setMinEta(e.target.value))}
                                        />
                                    </Col>
                                    <Col className="pl-1">
                                        <Form.Control
                                            size="sm"
                                            type="number"
                                            value={state.maxEta}
                                            min={state.minEta}
                                            onChange={e => dispatch(setMaxEta(e.target.value))}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">sesso</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => state.uomo ? dispatch(setUomo(false)) : dispatch(setUomo(true))}>Uomo</p>}
                                    checked={state.uomo}
                                    onChange={() => state.uomo ? dispatch(setUomo(false)) : dispatch(setUomo(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.donna ? dispatch(setDonna(false)) : dispatch(setDonna(true))}>Donna</p>}
                                    value={state.donna}
                                    checked={state.donna}
                                    onChange={() => state.donna ? dispatch(setDonna(false)) : dispatch(setDonna(true))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">tipo</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => state.comparsa ? dispatch(setComparsa(false)) : dispatch(setComparsa(true))}>Comparsa</p>}
                                    value={state.comparsa}
                                    checked={state.comparsa}
                                    onChange={() => state.comparsa ? dispatch(setComparsa(false)) : dispatch(setComparsa(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.figurante ? dispatch(setFigurante(false)) : dispatch(setFigurante(true))}>Figurante</p>}
                                    value={state.figurante}
                                    checked={state.figurante}
                                    onChange={() => state.figurante ? dispatch(setFigurante(false)) : dispatch(setFigurante(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.attore ? dispatch(setAttore(false)) : dispatch(setAttore(true))}>Attore</p>}
                                    value={state.attore}
                                    checked={state.attore}
                                    onChange={() => state.attore ? dispatch(setAttore(false)) : dispatch(setAttore(true))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">livello recitazione</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => state.basso ? dispatch(setBasso(false)) : dispatch(setBasso(true))}>Basso</p>}
                                    checked={state.basso}
                                    onChange={() => state.basso ? dispatch(setBasso(false)) : dispatch(setBasso(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.medio ? dispatch(setMedio(false)) : dispatch(setMedio(true))}>Medio</p>}
                                    checked={state.medio}
                                    onChange={() => state.medio ? dispatch(setMedio(false)) : dispatch(setMedio(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.alto ? dispatch(setAlto(false)) : dispatch(setAlto(true))}>Alto</p>}
                                    checked={state.alto}
                                    onChange={() => state.alto ? dispatch(setAlto(false)) : dispatch(setAlto(true))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">retribuzione</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => state.retribuito ? dispatch(setRetribuito(false)) : dispatch(setRetribuito(true))}>Retribuito</p>}
                                    checked={state.retribuito}
                                    onChange={() => state.retribuito ? dispatch(setRetribuito(false)) : dispatch(setRetribuito(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.nonRetribuito ? dispatch(setNonRetribuito(false)) : dispatch(setNonRetribuito(true))}>Non Retribuito</p>}
                                    checked={state.nonRetribuito}
                                    onChange={() => state.nonRetribuito ? dispatch(setNonRetribuito(false)) : dispatch(setNonRetribuito(true))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="labelFiltri">altri filtri</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => state.collab ? dispatch(setCollab(false)) : dispatch(setCollab(true))}>Solo persone che hanno già collaborato con RKH</p>}
                                    checked={state.collab}
                                    onChange={() => state.collab ? dispatch(setCollab(false)) : dispatch(setCollab(true))}
                                />
                                <Form.Check
                                    label={<p onClick={() => state.residenti ? dispatch(setResidenti(false)) : dispatch(setResidenti(true))}>Solo residenti a Torino</p>}
                                    checked={state.residenti}
                                    onChange={() => state.residenti ? dispatch(setResidenti(false)) : dispatch(setResidenti(true))}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="labelFiltri">ordina per</Form.Label>
                                <Form.Check
                                    type="radio"
                                    label={<p onClick={() => state.orderByEta ? null : dispatch(orderByEta())}>Età</p>}
                                    checked={state.orderByEta}
                                    onChange={() => state.orderByEta ? null : dispatch(orderByEta())}
                                />
                                <Form.Check
                                    type="radio"
                                    label={<p onClick={() => state.orderByNome ? null : dispatch(orderByNome())}>Nome</p>}
                                    checked={state.orderByNome}
                                    onChange={() => state.orderByNome ? null : dispatch(orderByNome())}
                                />
                            </Form.Group>
                            <Button
                                className="mb-2"
                                size="sm"
                                variant="outline-warning"
                                onClick={() => dispatch(clearFilters())}
                            >
                                Reset
                            </Button>
                        </Container>
                        <Container className="text-center mt-3">
                            <p
                                style={{
                                    fontSize: 'small',
                                    color: 'gray'
                                }}
                            >
                                <div>Attori nel DB: {Object.keys(elencoAttori).length}</div>
                                <div>Attori filtrati: {filtered.length}</div>
                                <div><Link id="linkAttoriPorvvisori" to={{pathname: '/areariservata/attoriprovvisori'}} >Attori provvisori: {
                                    elencoAttoriProvv ?
                                        Object.keys(elencoAttoriProvv).length
                                        :
                                        '0'
                                }</Link></div>
                            </p>
                        </Container>
                    </Col>
                    <Col className="pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5">
                        {
                            filtered.length!==0 ?
                                getCards()
                                :
                                <div style={{
                                    color:'lightgray'
                                }}>
                                    <Container className="text-center mt-5">
                                        <h2>La tua ricerca non ha prodotto nessun risultato...</h2>
                                        <h3>Riprova cambiando i filtri.</h3>
                                    </Container>
                                </div>
                        }
                        <Container className="d-flex justify-content-center">
                            <Pagination size="sm">
                                {
                                    createPagination()
                                }
                            </Pagination>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function createPagination() {
        let arr = []
        for(let i=1; i<=pagine; ++i) {
            arr.push(i)
        }
        return(
            arr.map(a => <Pagination.Item
                onClick={() => setPagActive(a)}
                active={a===pagActive}
            >
                {a}
            </Pagination.Item>)
        )
    }

    function getCards() {
        let arr = []
        for(let i=(pagActive-1)*disp; i<=pagActive*disp ; ++i) {
            arr.push(filtered[i] ? filtered[i] : null)
        }
        return(
            arr.map(a => a===null ? null : <PersonCard props={a} />)
        )
    }




}

export default Attori
