import {Button, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import {FaPlus} from "react-icons/fa";
import {useState} from "react";
import moment from "moment";
import firebase from "firebase";
import {nanoid} from "nanoid";


export function CalendarioNew() {


    const [cliente, setCliente] = useState('')
    const [commerciale, setCommerciale] = useState('')
    const [datand, setDatand] = useState(false)
    const [data, setData] = useState('')
    const [datariprese, setDatariprese] = useState([])
    const [videomaker, setVideomaker] = useState('')
    const [budget, setBudget] = useState('')
    const [reference, setReference] = useState('')
    const [references, setReferences] = useState([])
    const [clientepresenza, setClientepresenza] = useState(false)
    const [produzione, setProduzione] = useState(false)
    const [trucco, setTrucco] = useState(false)
    const [spoglio, setSpoglio] = useState('')
    const [upload, setUpload] = useState('')
    const [note, setNote] = useState('')

    const [result, setResult] = useState('')
    const [loading, setLoading] = useState(false)


    return (
        <div className="pt-4 mb-5">
            <Container>
                <div className="text-center mb-4">
                    <h2 className="text-warning">Nuovo Video</h2>
                    <p className="small">Calendario consegne video</p>
                </div>
                <div className="pt-2">
                    <Row>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Nome cliente *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nome cliente"
                                value={cliente}
                                onChange={e => setCliente(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Commerciale *</Form.Label>
                            <Form.Control
                                as="select"
                                value={commerciale}
                                onChange={e => setCommerciale(e.target.value)}
                            >
                                <option value="" disabled>Scegli...</option>
                                <option>Roberto</option>
                                <option>Andrea</option>
                                <option>Elia</option>
                                <option>Filippino</option>
                                <option>RKH</option>
                                <option>Altro</option>
                            </Form.Control>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <span>Data riprese *</span>
                                    <span>
                                        <Form.Check
                                            type="checkbox"
                                            label="Da decidere"
                                            className="mt-1"
                                            checked={datand}
                                            onChange={e => datand ? setDatand(false) : setDatand(true)}
                                        />
                                    </span>
                                </div>
                            </Form.Label>
                            <Row>
                                <Col className="pr-1">
                                    <Form.Control
                                        type="date"
                                        value={data}
                                        disabled={datand}
                                        onChange={e => setData(e.target.value)}
                                    />
                                </Col>
                                <Col md="auto" className="pl-1">
                                    <Button variant="outline-warning"
                                            disabled={datand}
                                            onClick={() => {
                                                if(moment(data).isValid()) {
                                                    setDatariprese([...datariprese, data])
                                                    setData('')
                                                }
                                            }}
                                    >
                                        <FaPlus />
                                    </Button>
                                </Col>
                            </Row>
                            <div className={datand ? 'd-none' : ''}>
                                <ul className="mt-2 mb-0">
                                    {
                                        datariprese.map(d => <li className="small">{d}</li>)
                                    }
                                </ul>
                            </div>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Videomaker *</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Videomaker"
                                value={videomaker}
                                onChange={e => setVideomaker(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Budget *</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Budget"
                                value={budget}
                                onChange={e => setBudget(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">References</Form.Label>
                            <Row>
                                <Col className="pr-1">
                                    <Form.Control
                                        type="link"
                                        placeholder="References"
                                        value={reference}
                                        onChange={e => setReference(e.target.value)}
                                    />
                                </Col>
                                <Col md="auto" className="pl-1">
                                    <Button variant="outline-warning"
                                            onClick={() => {
                                                if(reference.trim()!=='') {
                                                    setReferences([...references, reference])
                                                    setReference('')
                                                }
                                            }}
                                    >
                                        <FaPlus />
                                    </Button>
                                </Col>
                            </Row>
                            <ul className="mt-2 mb-0">
                                {
                                    references.map(r => <li className="small">{r}</li>)
                                }
                            </ul>
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Spoglio *</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Spoglio"
                                rows={3}
                                value={spoglio}
                                onChange={e => setSpoglio(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Note</Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Note"
                                rows={3}
                                value={note}
                                onChange={e => setNote(e.target.value)}
                            />
                        </Col>
                        <Col md={6} className="mb-3">
                            <Form.Label className="small">Eventuale upload</Form.Label>
                            <Form.File
                                label={upload ? upload.name : 'Scegli un file PDF'}
                                accept='application/pdf'
                                data-browse="Scegli..."
                                custom
                                onChange={e => {
                                    if(e.target.files[0]) {
                                        if(e.target.files[0].type === 'application/pdf') {
                                            if(e.target.files[0].size <= 5*1024*1024) {
                                                setUpload(e.target.files[0])
                                            }
                                        }
                                    }
                                }}
                            />
                            <Form.Text className="small">Solo file PDF - Max 5MB</Form.Text>
                        </Col>
                        <Col md={6} className="mb-3 d-flex justify-content-center align-items-center">
                            <Row className="text-center">
                                <Col md="auto">
                                    <Form.Check
                                        type="checkbox"
                                        label="Cliente in presenza"
                                        className="mt-1"
                                        checked={clientepresenza}
                                        onChange={e => clientepresenza ? setClientepresenza(false) : setClientepresenza(true)}
                                    />
                                </Col>
                                <Col md="auto">
                                    <Form.Check
                                        type="checkbox"
                                        label="Produzione"
                                        className="mt-1"
                                        checked={produzione}
                                        onChange={e => produzione ? setProduzione(false) : setProduzione(true)}
                                    />
                                </Col>
                                <Col md="auto">
                                    <Form.Check
                                        type="checkbox"
                                        label="Trucco"
                                        className="mt-1"
                                        checked={trucco}
                                        onChange={e => trucco ? setTrucco(false) : setTrucco(true)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="text-right">
                            {
                                result ?
                                    <span className="text-success font-weight-bold mr-3">{result}</span>
                                    :
                                    null
                            }
                            {
                                loading ?
                                    <Spinner animation="border" variant="warning" size="sm" className="mr-2" />
                                    :
                                    null
                            }
                            <Button
                                variant="outline-warning"
                                disabled={
                                    cliente.trim()==='' || commerciale==='' || videomaker==='' || budget==='' || budget===0 || spoglio==='' ||
                                    (datand===false && datariprese.length===0)
                                }
                                onClick={() => {
                                    setLoading(true)
                                    uploadData()
                                }}
                            >
                                Aggiungi
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )

    function uploadData() {
        const id = cliente.replace( /\s/g, '') + '-' + nanoid(10)

        if(upload!=='') {
            firebase.storage().ref('pdf/docs/' + id + '.pdf').put(upload).then(() => {
                let d
                if(datand) d = datand
                else d = datariprese
                firebase.firestore().collection('calendario').doc(id).set({
                    id: id,
                    stato: 'Riprese',
                    cliente: cliente,
                    commerciale: commerciale,
                    datariprese: d,
                    videomaker: videomaker,
                    budget: Number(budget),
                    references: references,
                    clientepresenza: Boolean(clientepresenza),
                    produzione: Boolean(produzione),
                    trucco: Boolean(trucco),
                    spoglio: spoglio,
                    note: note
                }).then(() => {
                    setResult('Video aggiunto con successo.')
                    clearInput()
                })
            })
        } else {
            let d
            if(datand) d = datand
            else d = datariprese
            firebase.firestore().collection('calendario').doc(id).set({
                id: id,
                stato: 'Riprese',
                cliente: cliente,
                commerciale: commerciale,
                datariprese: d,
                videomaker: videomaker,
                budget: Number(budget),
                references: references,
                clientepresenza: Boolean(clientepresenza),
                produzione: Boolean(produzione),
                trucco: Boolean(trucco),
                spoglio: spoglio,
                note: note
            }).then(() => {
                setResult('Video aggiunto con successo.')
                clearInput()
                setLoading(false)
            })
        }
    }

    function clearInput() {
        setCliente('')
        setCommerciale('')
        setData('')
        setDatariprese([])
        setDatand(false)
        setVideomaker('')
        setBudget('')
        setReferences([])
        setReference([])
        setSpoglio('')
        setNote('')
        setClientepresenza(false)
        setProduzione(false)
        setTrucco(false)
        setUpload('')
    }

}
