import {Button, Col, Container, Form, Row} from 'react-bootstrap'
import MultiSelect from "react-multi-select-component";
import {useState} from "react";
import {nanoid} from "nanoid";
import firebase from 'firebase'

export const options = [
    { label: "Drammatico", value: "Drammatico" },
    { label: "Cupo", value: "Cupo" },
    { label: "Horror", value: "Horror" },
    { label: "Oscurità", value: "Oscurità" },
    { label: "Cucina", value: "Cucina" },
    { label: "Camera da letto", value: "Camera da letto" },
    { label: "Salotto", value: "Salotto" },
    { label: "Bagno", value: "Bagno" },
    { label: "Vintage", value: "Vintage" },
    { label: "Particolare", value: "Particolare" },
];

export function NewScreen() {

    const [selected, setSelected] = useState([]);


    const [film, setFilm] = useState('');
    const [regista, setRegista] = useState('');
    const [file, setFile] = useState('');

    const [result, setResult] = useState('')
    const [error, setError] = useState('')


    return (
        <div>
            <Container>
                <div className="text-center mb-5">
                    <h2 className="text-warning">Aggiungi Screen Film</h2>
                    <p className="small">Database screen scene film</p>
                </div>
                <Row>
                    <Col md={6} className="mb-3">
                        <Form.Label className="small">Titolo film</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome Film"
                            value={film}
                            onChange={e => setFilm(e.target.value)}
                        />
                    </Col>
                    <Col md={6} className="mb-3">
                        <Form.Label className="small">Regista</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Nome Regista"
                            value={regista}
                            onChange={e => setRegista(e.target.value)}
                        />
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Label className="small">Upload File</Form.Label>
                        <Form.File
                            label={
                                file==='' ?
                                    <p>Scegli un file</p>
                                    :
                                    <p>{file.name}</p>
                            }
                            data-browse="Scegli"
                            onChange={e => {
                                if(e.target.files[0]) {
                                    if(e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
                                        if(e.target.files[0].size <= 1*1024*1024) {
                                            setFile(e.target.files[0])
                                            setError('')
                                        } else {
                                            setError('File troppo grande')
                                        }
                                    } else {
                                        setError('Formato file non supportato')
                                    }
                                }
                            }}
                            custom
                        />
                        <Form.Text className="small">Solo file PNG JPG JPEG - Max 1MB</Form.Text>
                    </Col>
                    <Col md={12} className="mb-3">
                        <Form.Label className="small">Tags</Form.Label>
                        <MultiSelect
                            options={options}
                            value={selected}
                            onChange={setSelected}
                            className="text-dark"
                        />
                    </Col>
                    <Col className="text-right mb-3">
                        {
                            error ?
                                <span className="font-weight-bold text-danger mr-3">{error}</span>
                                :
                                null
                        }
                        {
                            result ?
                                <sapn className="font-weight-bold text-success mr-3">{result}</sapn>
                                :
                                null
                        }
                        <Button variant="outline-warning"
                                disabled={film.trim()==='' || regista.trim()==='' || selected.length===0 || file===''}
                                onClick={() => uploadData()}
                        >
                            Aggiungi
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function uploadData() {
        const id = film + '_' + regista + '_' + nanoid(10)
        firebase.storage().ref('images/screen/' + id).put(file).then(() => {
            firebase.firestore().collection('screen').doc(id).set({
                id: id,
                film: film,
                regista: regista,
                tags: selected,
            }).then(() => {
                setError('')
                setResult('Screen caricato con successo.')
                setFilm('')
                setRegista('')
                setFile('')
                setSelected('')
            })
        })
    }
}
