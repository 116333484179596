import {
    ADD_ATTREZZATURA, CLEAR_ATTREZZATURA,
    CLEAR_FILTERS, DEL_ATTREZZATURA,
    LOG_IN, LOG_IN_ADMIN, LOG_IN_ATTREZZATURA, ORDER_BY_ETA, ORDER_BY_NOME,
    SET_ALTO,
    SET_ATTORE, SET_BASSO, SET_COLLAB,
    SET_COMPARSA,
    SET_DONNA,
    SET_FIGURANTE,
    SET_MAX_ETA, SET_MEDIO,
    SET_MIN_ETA,
    SET_NOME, SET_NON_RETRIBUITO, SET_RESIDENTI, SET_RETRIBUITO,
    SET_UOMO
} from "./Actions";


export const initialState = {
    nome: '',
    minEta: 0,
    maxEta: 99,
    uomo: false,
    donna: false,
    comparsa: false,
    figurante: false,
    attore: false,
    basso: false,
    medio: false,
    alto: false,
    retribuito: false,
    nonRetribuito: false,
    collab: false,
    residenti: false,
    orderByEta: false,
    orderByNome: false,

    login: false,
    loginAttrezzatura: false,
    loginAdmin: false,

    attrezzatura: [],
}

export function Reducer(state, action) {
    switch (action.type) {
        case SET_NOME :
            return {
                ...state,
                nome: action.nome
            }
        case SET_MIN_ETA :
            return {
                ...state,
                minEta: action.minEta
            }
        case SET_MAX_ETA :
            return {
                ...state,
                maxEta: action.maxEta
            }
        case SET_UOMO :
            return {
                ...state,
                uomo: action.uomo
            }
        case SET_DONNA :
            return {
                ...state,
                donna: action.donna
            }
        case SET_COMPARSA :
            return {
                ...state,
                comparsa: action.comparsa
            }
        case SET_FIGURANTE :
            return {
                ...state,
                figurante: action.figurante
            }
        case SET_ATTORE :
            return {
                ...state,
                attore: action.attore
            }
        case SET_BASSO :
            return {
                ...state,
                basso: action.basso
            }
        case SET_MEDIO :
            return {
                ...state,
                medio: action.medio
            }
        case SET_ALTO :
            return {
                ...state,
                alto: action.alto
            }
        case SET_RETRIBUITO :
            return {
                ...state,
                retribuito: action.retribuito
            }
        case SET_NON_RETRIBUITO :
            return {
                ...state,
                nonRetribuito: action.nonRetribuito
            }
        case SET_COLLAB :
            return {
                ...state,
                collab: action.collab
            }
        case SET_RESIDENTI :
            return {
                ...state,
                residenti: action.residenti
            }
        case CLEAR_FILTERS :
            return {
                ...state,
                nome: '',
                minEta: 0,
                maxEta: 99,
                uomo: false,
                donna: false,
                comparsa: false,
                figurante: false,
                attore: false,
                basso: false,
                medio: false,
                alto: false,
                retribuito: false,
                nonRetribuito: false,
                collab: false,
                residenti: false,
                orderByEta: false,
                orderByNome: false,
            }
        case ORDER_BY_ETA :
            return {
                ...state,
                orderByEta: true,
                orderByNome: false
        }
        case ORDER_BY_NOME :
            return {
                ...state,
                orderByEta: false,
                orderByNome: true
            }
        case LOG_IN :
            return {
                ...state,
                login: true,
                loginAttrezzatura: true,
                loginAdmin: false,
            }
        case LOG_IN_ATTREZZATURA :
            return {
                ...state,
                login: false,
                loginAttrezzatura: true,
                loginAdmin: false,
            }
        case LOG_IN_ADMIN :
            return {
                ...state,
                login: true,
                loginAttrezzatura: true,
                loginAdmin: true,
            }

        case ADD_ATTREZZATURA :
            return {
                ...state,
                attrezzatura: [
                    ...state.attrezzatura,
                    action.id
                ]
            }
        case DEL_ATTREZZATURA :
            return {
                ...state,
                attrezzatura: state.attrezzatura.filter(a => a!==action.id)
            }
        case CLEAR_ATTREZZATURA :
            return {
                ...state,
                attrezzatura: []
            }

        default :
            return state
    }
}
