import {Container, Button} from "react-bootstrap";
import logoRkh from './logoRKH.png'
import {Link} from "react-router-dom";


function Home() {
    return(
        <div>
            <Container fluid style={{backgroundColor:'#181818'}}>
                <Container className="App">
                    <Container className="mb-5">
                        <img
                            src={logoRkh}
                            alt="LogoRKH"
                            width="180px"
                            className="mb-4"
                        />
                        <h5
                            style={{
                                letterSpacing:'5px',
                                fontWeight:'lighter'
                            }}
                        >
                            PRODUCTION  APP
                        </h5>
                    </Container>
                    <Container className="mt-5">
                        <Link
                            to={{pathname:"/areariservata/attori"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>ATTORI</b>
                            </Button>
                        </Link>
                        <Link
                            to={{pathname:"/areariservata/costumi"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>COSTUMI</b>
                            </Button>
                        </Link>
                        <Link
                            to={{pathname:"/areariservata/magazzino"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>MAGAZZINO</b>
                            </Button>
                        </Link>
                        <Link
                            to={{pathname:"/areariservata/attrezzatura"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>ATTREZZATURA</b>
                            </Button>
                        </Link>
                    </Container>
                    <Container className="mt-3">
                        <Link
                            to={{pathname:"/areariservata/strumenti"}}
                        >
                            <Button
                                variant="warning"
                                className="rounded-lg mx-2 my-2"
                            >
                                <b>STRUMENTI</b>
                            </Button>
                        </Link>
                    </Container>
                </Container>
            </Container>
        </div>
    )
}


export default Home
