import {Button, Container} from "react-bootstrap";
import logoRkh from './logoRKH.png'
import {Link} from "react-router-dom";


function HomeOspiti() {
    return(
        <div>
            <Container style={{minHeight:'75vh'}} className="d-flex flex-column justify-content-center align-items-center mb-5">
                <div className="mb-5">
                    <img
                        src={logoRkh}
                        alt="LogoRKH"
                        width="180px"
                        className="mb-5"
                    />
                </div>
                <div className="text-center mb-5">
                    <p style={{fontSize:'20px'}}>
                        <b className="text-warning">RKH Studio</b> è alla continua ricerca di <b className="text-warning">comparse</b>, <b className="text-warning">figuranti</b> e <b className="text-warning">attori</b> per produzioni di cortometraggi e video musicali.
                    </p>
                    <p style={{fontSize:'20px'}}>
                        Se desideri candidarti, compila il <b className="text-warning">form</b> nella sezione <Link to="/iscriviti"><b className="text-warning">Iscriviti</b></Link>.
                    </p>
                </div>
                <div>
                    <Link
                        to={{pathname:"/iscriviti"}}
                    >
                        <Button
                            variant="warning"
                            className="rounded-lg mx-2"
                        >
                            <b>ISCRIVITI</b>
                        </Button>
                    </Link>
                </div>
            </Container>
            <Link
                to={{
                    pathname: '/areariservata'
                }}
            >
                <div
                    className="d-none d-sm-none d-md-none d-lg-block d-xl-block"
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '100px',
                        height: '100px',
                    }}
                />
            </Link>
        </div>
    )
}

export default HomeOspiti
