import {Button, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import firebase from "../firebase";
import {CardUtente} from "./CardUtente";


export function Report() {

    const [utenti, setUtenti] = useState([])
    useEffect(() => {
        const ref = firebase.firestore().collection('teamvideo-utenti')
        ref.onSnapshot(snapshot => {
            setUtenti(snapshot.docs)
        })
    }, [])

    return (
        <div>
            <Container>
                <div className="mb-5">
                    <div className="text-center mb-3">
                        <h2 className="text-warning">Report settimanale</h2>
                        <p></p>
                    </div>
                    <div className="text-center">
                        <Link to="/teamvideo/report/addutente">
                            <Button
                                variant="outline-warning"
                                size="sm"
                            >
                                Nuovo Utente
                            </Button>
                        </Link>
                    </div>
                </div>
                <Row>
                    {
                        utenti.length!==0 ?
                            utenti.map(u => <CardUtente props={u.data()} /> )
                            :
                            null
                    }
                </Row>
            </Container>
        </div>
    )
}
