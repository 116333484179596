import {Col, Container, Row} from "react-bootstrap";
import logoRKH from '../logoRKH.png'
import {Link, NavLink} from "react-router-dom";


function NavBarVideo() {
    return(
        <div className="mb-5">
            <Container fluid className="NavbarContainer">
                <Container>
                    <Row className="d-block d-sm-none d-md-none d-lg-none text-center pt-4 pb-2">
                        <Col>
                            <Link
                                to={{pathname:'/teamvideo'}}
                            >
                                <img src={logoRKH} alt="LogoRKH" height="30px"/>
                            </Link>
                        </Col>
                    </Row>
                    <Row className="pt-4 pb-4">
                        <Col className="d-none d-sm-block d-md-block d-lg-block">
                            <Link
                                to={{pathname:'/teamvideo'}}
                            >
                                <img src={logoRKH} alt="LogoRKH" height="30px"/>
                            </Link>
                        </Col>
                        <Col xs="3" sm="auto" md="auto" lg="auto" xl="auto">
                            <NavLink
                                to={{pathname:"/teamvideo"}}
                                style={{
                                    color: "white"
                                }}
                            >
                                Home
                            </NavLink>
                        </Col>
                        <Col xs="3" sm="auto" md="auto" lg="auto" xl="auto">
                            <NavLink
                                to={{pathname:"/teamvideo/strumenti"}}
                                style={{
                                    color: "white"
                                }}
                            >
                                Strumenti
                            </NavLink>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default NavBarVideo
