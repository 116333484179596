import {Button, Col, Container, Form, Pagination, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import firebase from "./firebase";
import CardCostume from "./CardCostume";


function Costumi() {

    const [nome, setNome] = useState('')
    const [uomo, setUomo] = useState(false)
    const [donna, setDonna] = useState(false)
    const [unisex, setUnisex] = useState(false)
    const [adulto, setAdulto] = useState(false)
    const [bambino, setBambino] = useState(false)
    const [ubicazione, setUbicazione] = useState('Tutti')


    const [elencoCostumi, setElencoCostumi] = useState([])

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('costumi')
        actorsRef.onSnapshot(snapshot => {
            setElencoCostumi(snapshot.docs)
        })
    }, [])

    let costumi = []
    if(elencoCostumi) costumi = Object.keys(elencoCostumi).map(a => elencoCostumi[a].data())
    let filtered = costumi.filter(a => (a.nome).toLowerCase().includes(nome) || (a.note).toLowerCase().includes(nome))

    if(uomo && donna && !unisex) filtered = filtered.filter(a => a.sesso==='Uomo' || a.sesso==='Donna')
    if(uomo && !donna && unisex) filtered = filtered.filter(a => a.sesso==='Uomo' || a.sesso==='Unisex')
    if(uomo && !donna && !unisex) filtered = filtered.filter(a => a.sesso==='Uomo')
    if(!uomo && donna && unisex) filtered = filtered.filter(a => a.sesso==='Donna' || a.sesso==='Unisex')
    if(!uomo && donna && !unisex) filtered = filtered.filter(a => a.sesso==='Donna')
    if(!uomo && !donna && unisex) filtered = filtered.filter(a => a.sesso==='Unisex')

    if(adulto && !bambino) filtered = filtered.filter(a => a.eta==='Adulto')
    if(!adulto && bambino) filtered = filtered.filter(a => a.eta==='Bambino')

    if(ubicazione!=='Tutti') filtered = filtered.filter(a => a.ubicazione===ubicazione)

    // PAGINATION
    let disp = 20
    let pagine = Math.ceil(filtered.length / disp)
    const [pagActive, setPagActive] = useState(1)

    return (
        <div>
            <Container className="mb-5">
                <Row>
                    <Col md="3" className="p-0 mx-3 mx-sm-3 mx-md-3 mx-lg-0 mx-xl-0
                    mb-5 mb-sm-5 mb-md-0 mb-lg-0 mb-xl-0">
                        <Container className="filtriContainer pt-4 pb-3 pl-4 pr-4">
                            <h3 className="mb-2 text-warning">Filtri</h3>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">nome costume</Form.Label>
                                <Form.Control
                                    size="sm"
                                    type="text"
                                    placeholder="Cerca"
                                    value={nome}
                                    onChange={e => setNome(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">sesso</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => uomo ? setUomo(false) : setUomo(true)}>Uomo</p>}
                                    checked={uomo}
                                    onChange={() => uomo ? setUomo(false) : setUomo(true)}
                                />
                                <Form.Check
                                    label={<p onClick={() => donna ? setDonna(false) : setDonna(true)}>Donna</p>}
                                    checked={donna}
                                    onChange={() => donna ? setDonna(false) : setDonna(true)}
                                />
                                <Form.Check
                                    label={<p onClick={() => unisex ? setUnisex(false) : setUnisex(true)}>Unisex</p>}
                                    checked={unisex}
                                    onChange={() => unisex ? setUnisex(false) : setUnisex(true)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="labelFiltri">età</Form.Label>
                                <Form.Check
                                    label={<p onClick={() => adulto ? setAdulto(false) : setAdulto(true)}>Adulto</p>}
                                    checked={adulto}
                                    onChange={() => adulto ? setAdulto(false) : setAdulto(true)}
                                />
                                <Form.Check
                                    label={<p onClick={() => bambino ? setBambino(false) : setBambino(true)}>Bambino</p>}
                                    checked={bambino}
                                    onChange={() => bambino ? setBambino(false) : setBambino(true)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label className="labelFiltri">ubicazione</Form.Label>
                                <Form.Control
                                    as="select"
                                    size="sm"
                                    onChange={e => setUbicazione(e.target.value)}
                                >
                                    <option value="Tutti">Tutti</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="Speciali">Speciali</option>
                                </Form.Control>
                            </Form.Group>
                            <Button
                                className="mb-2"
                                size="sm"
                                variant="outline-warning"
                                onClick={() => {
                                    setNome('')
                                    setUomo(false)
                                    setDonna(false)
                                    setUnisex(false)
                                    setAdulto(false)
                                    setBambino(false)
                                    setUbicazione('Tutti')
                                }}
                            >
                                Reset
                            </Button>
                        </Container>
                        <Container
                            className="d-flex justify-content-center mt-3"
                        >
                            <div>
                                <Link
                                    to={{pathname:"/areariservata/costumi/new"}}
                                >
                                    <Button
                                        size="sm"
                                        variant="warning"
                                        className="rounded-lg mx-2 my-2"
                                    >
                                        <b>NUOVO COSTUME</b>
                                    </Button>
                                </Link>
                            </div>
                        </Container>
                        <Container className="text-center mt-3">
                            <p
                                style={{
                                    fontSize: 'small',
                                    color: 'gray'
                                }}
                            >
                                <div>Costumi nel DB: {Object.keys(elencoCostumi).length}</div>
                                <div>Costumi filtrati: {filtered.length}</div>
                            </p>
                        </Container>
                    </Col>
                    <Col className="pl-1 pl-sm-1 pl-md-3 pl-lg-5 pl-xl-5">
                        {
                            filtered.length!==0 ?
                                getCards()
                                :
                                <div style={{
                                    color:'lightgray'
                                }}>
                                    <Container className="text-center mt-5">
                                        <h2>La tua ricerca non ha prodotto nessun risultato...</h2>
                                        <h3>Riprova cambiando i filtri.</h3>
                                    </Container>
                                </div>
                        }
                        <Container className="d-flex justify-content-center">
                            <Pagination size="sm">
                                {
                                    createPagination()
                                }
                            </Pagination>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )

    function createPagination() {
        let arr = []
        for(let i=1; i<=pagine; ++i) {
            arr.push(i)
        }
        return(
            arr.map(a => <Pagination.Item
                onClick={() => setPagActive(a)}
                active={a===pagActive}
            >
                {a}
            </Pagination.Item>)
        )
    }

    function getCards() {
        let arr = []
        for(let i=(pagActive-1)*disp; i<=pagActive*disp ; ++i) {
            arr.push(filtered[i] ? filtered[i] : null)
        }
        return(
            arr.map(a => a===null ? null : <CardCostume id={a.id} props={a} />)
        )
    }
}


export default Costumi