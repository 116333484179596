import {Badge, Button, Card, Col, Form, Modal, Nav, Row, Spinner, Tab} from "react-bootstrap";
import moment from "moment";
import {FaCheckCircle, FaTimesCircle} from "react-icons/all";
import firebase from "firebase";
import {FaDownload, FaPlus, FaTrashAlt} from "react-icons/fa";
import {useState} from "react";


export function CardCalendario({props}) {

    const [download, setDownload] = useState(null)
    firebase.storage().ref('pdf/docs/').child(props.id + '.pdf').getDownloadURL().then((link) => {
        setDownload(link)
    })

    const [showModalEdit, setShowModalEdit] = useState(false)
    const [cliente, setCliente] = useState(props.cliente)
    const [commerciale, setCommerciale] = useState(props.commerciale)
    const [datand, setDatand] = useState(typeof props.datariprese === 'boolean' ? props.datariprese : false)
    const [data, setData] = useState('')
    const [datariprese, setDatariprese] = useState(typeof props.datariprese !== 'boolean' ? props.datariprese : [])
    const [videomaker, setVideomaker] = useState(props.videomaker)
    const [budget, setBudget] = useState(Number(props.budget))
    const [reference, setReference] = useState('')
    const [references, setReferences] = useState(props.references)
    const [clientepresenza, setClientepresenza] = useState(Boolean(props.clientepresenza))
    const [produzione, setProduzione] = useState(Boolean(props.produzione))
    const [trucco, setTrucco] = useState(Boolean(props.trucco))
    const [spoglio, setSpoglio] = useState(props.spoglio)
    const [upload, setUpload] = useState('')
    const [note, setNote] = useState(props.note)
    const [result, setResult] = useState('')
    const [loading, setLoading] = useState(false)


    return (
        <Card className="text-dark mb-3 rounded10">
            <Card.Header className="font-weight-bold">
                <Row>
                    <Col>
                        <p className="small">CLIENTE</p>
                        <p className="font-weight-bold">{props.cliente}</p>
                    </Col>
                    <Col className="text-center">
                        <p className="small">COMMERCIALE</p>
                        <p className="font-weight-bold">{props.commerciale}</p>
                    </Col>
                    <Col className="text-right">
                        <p className="small">STATO</p>
                        {
                            props.stato==='Riprese' ?
                                <Badge variant="danger">Riprese</Badge>
                                :
                                props.stato==='In Lavorazione' ?
                                    <Badge variant="warning">In Lavorazione</Badge>
                                    :
                                    <Badge variant="success">Chiuso</Badge>
                        }
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Card.Text>
                    <Tab.Container defaultActiveKey="info">
                        <Row>
                            <Col md={3}>
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="info">INFO</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="spoglio">SPOGLIO</Nav.Link>
                                    </Nav.Item>
                                    {
                                        props.note ?
                                            <Nav.Item>
                                                <Nav.Link eventKey="note">NOTE</Nav.Link>
                                            </Nav.Item>
                                            :
                                            null
                                    }
                                    <Nav.Item>
                                        <Nav.Link eventKey="altro">ALTRO</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                            <Col>
                                <Tab.Content>
                                    <Tab.Pane eventKey="info">
                                        <Row className="mb-3">
                                            <Col md={2} >
                                                <p className="small font-weight-bold pt-1">DATA RIPRESE</p>
                                            </Col>
                                            <Col>
                                                {
                                                    props.datariprese===true ?
                                                        <p>Da definire</p>
                                                        :
                                                        props.datariprese.length!==0 ?
                                                            props.datariprese.map(d => <p> {moment(d).format('DD/MM/YYYY')} </p>)
                                                            :
                                                            null
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={2} >
                                                <p className="small font-weight-bold pt-1">VIDEOMAKER</p>
                                            </Col>
                                            <Col>
                                                <p>{props.videomaker}</p>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={2}>
                                                <p className="small font-weight-bold pt-1">BUDGET</p>
                                            </Col>
                                            <Col>
                                                <p>{props.budget.toFixed(2)} €</p>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={2}>
                                                <p className="small font-weight-bold pt-1">ALTRO</p>
                                            </Col>
                                            <Col>
                                                <Row>
                                                    <Col>
                                                        <p>Cliente in presenza <span>
                                                            {
                                                                props.clientepresenza ?
                                                                    <FaCheckCircle className="ml-1" color="#00ab14" />
                                                                    :
                                                                    <FaTimesCircle className="ml-1" color="#d11100" />
                                                            }
                                                        </span></p>
                                                    </Col>
                                                    <Col>
                                                        <p>Produzione <span>
                                                            {
                                                                props.produzione ?
                                                                    <FaCheckCircle className="ml-1" color="#00ab14" />
                                                                    :
                                                                    <FaTimesCircle className="ml-1" color="#d11100" />
                                                            }
                                                        </span></p>
                                                    </Col>
                                                    <Col>
                                                        <p>Trucco <span>
                                                            {
                                                                props.trucco ?
                                                                    <FaCheckCircle className="ml-1" color="#00ab14" />
                                                                    :
                                                                    <FaTimesCircle className="ml-1" color="#d11100" />
                                                            }
                                                        </span></p>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="spoglio" className="text-justify">
                                        {props.spoglio}
                                    </Tab.Pane>
                                    {
                                        props.note ?
                                            <Tab.Pane eventKey="note" className="text-justify">
                                                {props.note}
                                            </Tab.Pane>
                                            :
                                            null
                                    }
                                    <Tab.Pane eventKey="altro">
                                        <div>
                                            {
                                                props.references.length!==0 ?
                                                    <Row className="mb-3">
                                                        <Col md={2}>
                                                            <p className="small font-weight-bold pt-1">REFERENCES</p>
                                                        </Col>
                                                        <Col>
                                                            {
                                                                props.references.map(r => <p>{r}</p>)
                                                            }
                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div>
                                            {
                                                download!==null ?
                                                    <Row className="mb-3">
                                                        <Col md={2}>
                                                            <p className="small font-weight-bold pt-1">DOWNLOAD</p>
                                                        </Col>
                                                        <Col md="auto">
                                                            <a href={download} target="_blank" rel="noreferrer">
                                                                <div className="downloadItem">
                                                                    <FaDownload className="mr-2" />
                                                                    <span className="small">{props.id + '.pdf'}</span>
                                                                </div>
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="text-right">
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="rounded10 mx-1"
                                                onClick={() => {
                                                    if(window.confirm('Vuoi eliminare questo video?')) {
                                                        firebase.firestore().collection('calendario').doc(props.id).delete()
                                                        firebase.storage().ref('pdf/docs/').child(props.id + '.pdf').delete()
                                                    }
                                                }}
                                            >
                                                Elimina
                                            </Button>
                                            <Button
                                                variant="warning"
                                                size="sm"
                                                className="rounded10 mx-1"
                                                onClick={() => setShowModalEdit(true)}
                                            >
                                                Modifica
                                            </Button>
                                            {
                                                props.stato==='Riprese' ?
                                                    <Button
                                                        variant="success"
                                                        size="sm"
                                                        className="rounded10 mx-1"
                                                        onClick={() => {
                                                            if(window.confirm('Cambiare stato da RIPRESE a IN LAVORAZIONE?')) {
                                                                firebase.firestore().collection('calendario').doc(props.id).update({
                                                                    stato: 'In Lavorazione',
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        In lavorazione
                                                    </Button>
                                                    :
                                                    props.stato==='In Lavorazione' ?
                                                        <Button
                                                            variant="success"
                                                            size="sm"
                                                            className="rounded10 mx-1"
                                                            onClick={() => {
                                                                if(window.confirm('Cambiare stato da IN LAVORAZIONE a CHIUSO?')) {
                                                                    firebase.firestore().collection('calendario').doc(props.id).update({
                                                                        stato: 'Chiuso',
                                                                    })
                                                                }
                                                            }}
                                                        >
                                                            Chiuso
                                                        </Button>
                                                        :
                                                        null
                                            }
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Card.Text>
            </Card.Body>

            <Modal show={showModalEdit} onHide={() => setShowModalEdit(false)}
                   size="xl"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
                   className="text-dark"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Modifica Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="pt-2">
                        <Row>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Nome cliente *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Nome cliente"
                                    value={cliente}
                                    onChange={e => setCliente(e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Commerciale *</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={commerciale}
                                    onChange={e => setCommerciale(e.target.value)}
                                >
                                    <option value="" disabled>Scegli...</option>
                                    <option>Roberto</option>
                                    <option>Andrea</option>
                                    <option>Elia</option>
                                    <option>Filippino</option>
                                    <option>RKH</option>
                                    <option>Altro</option>
                                </Form.Control>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small w-100">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <span>Data riprese *</span>
                                        <span>
                                        <Form.Check
                                            type="checkbox"
                                            label="Da decidere"
                                            className="mt-1"
                                            checked={datand}
                                            onChange={e => datand ? setDatand(false) : setDatand(true)}
                                        />
                                    </span>
                                    </div>
                                </Form.Label>
                                <Row>
                                    <Col className="pr-1">
                                        <Form.Control
                                            type="date"
                                            value={data}
                                            disabled={datand}
                                            onChange={e => setData(e.target.value)}
                                        />
                                    </Col>
                                    <Col md="auto" className="pl-1">
                                        <Button variant="outline-warning"
                                                disabled={datand}
                                                onClick={() => {
                                                    if(moment(data).isValid()) {
                                                        setDatariprese([...datariprese, data])
                                                        setData('')
                                                    }
                                                }}
                                        >
                                            <FaPlus />
                                        </Button>
                                    </Col>
                                </Row>
                                <div className={datand ? 'd-none' : ''}>
                                    <ul className="mt-2 mb-0">
                                        {
                                            datariprese.map(d => <li className="small">{d}<span>
                                            <FaTrashAlt className="ml-2 iconTrash" onClick={() => setDatariprese(datariprese.filter(a => a!==d))} />
                                        </span></li>)
                                        }
                                    </ul>
                                </div>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Videomaker *</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Videomaker"
                                    value={videomaker}
                                    onChange={e => setVideomaker(e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Budget *</Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="Budget"
                                    value={budget}
                                    onChange={e => setBudget(e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">References</Form.Label>
                                <Row>
                                    <Col className="pr-1">
                                        <Form.Control
                                            type="link"
                                            placeholder="References"
                                            value={reference}
                                            onChange={e => setReference(e.target.value)}
                                        />
                                    </Col>
                                    <Col md="auto" className="pl-1">
                                        <Button variant="outline-warning"
                                                onClick={() => {
                                                    if(reference.trim()!=='') {
                                                        setReferences([...references, reference])
                                                        setReference('')
                                                    }
                                                }}
                                        >
                                            <FaPlus />
                                        </Button>
                                    </Col>
                                </Row>
                                <ul className="mt-2 mb-0">
                                    {
                                        references.map(r => <li className="small">{r}<span>
                                            <FaTrashAlt className="ml-2 iconTrash" onClick={() => setReferences(references.filter(a => a!==r))} />
                                        </span></li>)
                                    }
                                </ul>
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Spoglio *</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Spoglio"
                                    rows={3}
                                    value={spoglio}
                                    onChange={e => setSpoglio(e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Note</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Note"
                                    rows={3}
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <Form.Label className="small">Eventuale upload</Form.Label>
                                <Form.File
                                    label={upload ? upload.name : 'Scegli un file PDF'}
                                    accept='application/pdf'
                                    data-browse="Scegli..."
                                    custom
                                    onChange={e => {
                                        if(e.target.files[0]) {
                                            if(e.target.files[0].type === 'application/pdf') {
                                                if(e.target.files[0].size <= 5*1024*1024) {
                                                    setUpload(e.target.files[0])
                                                }
                                            }
                                        }
                                    }}
                                />
                                <Form.Text className="small text-center">{download ? 'Un file è stato già caricato.' : null}</Form.Text>
                                <Form.Text className="small">Solo file PDF - Max 5MB</Form.Text>
                            </Col>
                            <Col md={6} className="mb-3 d-flex justify-content-center align-items-center">
                                <Row className="text-center">
                                    <Col md="auto">
                                        <Form.Check
                                            type="checkbox"
                                            label="Cliente in presenza"
                                            className="mt-1"
                                            checked={clientepresenza}
                                            onChange={e => clientepresenza ? setClientepresenza(false) : setClientepresenza(true)}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <Form.Check
                                            type="checkbox"
                                            label="Produzione"
                                            className="mt-1"
                                            checked={produzione}
                                            onChange={e => produzione ? setProduzione(false) : setProduzione(true)}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <Form.Check
                                            type="checkbox"
                                            label="Trucco"
                                            className="mt-1"
                                            checked={trucco}
                                            onChange={e => trucco ? setTrucco(false) : setTrucco(true)}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        result ?
                            <span className="text-success font-weight-bold mr-3">{result}</span>
                            :
                            null
                    }
                    {
                        loading ?
                            <Spinner animation="border" variant="warning" size="sm" className="mr-2" />
                            :
                            null
                    }
                    <Button variant="danger" size="sm" className="rounded10" onClick={() => setShowModalEdit(false)}>
                        Annulla
                    </Button>
                    <Button variant="warning" size="sm" className="rounded10"
                            onClick={() => {
                                setLoading(true)
                                updateData()
                            }}>
                        Aggiorna
                    </Button>
                </Modal.Footer>
            </Modal>

        </Card>
    )

    function updateData() {
        if(upload!=='') {
            firebase.storage().ref('pdf/docs/' + props.id + '.pdf').put(upload).then(() => {
                let d
                if(datand) d = datand
                else d = datariprese
                firebase.firestore().collection('calendario').doc(props.id).update({
                    id: props.id,
                    stato: 'Riprese',
                    cliente: cliente,
                    commerciale: commerciale,
                    datariprese: d,
                    videomaker: videomaker,
                    budget: Number(budget),
                    references: references,
                    clientepresenza: Boolean(clientepresenza),
                    produzione: Boolean(produzione),
                    trucco: Boolean(trucco),
                    spoglio: spoglio,
                    note: note
                }).then(() => {
                    setResult('Video aggiornato con successo.')
                    setLoading(false)
                })
            })
        } else {
            let d
            if(datand) d = datand
            else d = datariprese
            firebase.firestore().collection('calendario').doc(props.id).update({
                id: props.id,
                stato: 'Riprese',
                cliente: cliente,
                commerciale: commerciale,
                datariprese: d,
                videomaker: videomaker,
                budget: Number(budget),
                references: references,
                clientepresenza: Boolean(clientepresenza),
                produzione: Boolean(produzione),
                trucco: Boolean(trucco),
                spoglio: spoglio,
                note: note
            }).then(() => {
                setResult('Video aggiornato con successo.')
                setLoading(false)
            })
        }
    }

}
