import {Container, Row, Col, Carousel, Button, Badge, Spinner, Modal} from "react-bootstrap";
import {
    FaWhatsapp,
    FaShareAlt,
    FaInstagram,
    FaFacebookSquare,
    FaYoutube,
    FaCopy,
    FaEnvelope,
} from "react-icons/fa";
import {useLocation} from "react-router";
import React, {useContext, useEffect, useState} from "react";
import moment from 'moment'
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import firebase from "./firebase";
import {StateContext} from "./App";
import profilePic from './profilePic.jpg'


function ProvPersona() {

    moment.updateLocale('it', {months: [
            'gennaio',
            'febbraio',
            'marzo',
            'aprile',
            'maggio',
            'giugno',
            'luglio',
            'agosto',
            'settembre',
            'ottobre',
            'novembre',
            'dicembre'
        ]})

    const queryId = useLocation().pathname.split('/')[3]
    const [info, setInfo] = useState()

    useEffect(() => {
        const actorsRef = firebase.firestore().collection('provActors').doc(queryId)
        actorsRef.onSnapshot(snapshot => {
            setInfo(snapshot.data())
        })
    }, [])


    // IMMAGINI + CV
    const img0Ref = firebase.storage().ref('images/actors/' + queryId).child('0')
    const img1Ref = firebase.storage().ref('images/actors/' + queryId).child('1')
    const img2Ref = firebase.storage().ref('images/actors/' + queryId).child('2')
    const img3Ref = firebase.storage().ref('images/actors/' + queryId).child('3')
    const cvRef = firebase.storage().ref('images/actors/' + queryId).child('cv')

    const [image0, setImage0] = useState(profilePic)
    const [image1, setImage1] = useState()
    const [image2, setImage2] = useState()
    const [image3, setImage3] = useState()
    const [cv, setCv] = useState()

    img0Ref.getDownloadURL().then((url) => {
        setImage0(url)
    }).catch((err) => {
        setImage0(profilePic)
    })
    img1Ref.getDownloadURL().then((url) => {
        setImage1(url)
    }).catch((err) => {
        console.log(err)
    })
    img2Ref.getDownloadURL().then((url) => {
        setImage2(url)
    }).catch((err) => {
        console.log(err)
    })
    img3Ref.getDownloadURL().then((url) => {
        setImage3(url)
    }).catch((err) => {
        console.log(err)
    })
    cvRef.getDownloadURL().then((url) => {
        setCv(url)
    }).catch((err) => {
        console.log(err)
    })

    // NOTE UPDATE
    const [note, setNote] = useState('')
    useEffect(() => {
        if(info) {
            setNote(info.note)
        }
    }, [info])
    function updateNote() {
        firebase.firestore().collection('actors').doc(queryId).set({
            id: info.id,
            nome: info.nome,
            cognome: info.cognome,
            sesso: info.sesso,
            dataNascita: info.dataNascita,
            luogoNascita: info.luogoNascita,
            luogoDomicilio: info.luogoDomicilio,
            cf: info.cf,
            email: info.email,
            telefono: info.telefono,
            comparsa: info.comparsa,
            figurante: info.figurante,
            attore: info.attore,
            livelloRecitazione: info.livelloRecitazione,
            retribuzione: info.retribuzione,
            instagram: info.instagram,
            facebook: info.facebook,
            youtube: info.youtube,
            showreel: info.showreel,
            commenti: info.commenti,
            accetto: info.accetto,
            note: note
        })
    }

    // CALCOLO ETA'
    const anno = moment().format('YYYY')
    const mese = moment().format('MM')
    const giorno = moment().format('DD')
    let eta = 0
    if(info) {
        const [a, m, g] = info.dataNascita.split('-')
        eta = anno - a - 1
        if(mese > m) eta++
        else if(mese===m && giorno >= g) eta++
    }

    // MODAL CONDIVIDI
    const [showCondividi, setShowCondividi] = useState(false)


    // ABILITA E DISABILITA ATTORE
    function abilitaAttore() {
        firebase.firestore().collection('actors').doc(queryId).set(info)
        firebase.firestore().collection('provActors').doc(queryId).delete()
    }
    function eliminaAttore() {
        firebase.storage().ref('images/actors/' + queryId).child('0').delete()
        firebase.storage().ref('images/actors/' + queryId).child('1').delete()
        firebase.storage().ref('images/actors/' + queryId).child('2').delete()
        firebase.storage().ref('images/actors/' + queryId).child('3').delete()
        firebase.storage().ref('images/actors/' + queryId).child('cv').delete()
        firebase.firestore().collection('provActors').doc(queryId).delete()
    }

    const state = useContext(StateContext)[0]

    return(
        <div>
            {
                info ?
                    <Container>
                        <Row>
                            <Col md="4" className="p-0 px-4">
                                <div
                                    style={{
                                        backgroundImage: `url(${image0})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover'
                                    }}
                                    className="rounded10 mb-5 picPersona"
                                >
                                </div>
                            </Col>
                            <Col className="pl-5">
                                <Container className="mb-3">
                                    <h2
                                        style={{
                                            marginBottom: '0px',
                                            color:'gold',
                                            fontWeight:'bold'
                                        }}
                                    >
                                        {info.nome} {info.cognome}
                                    </h2>
                                    <code>ID: {queryId}</code>
                                </Container>
                                <Container>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="mx-1 mb-3"
                                        onClick={() => {
                                            if(window.confirm('Vuoi ABILITARE questo attore?')) {
                                                abilitaAttore()
                                            }
                                        }}
                                    >
                                        Abilita
                                    </Button>
                                    <Button
                                        variant="danger"
                                        size="sm"
                                        className="mx-1 mb-3"
                                        onClick={() => {
                                        if(window.confirm('Vuoi ELIMINARE questo attore?')) {
                                            eliminaAttore()
                                        }
                                    }}
                                        >
                                        Elimina
                                    </Button>
                                </Container>
                                <Container>
                                    <Row className="mb-3">
                                        <Col md="auto">
                                            <p>Nome: <b>{info.nome}</b></p>
                                            <p>Cognome: <b>{info.cognome}</b></p>
                                            <p>Età: <b>{eta}</b></p>
                                            <p>Sesso: <b style={{textTransform:'uppercase'}}>{info.sesso}</b></p>
                                        </Col>
                                        <Col>
                                            <p>CF: <b>{info.cf}</b></p>
                                            <p>Data di Nascita: <b>{moment(info.dataNascita.split('T')[0], 'YYYY-MM-DD').locale('it').format('DD MMMM YYYY')}</b></p>
                                            <p>Luogo di Nascita: <b>{info.luogoNascita}</b></p>
                                            <p>Domicilio: <b>{info.luogoDomicilio}</b></p>
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="mb-4">
                                    <Row>
                                        <Col md="7">
                                            <div>
                                                <span className="mr-2">Tags: </span>
                                                {
                                                    info.comparsa ? <Badge variant="warning" className="mr-2">Comparsa</Badge> : null
                                                }
                                                {
                                                    info.figurante ?
                                                        <Badge variant="primary" className="mr-2">Figurante</Badge> : null
                                                }
                                                {
                                                    info.attore ? <Badge variant="danger" className="mr-2">Attore</Badge> : null
                                                }
                                            </div>
                                            <div>
                                                <span className="mr-2">Livello recitazione: </span>
                                                {
                                                    info.livelloRecitazione==='Basso' ? <Badge variant="dark" className="mr-2">Basso</Badge> :
                                                        info.livelloRecitazione==='Medio' ? <Badge variant="warning" className="mr-2">Medio</Badge> :
                                                            info.livelloRecitazione==='Alto' ? <Badge variant="success" className="mr-2">Alto</Badge> :
                                                                null
                                                }
                                            </div>
                                            <div>
                                                <span className="mr-2">Retribuzione: </span>
                                                {
                                                    info.retribuzione==='No' ? <Badge variant="success" className="mr-2">No</Badge> :
                                                        info.retribuzione==='Si' ? <Badge variant="danger" className="mr-2">Si</Badge> :
                                                            info.retribuzione==='Entrambe' ? <Badge variant="warning" className="mr-2">Si/No</Badge> :
                                                                null
                                                }
                                            </div>
                                        </Col>
                                        <Col>
                                            {
                                                cv ?
                                                    <a
                                                        href={cv}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                    >
                                                        <Button
                                                            size="sm"
                                                            className="rounded-lg mx-1"
                                                        >
                                                            CV
                                                        </Button>
                                                    </a> : null
                                            }
                                            {
                                                info.showreel ?
                                                    <a
                                                        href={
                                                            info.showreel.includes('http://') || info.showreel.includes('https://') ?
                                                                info.showreel
                                                                :
                                                                'http://' + info.showreel
                                                        }
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <Button
                                                            size="sm"
                                                            className="rounded-lg mx-1"
                                                        >
                                                            Showreel
                                                        </Button>
                                                    </a> : null
                                            }
                                            {
                                                info.commenti ?
                                                    <Button
                                                        size="sm"
                                                        className="rounded-lg mx-1"
                                                        onClick={() => alert(info.commenti)}
                                                    >
                                                        Commenti
                                                    </Button>
                                                    :
                                                    null
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                                <Container className="mb-2">
                                    <p><b>CONTATTI</b></p>
                                    <Row>
                                        <Col>
                                            <p><a href={`mailto:${info.email}`}>{info.email}</a></p>
                                            <p><a href={`tel:${info.telefono}`}>{info.telefono}</a></p>
                                        </Col>
                                        <Col>
                                            {
                                                info.facebook ?
                                                    <a href={
                                                        info.facebook.includes('http://') || info.facebook.includes('https://') ?
                                                            info.facebook
                                                            :
                                                            'http://' + info.facebook
                                                    } target="_blank" rel="noreferrer" className="m-1">
                                                        <FaFacebookSquare className="socialPersona" size="1.7em" color="white" />
                                                    </a> : null
                                            }
                                            {
                                                info.instagram ?
                                                    <a href={
                                                        info.instagram.includes('http://') || info.instagram.includes('https://') ?
                                                            info.instagram
                                                            :
                                                            'http://' + info.instagram
                                                    } target="_blank" rel="noreferrer" className="m-1">
                                                        <FaInstagram className="socialPersona" size="1.7em" color="white" />
                                                    </a> : null
                                            }
                                            {
                                                info.telefono ?
                                                    <a href={
                                                        info.telefono.includes('+39') ?
                                                            `https://wa.me/${info.telefono}`
                                                            :
                                                            `https://wa.me/+39${info.telefono}`
                                                    } target="_blank" rel="noreferrer" className="m-1">
                                                        <FaWhatsapp className="socialPersona" size="1.7em" color="white" />
                                                    </a> : null
                                            }
                                            {
                                                info.youtube ?
                                                    <a href={
                                                        info.youtube.includes('http://') || info.youtube.includes('https://') ?
                                                            info.youtube
                                                            :
                                                            'http://' + info.youtube
                                                    } target="_blank" rel="noreferrer" className="m-1">
                                                        <FaYoutube className="socialPersona" size="1.7em" color="white" />
                                                    </a> : null
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                            <Col md="auto">
                                <div className="m-1">
                                    <FaShareAlt className="shareButton" size="2em" onClick={() => setShowCondividi(true)} />
                                </div>
                            </Col>
                        </Row>
                        <Row className="mb-5">
                            <Col xs="12" sm="12" md="12" lg="4" xl="4" className="p-0 px-4 mb-sm-4 mb-md-4 mb-lg-0 mb-xl-0">
                                <div>
                                    <div style={{textAlign:'center', fontSize:'smaller', letterSpacing:'3px'}} className="mb-1">
                                        <p><b>ANNOTAZIONI</b></p>
                                    </div>
                                    <textarea
                                        className="annotazioni mb-2"
                                        value={note}
                                        onChange={e => setNote(e.target.value)}
                                        placeholder="Annotazioni attore..."
                                    />
                                    <div className="d-flex justify-content-end">
                                        <Button
                                            size="sm"
                                            variant="outline-light"
                                            onClick={() => {
                                                updateNote()
                                            }}
                                        >
                                            Salva
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="8" xl="8" className="pl-5">
                                <Container className="p-0 d-flex align-items-center justify-content-center" >
                                    <Carousel>
                                        {
                                            image1 ?
                                                <Carousel.Item>
                                                    <img
                                                        className="rounded10"
                                                        src={image1}
                                                        alt="Img Persona"
                                                        height="400px"
                                                    />
                                                </Carousel.Item>
                                                :
                                                null
                                        }
                                        {
                                            image2 ?
                                                <Carousel.Item>
                                                    <img
                                                        className="rounded10"
                                                        src={image2}
                                                        alt="Img Persona"
                                                        height="400px"
                                                    />
                                                </Carousel.Item>
                                                :
                                                null
                                        }
                                        {
                                            image3 ?
                                                <Carousel.Item>
                                                    <img
                                                        className="rounded10"
                                                        src={image3}
                                                        alt="Img Persona"
                                                        height="400px"
                                                    />
                                                </Carousel.Item>
                                                :
                                                null
                                        }
                                    </Carousel>
                                </Container>
                            </Col>
                        </Row>

                        {
                            // MODAL CONDIVIDI
                        }
                        <Modal
                            show={showCondividi}
                            onHide={() => setShowCondividi(false)}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Body style={{backgroundColor:'#292929'}} >
                                <p>Condividi</p>
                                <div className="mt-3 mb-2 mr-2">
                                    <CopyToClipboard text={window.location.href}>
                                        <FaCopy
                                            className="mx-1 socialPersona"
                                            size="1.7em"
                                            color="white"
                                        />
                                    </CopyToClipboard>
                                    <a href={`https://wa.me/?text=${info.nome}%20${info.cognome}%20-%20${eta}%20anni%0A${window.location.href}%20`} target="_blank" rel="noreferrer" className="m-1">
                                        <FaWhatsapp
                                            className="mx-1 socialPersona"
                                            size="1.7em"
                                            color="white"
                                        />
                                    </a>
                                    <a href={`mailto:?subject=${info.nome}%20${info.cognome}%20-%20${eta}&body=${info.nome}%20${info.cognome}%20-%20${eta}%20anni%0A${window.location.href}%20`} target="_blank" rel="noreferrer" className="m-1">
                                        <FaEnvelope
                                            className="mx-1 socialPersona"
                                            size="1.7em"
                                            color="white"
                                        />
                                    </a>
                                </div>
                                <div className="mt-3 mb-2 mr-2 ml-2">
                                    <Row>
                                        <Col md="auto" className="my-2">
                                            <pre style={{color:'white'}}>{window.location.href}</pre>
                                        </Col>
                                        <Col md="auto">
                                            <CopyToClipboard text={window.location.href}>
                                                <Button size="sm" variant="warning" >Copia</Button>
                                            </CopyToClipboard>
                                        </Col>
                                    </Row>


                                </div>
                            </Modal.Body>
                        </Modal>
                    </Container>
                    :
                    <Container className="text-center">
                        <Spinner animation="border" variant="warning" className="mb-2"/>
                        <h5>Caricamento dati...</h5>
                    </Container>
            }

        </div>
    )
}

export default ProvPersona