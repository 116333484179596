import {Alert, Button, Col, Container, Form} from "react-bootstrap";
import {useState} from "react";
import moment from "moment";
import {nanoid} from "nanoid";
import firebase from "./firebase";


function AddCostume() {

    const [nome, setNome] = useState('')
    const [sesso, setSesso] = useState('')
    const [eta, setEta] = useState('')
    const [ubicazione, setUbicazione] = useState('')
    const [note, setNote] = useState('')
    const [foto, setFoto] = useState('')

    const [error, setError] = useState('')
    const [result, setResult] = useState('')

    return (
        <div>
            <Container className="mb-4">
                <h2 style={{color:'gold', textAlign:'center'}}>Nuovo costume</h2>
                <p style={{textAlign:'center', fontSize:'smaller'}}>Tutti i campi segnati con un asterisco (<span className="asterisco">*</span>) sono obbligatori</p>
            </Container>

            <Container>
                <Form.Label>Nome <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Nome"
                    onChange={e => setNome(e.target.value)}
                    value={nome}
                    className="mb-3"
                />

                <Form.Label>Sesso <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    as="select"
                    onChange={e => setSesso(e.target.value)}
                    value={sesso}
                    className="mb-3"
                >
                    <option disabled value="">Scegli...</option>
                    <option value="Uomo">Uomo</option>
                    <option value="Donna">Donna</option>
                    <option value="Unisex">Unisex</option>
                </Form.Control>

                <Form.Label>Età <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    as="select"
                    onChange={e => setEta(e.target.value)}
                    value={eta}
                    className="mb-3"
                >
                    <option disabled value="">Scegli...</option>
                    <option value="Adulto">Adulto</option>
                    <option value="Bambino">Bambino</option>
                </Form.Control>

                <Form.Label>Ubicazione <span className="asterisco">*</span> </Form.Label>
                <Form.Control
                    as="select"
                    onChange={e => setUbicazione(e.target.value)}
                    value={ubicazione}
                    className="mb-3"
                >
                    <option disabled value="">Scegli...</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="Baule">Baule</option>
                    <option value="Speciale">Speciale</option>
                </Form.Control>

                <Form.Label>Note</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder="Note"
                    onChange={e => setNote(e.target.value)}
                    value={note}
                    className="mb-3"
                />

                <Form.Label>Foto <span className="asterisco">*</span> </Form.Label>
                <Form.File
                    label={
                        foto==='' ?
                            <p>Scegli un file</p>
                            :
                            <p>{foto.name}</p>
                    }
                    data-browse="Scegli"
                    onChange={e => {
                        if(e.target.files[0]) {
                            if(e.target.files[0].type === 'image/jpg' || e.target.files[0].type === 'image/jpeg' || e.target.files[0].type === 'image/png') {
                                if(e.target.files[0].size <= 5*1024*1024) {
                                    setFoto(e.target.files[0])
                                    setError('')
                                } else {
                                    setFoto('')
                                    setError('Foto Costume - File troppo pesante')
                                }
                            } else {
                                setFoto('')
                                setError('Foto Costume - Formato file non supportato')
                            }
                        }
                    }}
                    custom
                />
                <Form.Text
                    className="mb-3"
                >
                    Dimensione massima: 5MB - Solo formati .jpg .png .jpeg
                </Form.Text>

                {
                    error ? <Alert variant="danger" style={{textAlign:'center'}}>{error}</Alert> : null
                }

                {
                    result ? <Alert variant="success" style={{textAlign:'center'}}>{result}</Alert> : null
                }

                <div className="mb-5 d-flex justify-content-end">
                    <Button
                        size="md"
                        variant="warning"
                        onClick={() => {

                            if(nome==='') setError('Nome non valido.')
                            else if(sesso==='') setError('Sesso non valido.')
                            else if(eta==='') setError('Età non valida.')
                            else if(ubicazione==='') setError('Ubicazione non valida.')
                            else if(foto==='') setError('Caricare una foto.')
                            else {
                                let string = nome + '-' + nanoid(8)
                                string = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g,'')
                                let id = string.split(' ').join('')
                                firebase.storage().ref('images/costumi/' + id).put(foto).then(() => {
                                    console.log('Immagine caricata')
                                }).catch((err) => setError('Errore caricamento immagine'))
                                firebase.firestore().collection('costumi').doc(id).set({
                                    id: id,
                                    nome: nome,
                                    sesso: sesso,
                                    eta: eta,
                                    ubicazione: ubicazione,
                                    note: note,
                                }).then(() => {
                                    setError('')
                                    setResult('Costume inserito con successo!')

                                    setNome('')
                                    setSesso('')
                                    setEta('')
                                    setUbicazione('')
                                    setNote('')
                                    setFoto('')
                                }).catch((err) => setError('Errore inserimento costume'))
                            }
                        }}
                    >
                        Aggiungi
                    </Button>
                </div>

            </Container>
        </div>
    )
}

export default AddCostume