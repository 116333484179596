import {Container} from "react-bootstrap";


function PrivacyOspiti() {
    return(
        <div style={{
            fontSize: '15px',
            fontWeight: 'lighter'
        }}>
            <Container className="pt-4 mb-5">
                <Container className="text-center mb-5 pb-3">
                    <h2 className="text-warning">Informativa Privacy e Trattamento dei Dati Personali</h2>
                    <p>Informativa relativa al Trattamento dei Dati Personali degli utenti ai sensi dell'Art. 13 del regolamento (UE) 2016/679 (“GDPR”)</p>
                </Container>
                <Container className="mt-4">
                    <h4>1. Titolare del Trattamento</h4>
                    <p><strong>RKH Studio SRL</strong></p>
                    <p>Indirizzo sede legale: <strong>Via Carlo Allioni, 3 - 10122 Torino TO</strong></p>
                    <p>Indirizzo email: <strong><a style={{color:'white'}} href="mailto:comparsetorino@gmail.com">comparsetorino@gmail.com</a></strong></p>
                    <p>("Società")</p>
                </Container>
                <Container className="mt-4">
                    <h4>2. Definizione di "Dati"</h4>
                    <p>Per “<strong>Dati</strong>” si intendono, a titolo esemplificativo e non esaustivo, <i>nome, cognome, luogo e data di nascita, residenza, indirizzo di posta elettronica e contatto telefonico, titolo di studio, esperienze lavorative ed eventuali ulteriori Dati da Lei inseriti nel curriculum vitae
                        e/o inseriti nel questionario che Le sarà sottoposto in fase di candidatura</i> (dati cd. “comuni”), nonché quelli idonei a rivelare, a titolo esemplificativo, lo stato di salute (come l’appartenenza a categorie
                        protette) eventualmente contenuti nel curriculum o in eventuale ulteriore documentazione trasmessa alla Società, categorie particolari di dati personali trattati (dati cd. “sensibili”).</p>
                </Container>
                <Container className="mt-4">
                    <h4>3. Finalità del Trattamento</h4>
                    <ul>
                        <li>Finalità connesse o strumentali allo svolgimento dell’attività di ricerca e selezione dei candidati.</li>
                        <li>Verificare, anche tramite società specializzate, che i Dati forniti corrispondano al vero avvalendosi di informazioni pubbliche (ivi inclusi profili di social network professionali, banche dati, registri web o archivi multimediali accessibili al pubblico). Il trattamento, in conformità con quanto indicato dal Gruppo di lavoro art. 29 nell’«<i>Opinion on data processing at work</i>», è limitato alle sole informazioni connesse all’attitudine professionale al lavoro, necessario al solo fine di valutare gli specifici rischi legati al tipo di attività che dovrà essere svolta dai candidati, effettuato nella misura meno intrusiva possibile, adottando ogni necessaria misura per garantire un corretto bilanciamento tra il legittimo interesse del datore di lavoro a controllare e i diritti e le libertà fondamentali di questi ultimi.</li>
                        <li>Conservare per 2 anni i Dati per eventuali future selezioni, nel caso in cui la selezione per cui Lei si è candidato abbia esito negativo, previa Sua richiesta, da esprimersi con manifestazione di volontà in calce alla presente Informativa Qualora Lei decidesse di non usufruire di tale ulteriore servizio e quindi di non autorizzare la conservazione dei suoi Dati per future selezioni e il conseguente trattamento, ciò non precluderà che il Suo profilo sia preso in considerazione per la selezione in corso, ma impedirà solo che la Sua candidatura sia presa in considerazione per eventuali future selezioni.</li>
                        <li>Adempiere agli obblighi ed esercitare i diritti della Società o dell’interessato previsti dalla normativa applicabile in materia di diritto del lavoro e della sicurezza sociale e protezione sociale, o da contratti collettivi, nonché a disposizioni impartite da autorità a ciò legittimate e da organi di vigilanza e controllo.</li>
                    </ul>
                </Container>
                <Container className="mt-4">
                    <h4>4. Base Giuridica del trattamento</h4>
                    <ul>
                        <li>È necessaria l'esecuzione di misure precontrattuali adottate su Sua richiesta per il trattamento di Dati Personali per le finalità di cui alla sezione 2.a.</li>
                        <li>Legittimo interesse per il trattamento di Dati Personali per le finalità di cui alla sezione 2.b.</li>
                        <li>È necessario il Consenso per il trattamento di Dati Personali per le finalità di cui alla sezione 2.c.</li>
                        <li>È necessario assolvere gli obblighi legali cui è soggetta la Società nel rispetto, per quanto riguarda i dati “sensibili”, di quanto previsto nell’art. 9.2, lett. b) per il trattamento di Dati Personali per le finalità di cui alla sezione 2.d.</li>
                    </ul>
                </Container>
                <Container className="mt-4">
                    <h4>5. Periodo di Conservazione dei Dati</h4>
                    <ul>
                        <li>I Dati Personali trattati per la finalità di cui alla sezione 2.a saranno conservati per tutta la durata dell’attività di selezione e, alla cessazione della stessa, cancellati, salva l’ulteriore eventuale conservazione da Lei autorizzata (vedi di seguito).</li>
                        <li>I Dati Personali trattati per la finalità di cui alla sezione 2.b saranno conservati per tutta la durata dell’attività di selezione e, alla cessazione della stessa, cancellati.</li>
                        <li>I Dati Personali trattati per la finalità di cui alla sezione 2.c saranno conservati per 2 anni dalla cessazione della selezione.</li>
                        <li>I Dati Personali trattati per la finalità di cui alla sezione 2.d saranno conservati per 2 anni dalla cessazione della selezione.</li>
                    </ul>
                    <p>Decorsi i termini di conservazione sopra indicati, i Dati saranno distrutti o resi anonimi, compatibilmente con le procedure tecniche di cancellazione e backup.</p>
                </Container>
                <Container className="mt-4">
                    <h4>6. Conferimento dei Dati</h4>
                    <p>Obbligatorio per l’attività di ricerca e selezione del personale. Il rifiuto di fornire i Dati non consente di svolgere tale attività e non consentirà che la Sua candidatura sia presa in considerazione.</p>
                </Container>
                <Container className="mt-4">
                    <h4>7. Destinatari dei Dati</h4>
                    <p>I Dati potranno essere comunicati a soggetti operanti in qualità di titolari autonomi oppure trattati, per conto della Società, da soggetti designati come responsabili del trattamento, a cui sono impartite adeguate istruzioni operative.</p>
                </Container>
                <Container className="mt-4">
                    <h4>8. Soggetti Autorizzati al Trattamento</h4>
                    <p>I Dati potranno essere trattati dai dipendenti delle funzioni aziendali deputate al perseguimento delle finalità sopra indicate, che sono stati espressamente autorizzati al trattamento e che hanno ricevuto adeguate istruzioni operative.</p>
                </Container>
                <Container className="mt-4">
                    <h4>9. Diritti dell'Interessato - Reclamo all'Autorità di Controllo</h4>
                    <ul>
                        <li>Contattando la Società via e-mail all’indirizzo <strong><a style={{color:'white'}} href="mailto:comparsetorino@gmail.com">comparsetorino@gmail.com</a></strong>, gli interessati possono chiedere al titolare l’accesso ai Dati che li riguardano, la loro rettifica, l’integrazione o la loro cancellazione, nonché, la limitazione del trattamento nei casi previsti dall’art. 18 GDPR nonché l’opposizione al trattamento, per motivi connessi alla propria situazione particolare, nelle ipotesi di legittimo interesse del titolare.</li>
                        <li>Gli interessati, inoltre, nel caso in cui il trattamento sia basato sul consenso o sul contratto e sia effettuato con strumenti automatizzati hanno il diritto di ricevere in un formato strutturato, di uso comune e leggibile da dispositivo automatico i Dati, nonché, se tecnicamente fattibile, di trasmetterli ad altro titolare senza impedimenti.</li>
                        <li>Gli interessati hanno il diritto di proporre reclamo all'Autorità di controllo competente.</li>
                    </ul>
                </Container>
                <Container className="mt-4">
                    <p>Ultimo aggiornamento informativa: <strong>24 marzo 2021</strong></p>
                </Container>



            </Container>
        </div>
    )
}

export default PrivacyOspiti
